import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import './basic_details.css'

export default function BasicDetails(){
    return(
        <div className="custom-basic-details">
            <Grid container>
                <Grid item md={4} pt={3}>
                    <TextField id="outlined-basic" label="Client Name" variant="outlined" className="custom-basic-text-field"/>
                </Grid>
                <Grid item md={4} pt={3}>
                    <TextField id="outlined-basic" label="Client Id" variant="outlined" className="custom-basic-text-field"/>
                </Grid>
                <Grid item md={4} pt={3}>
                    <div>
                        <input className="" type="checkbox" value=""/>&nbsp;
                        <span className="custom-check-internal">Internal Customer</span>
                    </div>
                    {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Internal Customer" className="custom-check-internal"/> */}
                </Grid>
            </Grid>

            <Grid container>
                <Grid item md={4} pt={3}>
                <FormControl fullWidth>
                    <InputLabel className='custom-customer-select-label' id="vendor-label">Vendor</InputLabel>
                    <Select className='custom-customer-select-field' labelId="vendor-label" label="Vendor" variant="outlined">
                        <MenuItem value='Taylor'>Taylor</MenuItem>
                        <MenuItem value='Vendor'>Vendor</MenuItem>
                        <MenuItem value='Vendor'>Vendor</MenuItem>
                        <MenuItem value='Vendor'>Vendor</MenuItem>
                    </Select>
                </FormControl>
                </Grid>
                <Grid item md={4} pt={3}>
                <FormControl fullWidth>
                    <InputLabel className='custom-customer-select-label' id="progrm-label">Program</InputLabel>
                    <Select className='custom-customer-select-field' labelId="program-label" label="Program" variant="outlined">
                        <MenuItem value='Program 1'>Program 1</MenuItem>
                        <MenuItem value='Program 2'>Program 2</MenuItem>
                    </Select>
                </FormControl>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item md={4} pt={3}>
                <FormControl fullWidth>
                    <InputLabel className='custom-customer-select-label' id="industry-label">Industry Type</InputLabel>
                    <Select className='custom-customer-select-field' labelId="industry-label" label="Industry Type" variant="outlined">
                        <MenuItem value='Taylor'>Taylor</MenuItem>
                        <MenuItem value='Vendor'>Vendor</MenuItem>
                        <MenuItem value='Vendor'>Vendor</MenuItem>
                        <MenuItem value='Vendor'>Vendor</MenuItem>
                    </Select>
                </FormControl>
                </Grid>
                <Grid item md={4} pt={3}>
                <FormControl fullWidth>
                    <InputLabel className='custom-customer-select-label' id="customer-label">Customer Type</InputLabel>
                    <Select className='custom-customer-select-field' labelId="customer-label" label="Customer Type" variant="outlined">
                        <MenuItem value='Program 1'>Program 1</MenuItem>
                        <MenuItem value='Program 2'>Program 2</MenuItem>
                    </Select>
                </FormControl>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item md={4} pt={3}>
                <FormControl fullWidth>
                    <InputLabel className='custom-customer-select-label' id="country-label">Country</InputLabel>
                    <Select className='custom-customer-select-field' labelId="country-label" label="Country" variant="outlined">
                        <MenuItem value='Taylor'>Taylor</MenuItem>
                        <MenuItem value='Vendor'>Vendor</MenuItem>
                        <MenuItem value='Vendor'>Vendor</MenuItem>
                        <MenuItem value='Vendor'>Vendor</MenuItem>
                    </Select>
                </FormControl>
                </Grid>
                <Grid item md={4} pt={3}>
                <FormControl fullWidth>
                    <InputLabel className='custom-customer-select-label' id="billing-label">Billing Currency</InputLabel>
                    <Select className='custom-customer-select-field' labelId="billing-label" label="Billing Currency" variant="outlined">
                        <MenuItem value='Program 1'>Program 1</MenuItem>
                        <MenuItem value='Program 2'>Program 2</MenuItem>
                    </Select>
                </FormControl>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item md={4} pt={3}>
                    <TextField id="outlined-basic" label="Website" variant="outlined" className="custom-basic-text-field"/>
                </Grid>
                <Grid item md={4} pt={3}>
                    <TextField id="outlined-basic" label="Email" variant="outlined" className="custom-basic-text-field"/>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item md={4} pt={3}>
                    <TextField id="outlined-basic" label="Tax Id" variant="outlined" className="custom-basic-text-field"/>
                </Grid>
                <Grid item md={4} pt={3} pb={3}>
                <FormControl fullWidth>
                    <InputLabel className='custom-customer-select-label' id="tax-label">Tax Category</InputLabel>
                    <Select className='custom-customer-select-field' labelId="tax-label" label="Tax Category" variant="outlined">
                        <MenuItem value='Program 1'>Program 1</MenuItem>
                        <MenuItem value='Program 2'>Program 2</MenuItem>
                    </Select>
                </FormControl>
                </Grid>
            </Grid>

            <Grid container pb={2}>
                <Grid item xs={8} pr={4} textAlign={'end'}>
                    <Button variant="contained" className='custom-send-btn'>Send</Button>
                </Grid>
            </Grid>
        </div>
    )
}