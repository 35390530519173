import React from "react";
import MUIDataTable, { TableHead } from "mui-datatables";
import { Icon } from '@iconify/react';
import { Button, Table, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import './order.css'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';



export default function OrderListDetails(props: any) {
  const [data, setData] = React.useState<any[]>([])
  const [isLoading, setIsLoading] = React.useState(true);
  const [sort, SetSort] = React.useState<any>();
  const allColumns = props.allColumns
  // const data = props.data
  const [visibleColumns, setVisibleColumns] = React.useState<number[]>([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  React.useEffect(() => {
    const storedButtonIds = localStorage.getItem('selectedButtonIds');
    if (props.buttonId) {
      const initialButtonIds = storedButtonIds ? JSON.parse(storedButtonIds) : props.buttonId;
      setVisibleColumns(initialButtonIds)
    }
    props.setPageSize(rowsPerPage)
    props.setPageNumber(currentPage)
    if (props.data) {
      setData(props.data)
      setIsLoading(false);
    }

    props.setTableColumns(columns)

  }, [props.buttonId, props.data]);

  const columns = allColumns.filter((_: any, index: number) =>
    visibleColumns.includes(index)
  );
  // props.setTableColumns(columns)
  const formatTime = (timeString: any) => {
    const time = new Date(`2000-01-01T${timeString}`);
    const formattedTime = time.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
    return formattedTime;
  };
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    props.setPageSize(newRowsPerPage)
  };
  const handleSort = (column:any, direction:any) => {
    props.setSort(direction)
  };
  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
    props.setPageNumber(newPage)
    if(sort=='asc'){
      setCurrentPage(0)
    }
  };
  const options = {
    serverSide: true,
    filter: true,
    sort: true,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    count: props.dataCount,
    pagination: true,
    textLabels: {
      body: {
        // noMatch: (isLoading) ? (props.interval == false) ? "Loading....." : "" : "No Records Found"
        noMatch: (isLoading) ? "Loading....." : "No Records Found"
      },
    },
    onColumnSortChange: (changedColumn:any, direction:any) => {
      handleSort(changedColumn, direction);
    },
    onChangeRowsPerPage: handleChangeRowsPerPage,
    onRowSelectionChange: (currentRowsSelected: any, allRowsSelected: any, rowsSelected: any) => {
      // Calculate the indices of the first and last row on the current page
      const startIndex = options.page * rowsPerPage;
      const endIndex = startIndex + rowsPerPage;

      // Filter the selected rows to include only those on the current page
      const currentPageRowsSelected = allRowsSelected.filter(
        (row: any) => row.dataIndex >= startIndex && row.dataIndex < endIndex
      );
      props.handleDeleteIcon(true, rowsSelected.length)
      if (currentPageRowsSelected.length) {
        let selectedRows: any = []
        const selectedRowData = currentPageRowsSelected.map((index: any) => {
          const id = index.dataIndex;
          let dataRows = {
            "orderId": data[id].orderId,
            "customerName": data[id].customerName
          }
          selectedRows.push(dataRows)
        });
        props.setSelectedRowData(selectedRows)
      } else {
        let selectedRows: any = []
        props.setSelectedRowData(selectedRows)
      }
    },
    onFilterChange: (changedColumn: any, filterList: any) => {
      console.log("sagdfgfsdgfagfdga")
    },
    expandableRows: true,
    renderExpandableRow: (rowData: any, rowMeta: any) => {
      const orderIdElement = rowData[1];
      const orderId = orderIdElement.props.children;
      const order = data.find((item: any) => item.orderId === orderId);
      if (!order || !order.comments) {
        return null;
      }


      return (
        <React.Fragment>
          <TableRow>
            <TableCell colSpan={12}>
              {(order.comments.length != 0) ?
                <Timeline position="right" className="custom-expansion">
                  {order.comments.map((comment: any, index: number) => (
                    <>
                        {comment.commentInfo == null ?
                    <TimelineItem key={index}>
                      <TimelineSeparator style={{ margin: '8px 0px' }}>
                        <Icon icon="radix-icons:dot-filled" color="#D0CFFC" style={{ height: '20px', width: '20px' }} />
                        {/* <TimelineDot color="secondary" style={{height:'10px', width:'10px'}}>
                          {comment.commentType === 'Acknowledged' && (
                            <Icon icon="solar:cart-check-bold" width="15" height="15" color="#5E5ADB" />
                          )}
                          {comment.commentType === 'TaxCalculated' && (
                            <Icon icon="heroicons-solid:receipt-tax" width="15" height="15" color="#2264E5" />
                          )}
                          {comment.commentType === 'Shipped' && (
                            <Icon icon="iconamoon:delivery-fast-fill" width="15" height="15" color="#5E5ADB" />
                          )}
                          {comment.commentType === 'Resubmitted' && (
                            <Icon icon="iconamoon:invoice-fill" width="15" height="15" color="#2264E5" />
                          )}
                          {comment.commentType === 'Invoiced' && (
                            <Icon icon="iconamoon:invoice-fill" width="15" height="15" color="#5E5ADB" />
                          )}
                        </TimelineDot> */}
                        {/* {index !== order.comments.length - 1 && <TimelineConnector />} */}
                      </TimelineSeparator>
                      <TimelineContent sx={{ pt: '22px', px: 2 }}>
                        {/* {comment.commentInfo == null ? */}
                          <Typography component="span" >
                            <span style={{ display: 'flex' }}>
                              <span className="custom-order-acknowledgement"> {comment.commentType} &nbsp;</span>
                              <span className="custom-order-date">on {comment.commentDate}
                                {/* {formatTime(comment.commentTime)}   */}
                                &nbsp;{comment.createdAt}</span>  <span className="custom-timeline-text"> by</span>&nbsp;
                              <span className="custom-order-name">
                                <Button className="custom-order-name-button" disabled>{comment.createdBy}</Button>
                              </span>
                            </span>
                          </Typography>
                          {/* : ''} */}
                        
                        {/* {index == order.comments.length - 1 &&
                          <>
                            {(comment.commentInfo) ?
                              <Typography component="span" style={{ display: 'flex' }} className="custom-last-comment"><Icon style={{ marginTop: '4px' }} icon="icon-park-outline:notes" color="#687182" rotate={2} />&nbsp; {comment.commentInfo}</Typography>
                              : ''}
                          </>
                        } */}
                      </TimelineContent>
                    </TimelineItem>
                     : ''}
                    </>
                  ))}
                </Timeline>
                : <p className="custom-fulfillment-comments">No Comments</p>}
            </TableCell>
          </TableRow>
        </React.Fragment>
      );
    },
    page: currentPage,
    onChangePage: handleChangePage,
    responsive: "standard",
  };
  const componentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    props.setSelectedComponentRef(componentRef.current);
  }, []);

  return (
    <>
      {/* {(isLoading) ? <LinearProgress /> : */}
      <div ref={componentRef} className="custom-data-table" id="divcontents">
        <div className="table-container">
          <MUIDataTable
            title={"Order Details"}
            data={data}
            columns={columns}
            options={options as any}
          />
        </div>
      </div>
      {/* } */}
    </>

  );
};
