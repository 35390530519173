import React from 'react'
import './invoice_header_editAddress.component.css'
import { Box, Button, Grid, TextField } from '@mui/material';
import { Icon } from '@iconify/react';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup"
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: '#FFFFFF;',
    boxShadow: 2,
    maxHeight: '80vh', // Set a maximum height for the modal container
    overflowY: 'auto',
};
export default function InvoiceHeaderEditAddress(props: any) {
    const handleClose = () => {
        props.handleCloseUnitModal()
    }
    const initialValues = {
        billToChooseAddress: "",
        billToName: "",
        billToDepartmentName: "",
        billToAddress1: "",
        billToAddress2: "",
        billToAddress3: "",
        billToCity: "",
        billToState: "",
        billToCountry: "",
        taxID: "",
        remitID: "",
        receivableID: "",
        billToZip: ""
    };
    let invoiceEditAddress = props.invoiceHeaderDetail.editAddress;
    const handleSubmit = (values: any) => {
    }
    return (
        <div>
            <Box sx={style} className='custom-invoiceHeaderEditAddress-modal'>
                <Grid container className='custom-invoice-editAddress-container'>
                    <Grid item md={6} pt={1} >
                        <h1 className='custom-invoiceHeaderEditAddress-head'>View / Edit Address</h1>
                    </Grid>
                    <Grid item md={6} pt={2} textAlign={'end'}>
                        <Icon icon="carbon:close-filled" color="#e01313" width="20" height="20" onClick={handleClose} />
                    </Grid>
                </Grid>
                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                    <Form>
                        <Grid container>
                            <Grid item xs={6} >
                                <Grid container>
                                    <Grid item xs={12} >
                                        <p className='custom-invoiceHeaderEditAddress-head-text'> Remit To </p>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} pr={2} py={1}>
                                        <p className='custom-invoiceHeaderEditAddress-text'>You have not configured remittance addresses.Add them on the Settlement page in the Configuration area</p>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} pr={2} py={1}>
                                        <Field as={TextField} fullWidth className='custom-invoiceHeaderEditAddress-fields' size='small' id="taxID" name="taxID" label="Tax ID Of Supplier" variant="outlined" />
                                    </Grid>
                                </Grid>
                                <Grid container pr={2} py={1}>
                                    <Grid item xs={2} >
                                        <p className='custom-invoiceHeaderEditAddress-text'>Name :</p>
                                    </Grid>
                                    <Grid item xs={10} >
                                        <p className='custom-invoiceHeaderEditAddress-text'><b>{invoiceEditAddress.remitToName}</b></p>
                                    </Grid>
                                </Grid>
                                <Grid container pr={2} py={1}>
                                    <Grid item xs={2} >
                                        <p className='custom-invoiceHeaderEditAddress-text'>Address :</p>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <p className='custom-invoiceHeaderEditAddress-text'>{invoiceEditAddress.address}</p>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} pr={2} py={1}>
                                        <Field as={TextField} fullWidth className='custom-invoiceHeaderEditAddress-fields' size='small' value={invoiceEditAddress.remitToId} id="remitID" name="remitID" label="Remit To ID" variant="outlined" />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} pr={2} py={1}>
                                        <Field as={TextField} fullWidth className='custom-invoiceHeaderEditAddress-fields' value={invoiceEditAddress.accountRecievableId} size='small' id="receivableID" name="receivableID" label="Account Receivable ID" variant="outlined" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} >
                                <Grid container>
                                    <Grid item xs={12} >
                                        <p className='custom-invoiceHeaderEditAddress-head-text'> Bill To </p>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} pr={2} py={1}>
                                        <Field as={TextField} fullWidth className='custom-invoiceHeaderEditAddress-fields' value={invoiceEditAddress.billToChooseAddress} size='small' id="billToChooseAddress" name="billToChooseAddress" label="Choose Address" variant="outlined" />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} >
                                        <Grid item xs={12} pr={2} py={1}>
                                            <Field as={TextField} fullWidth className='custom-invoiceHeaderEditAddress-fields' value={invoiceEditAddress.billToName} size='small' id="billToName" name="billToName" label="Name" variant="outlined" />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} pr={2} py={1} >
                                            <Field as={TextField} fullWidth className='custom-invoiceHeaderEditAddress-fields' value={invoiceEditAddress.remitToId} size='small' id="billToDepartmentName" name="billToDepartmentName" label="Department Name" variant="outlined" />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} pr={2} py={1} >
                                            <Field as={TextField} fullWidth className='custom-invoiceHeaderEditAddress-fields' value={invoiceEditAddress.billToAddress} size='small' id="billToAddress1" name="billToAddress1" label="Address 1" variant="outlined" />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} pr={2} py={1} >
                                            <Field as={TextField} fullWidth className='custom-invoiceHeaderEditAddress-fields' value={invoiceEditAddress.remitToId} size='small' id="billToAddress2" name="billToAddress2" label="Address 2" variant="outlined" />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} pr={2} py={1} >
                                            <Field as={TextField} fullWidth className='custom-invoiceHeaderEditAddress-fields' value={invoiceEditAddress.remitToId} size='small' id="billToAddress3" name="billToAddress3" label="Address 3" variant="outlined" />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} pr={2} py={1} >
                                            <Field as={TextField} fullWidth className='custom-invoiceHeaderEditAddress-fields' value={invoiceEditAddress.billToCity} size='small' id="billToCity" name="billToCity" label="City" variant="outlined" />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} pr={2} py={1} >
                                            <Field as={TextField} fullWidth className='custom-invoiceHeaderEditAddress-fields' value={invoiceEditAddress.billToState} size='small' id="billToState" name="billToState" label="State" variant="outlined" />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} pr={2} py={1} >
                                            <Field as={TextField} fullWidth className='custom-invoiceHeaderEditAddress-fields' value={invoiceEditAddress.billToZip} size='small' id="billToZip" name="billToZip" label="Zip" variant="outlined" />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} pr={2} py={1} >
                                            <Field as={TextField} fullWidth className='custom-invoiceHeaderEditAddress-fields' value={invoiceEditAddress.billToCountry} size='small' id="billToCountry" name="billToCountry" label="Country / Region" variant="outlined" />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} pr={2} py={1} >
                                            <p className='custom-invoiceHeaderEditAddress-text'>This selection will refresh the page content</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container pt={3} className='custom-invoiceHeaderEditAddress-editModal-footer'>
                            <Grid item md={6} textAlign={"end"}>
                                <Button type="submit" className="custom-invoiceHeaderEditAddress-save-btn">Ok</Button>
                            </Grid>
                            <Grid item md={6} pl={2} textAlign={"start"}>
                                <Button className="custom-invoiceHeaderEditAddress-cancel-btn" onClick={handleClose}>Cancel</Button>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </Box>
        </div>
    )
}

