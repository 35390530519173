import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Alert, Grid, TextField, useMediaQuery, useTheme } from '@mui/material';
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import { createItemUnit } from './add_item';
import './add_item_modal.css';
const validationSchema = Yup.object().shape({
    itemUniteName: Yup.string().required("Item Name is required").matches(/^[a-zA-Z0-9_]+$/,  'Invalid Special characters'),
    itemUnit: Yup.string().required("Unit is required").matches(/^[a-zA-Z0-9_]+$/,  'Invalid Special characters'),
});

export default function AddItemsModal(props: any) {
    const [alert, setAlert] = React.useState<string>();
    const [responseMsg, setResponseMsg] = React.useState<string>();
    const ALERT_DURATION = 2000;
    const alertClose = () => {
        setAlert('false')
    }
    const initialValues = {
        itemUniteName: "",
        itemUnit: "",
    };

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Adjust breakpoint as needed

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isSmallScreen ? '70%' : 330,
        bgcolor: '#FFFFFF;',
        boxShadow: 2,
    };
    const handleSubmit = async (values: any,  { resetForm }: { resetForm: () => void }) => {
        try {
            await createItemUnit(values).then((res:any) => {
                if(res.status == 200){
                    setResponseMsg(res.message);
                    setAlert('true')
                    props.handleUnit();
                     setTimeout(() => {
                        setAlert('');
                        resetForm();
                    }, ALERT_DURATION);
                }
                else{
                    setResponseMsg(res.message);
                    setAlert('false')
                    setTimeout(() => {
                        setAlert('');
                    }, ALERT_DURATION);
                }
            });
           
        } catch (error) {
            console.error('Error occurred:', error);
        }
    };

    const handleClose = () => {
        props.handleCloseUnitModal()
    }

    return (
        <div>
            <Box sx={style} className='custom-addItem-modal'>
                {/* {(alert) ? <Alert onClose={alertClose} className="custom-add-item-alert" severity="success">{responseMsg}</Alert> : ''} */}
                {(alert == 'true') ? <Alert style={{marginTop:'7px'}} onClose={alertClose}>{responseMsg}</Alert> : (alert == 'false') ? <Alert onClose={alertClose} style={{marginTop:'7px'}} severity='error'>{responseMsg}</Alert> : ''}
                <Formik initialValues={initialValues} validateOnChange={true} validateOnBlur={false} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({ errors, touched }) => (
                        <Form>
                            <Grid container>
                                <Grid item xs={6} pt={1} py={1}>
                                    <h1 className='custom-addUnit-head'>Add Unit</h1>
                                </Grid>
                                {/* <Grid item xs={6} pt={2} py={2} textAlign={'end'}>
                                    <Icon icon="carbon:close-filled" color="#e01313" width="20" height="20" onClick={handleClose} />
                                </Grid> */}
                            </Grid>
                            <Grid container>
                                <Grid item md={12} py={1}>
                                    <Field as={TextField} fullWidth size="small" id="itemUnit" name="itemUnit" label="Unit " variant="outlined" className="custom-addItemModal-text-field"
                                        error={errors.itemUnit && touched.itemUnit} helperText={<ErrorMessage name="itemUnit" />}
                                    />
                                </Grid>
                                <Grid item md={12} py={2} >
                                    <Field as={TextField} fullWidth size="small" id="itemUniteName" name="itemUniteName" label="Item Unit Name" variant="outlined" className="custom-addItemModal-text-field"
                                        error={errors.itemUniteName && touched.itemUniteName} helperText={<ErrorMessage name="itemUniteName" />}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={6} textAlign={"end"} py={2} pr={1}>
                                    <Button type="submit" className="custom-addItemModal-send-btn">
                                        Add
                                    </Button>
                                </Grid>
                                <Grid item xs={6} textAlign={"start"} py={2} pl={1}>
                                    <Button className="custom-addItemModal-delete-btn" onClick={handleClose}>cancel</Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
        </div>
    );
}
