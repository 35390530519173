import React from 'react'
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { Icon } from '@iconify/react';
import './order_acks_history.css'
import { Grid } from '@mui/material';
import { historyComment } from './order_acks_hitory.api';
import { useLocation } from 'react-router-dom';
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 136deg, #00B929 0%, #00B929 50%, #00B929)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 136deg, #00B929 0%, #00B929 50%, #00B929)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
    position: 'relative',
    zIndex: 1,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: 8,
      width: 8,
      borderRadius: '50%',
      backgroundColor: '#fff',
    },
  },
}));
const ColorlibInnerConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 136deg, #00B929 0%, #00B929 50%, #00B929)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 136deg, #00B929 0%, #00B929 50%, #00B929)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
    position: 'relative',
    zIndex: 1,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: 8,
      width: 8,
      borderRadius: '50%',
      backgroundColor: '#fff',
    },
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#C2CAD9',
  zIndex: 1,
  color: '#fff',
  width: 33,
  height: 33,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, #00B929 0%, #00B929 50%, #00B929)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, #00B929 0%, #00B929 50%, #00B929)'
  }),
}));
const ColorlibStepInnerIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#C2CAD9',
  zIndex: 1,
  color: '#fff',
  width: 10,
  height: 10,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, #00B929 0%, #00B929 50%, #00B929)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, #00B929 0%, #00B929 50%, #00B929)'
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;
  const icons: { [index: string]: React.ReactElement } = {
    Acknowledged: <Icon icon="solar:cart-check-bold" style={{ cursor: 'pointer' }} width="12" height="12" color="transparent" />,
    New: <Icon icon="solar:cart-check-bold" style={{ cursor: 'pointer' }} width="12" height="12" color="transparent" />,
    TaxCalculated: <Icon icon="heroicons-solid:receipt-tax" style={{ cursor: 'pointer' }} width="12" height="12" color="transparent" />,
    "Tax Calculated": <Icon icon="heroicons-solid:receipt-tax" style={{ cursor: 'pointer' }} width="12" height="12" color="transparent" />,
    "Order Sent to Ariba": <Icon icon="heroicons-solid:receipt-tax" style={{ cursor: 'pointer' }} width="12" height="12" color="transparent" />,
    shipped: <Icon icon="iconamoon:delivery-fast-fill" style={{ cursor: 'pointer' }} width="12" height="12" color="transparent" />,
    invoice: <Icon icon="iconamoon:invoice-fill" style={{ cursor: 'pointer' }} width="12" height="12" color="transparent" />,
    Resubmitted: <Icon icon="akar-icons:arrow-repeat" style={{ cursor: 'pointer' }} width="12" height="12" color="transparent" />,
  };

  const icon = icons[props.icon as keyof typeof icons] 

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={`custom-stepper-label-background ${className}`}>
      {icon}
    </ColorlibStepIconRoot>
  );
}
function ColorlibStepInnerIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const innerIcons: { [index: string]: React.ReactElement } = {
    1: <Icon icon="ph:dot-outline-fill" style={{ cursor: 'pointer' }} width="0" height="0" />
  };

  return (
    <ColorlibStepInnerIconRoot ownerState={{ completed, active }} className={` custom-innerstepper-icon ${className}`}>
      {innerIcons[String(props.icon)]}
    </ColorlibStepInnerIconRoot>
  );
}

const InnerStepper = (props: { comments: string[] }) => {
  const location = useLocation();
  return (
    <Stepper activeStep={2} orientation="vertical">
      <Step>
        <StepLabel className='custom-stepper-label' StepIconComponent={ColorlibStepInnerIcon}>
          {props.comments}
        </StepLabel>
      </Step>
  </Stepper>
  );
};

export default function OrderAckHistory() {
  const VerticalStepper = () => {
    const location = useLocation();
  const [orderHistoryComments, setOrderHistoryComments] = React.useState<any>({ type: '' })
  const [purchaseOrder,setPurchaseOrder]=React.useState<any>('')
  const groupedComments: { [key: string]: any[] } = {};
    React.useEffect(() => {
      const fetchdata = async () => {
        try {
          let stage=localStorage.getItem('stage')
          const searchParams = new URLSearchParams(location.search);
          const status = searchParams.get("status");
          setPurchaseOrder(status);
          // let stageText;
          // if(status== 'New' || status== 'Processing' ){
          //   stageText='New';
          //   setPurchaseOrder(stageText);
          // }
          // else if(status == 'Ack & Tax Calculated' || status == 'Sent To Ariba' ||  status == 'Sent'){
          //   stageText='Tax Calculated';
          //   setPurchaseOrder(stageText);
          // }
          // else if(stage=='2'){
          //   stageText='Shipped';
          //   setPurchaseOrder(stageText);
          // }
          // else if(stage=='3'){
          //   stageText='Resubmitted';
          //   setPurchaseOrder(stageText);
          // }
        
          // else if(stage=='4'){
          //   stageText='Invoice Sent';
          //   setPurchaseOrder(stageText);
          // }
        
          const id = searchParams.get("id");
          // calling get api for Comments history
          let response = await historyComment(id)
          setOrderHistoryComments(response)
          grpComments(response)
        }
        catch (error) {
          console.error('Error occurred:', error);
        }
      }
      fetchdata()
    }, [])

    function grpComments(response:any){
      for (const comment of response) {
        if (!groupedComments[comment.type]) {
          groupedComments[comment.type] = [];
        }
        groupedComments[comment.type].push(comment);
      }
    }
    const formatTime = (timeString: any) => {
      const time = new Date(`2000-01-01T${timeString}`);
      const formattedTime = time.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      });
      return formattedTime;
    };
    return (
      <div>
        <Grid container>
          <Grid item xs={9} className='custom-History-stepper'>
          {orderHistoryComments.length > 0 ? (
            <Stepper activeStep={orderHistoryComments.length - 1} orientation="vertical">
              {orderHistoryComments.map((label: any) => (
                <Step key={label.id}>
                  <StepLabel className='custom-stepper-head-label' StepIconComponent={ColorlibStepIcon} icon={label.type}>
                  {(label.commentInfo)?
                  <>
                  {/* <span className='custom-orderHistory-labelhead'>Commented on {label.type} <span style={{fontSize:'10px'}}>on {label.date} 
                   {formatTime(label.time)}
                   &nbsp; by </span>  {label.createdBy}</span> */}
                  {(label.type == 'sent')?
                   <span className='custom-orderHistory-labelhead'>Commented on Sent to Ariba <span style={{fontSize:'10px'}}>on {label.date} 
                   {/* {formatTime(label.time)} */}
                   &nbsp; by </span>  {label.createdBy}</span>
                  :
                  <span className='custom-orderHistory-labelhead'>Commented on {label.type} <span style={{fontSize:'10px'}}>on {label.date} 
                  {/* {formatTime(label.time)} */}
                  &nbsp; by </span>  {label.createdBy}</span>
                  }
                 </>         
                  :
                  <>
                   <span className='custom-orderHistory-labelhead'>{label.type} <span style={{fontSize:'10px'}}> on {label.date} 
                    {/* {formatTime(label.time)} */}
                    &nbsp;  by </span>  {label.createdBy}</span>
                   
                  </>                  
                  }
                  </StepLabel>
                  {(label.commentInfo)?<InnerStepper comments={label.commentInfo} />:''}
                  
                </Step>
              ))}
            </Stepper>
          ) : (
            <p>No order history comments found.</p>
          )}
          </Grid>
          <Grid item xs={3} pr={3} className='custom-History-purchase'>
            <Grid container textAlign={'center'} >
              <Grid item xs={12}>
                <h1 className='custom-History-Purchase'>Purchase Order</h1>
              </Grid>
            </Grid>
            <Grid container justifyContent={'center'} >
              <Grid item textAlign={'end'} >
                {/* { (purchaseOrder=='New')&&
                <Icon style={{margin:'0px 4px 0px 0px'}} icon="ic:baseline-new-releases" color="#00b929" /> 
                }
                { (purchaseOrder=='Ack & Tax Calculated' || purchaseOrder=='Sent To Ariba' || purchaseOrder=='Sent')&&
                <Icon style={{margin:'0px 4px 0px 0px'}} icon="heroicons-solid:receipt-tax" color="#00b929" />
                }
                 { (purchaseOrder=='Shipped')&&
                <Icon style={{margin:'0px 4px 0px 0px'}} icon="iconamoon:delivery-fast-fill" color="#00b929" />
                }
                 { (purchaseOrder=='Resubmitted' || purchaseOrder=='Rejected')&&
                <Icon style={{margin:'0px 4px 0px 0px'}} icon="akar-icons:arrow-repeat" color="#00b929" />
                }
                 { (purchaseOrder=='Invoiced' || purchaseOrder=='Approved')&&
                <Icon style={{margin:'0px 4px 0px 0px'}} icon="iconamoon:invoice-fill" color="#00b929" />
                } */}
              </Grid>
              <Grid item textAlign={'end'}>
                <h1 className='custom-History-Invoice'>{purchaseOrder}</h1>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };
  return (
    <div>
      <VerticalStepper />
    </div>
  );
}