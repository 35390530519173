import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import './order_tax_calculation.css'
import { TaxSummaryTable } from './order_tax_table.component';
import React from 'react';
import { SentAriba, checkAriba } from '../order_acks_details/order_details_api';
import { useLocation } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Swal from 'sweetalert2';
import { sendPdf } from '../invoice/invoice._api';
import { Alert } from '@mui/material';

interface AmountDetails {
  subTotalAmount: string;
  dueAmount: string;
  grossAmount: string;
  netAmount: string;
}

export default function OrderTaxCalculation(props: any) {
  const [stages, setStages] = React.useState()


  const [orderStatus, setOrderStatus] = React.useState<string>();
  const [taxDetails, setTaxDetails] = React.useState()
  const [amountDetails, setAmountDetails] = React.useState<AmountDetails>()
  const [orderButton, SetOrderButton] = React.useState<boolean>(false);
  const [SentAribaDisabled, setSentAribaDisabled] = React.useState<boolean>()
  const [SentCheckAribaDisabled, setSentCheckAribaDisabled] = React.useState<boolean>()
  const [sendInvoice, setSendInvoice] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [mailLoading, setMailLoading] = React.useState<boolean>(false);
  const [orderId, setOrderId] = React.useState<any>();
  const location = useLocation();
  const [alert, setAlert] = React.useState<string>();
  const [invoiceMessage, setInvoiceMessage] = React.useState<any>();

  React.useEffect(() => {
    let status = localStorage.getItem('autoProcess');
    const searchParams = new URLSearchParams(location.search);
    const aribaStatus = searchParams.get("status");
    const orderId = searchParams.get("id");
    setOrderId(orderId);
    setSendInvoice(aribaStatus)
    if (aribaStatus == 'Sent to Ariba' || aribaStatus == 'Sent') {
      setSentAribaDisabled(false);
      setSentCheckAribaDisabled(true);
    } else if (aribaStatus == 'Approved') {
      setSentAribaDisabled(false);
      setSentCheckAribaDisabled(false);
    } else if (aribaStatus == 'PayloadID Exist') {
      setSentAribaDisabled(false);
      setSentCheckAribaDisabled(false);
    } else {
      setSentAribaDisabled(true);
      setSentCheckAribaDisabled(false);
    }
    if(aribaStatus == 'Ack & Tax Calculated' || aribaStatus == 'Sent to Ariba' || aribaStatus == 'Sent'){
      setOrderStatus('1');
    }
    setStages(props.stage)
    if (status) {
      if (status == 'true') {
        SetOrderButton(true)
      } else {
        SetOrderButton(false)
      }
    }
    if (props.salesTaxDetails) {
      setTaxDetails(props.salesTaxDetails.taxDetails)
      setAmountDetails(props.salesTaxDetails.amountDetails)
    }
  }, [orderStatus, props.salesTaxDetails]);
  const handleSentAriba = async () => {
    setLoading(true);
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    await SentAriba(id).then((response) => {
      setLoading(false)
      if (response == 200) {
        setSentAribaDisabled(false)
        setSentCheckAribaDisabled(true)
      }
    }).catch((error) => {
      console.log('Error Occured: ', error);
    });
  }
  const handleCheckSentAriba = async () => {
    setLoading(true);
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    await checkAriba(id).then((response) => {
      if (response) {
        setLoading(false)
      }
    }).catch((error) => {
      console.log('Error Occured: ', error);
    });
  }

  const alertClose = () => {
    setAlert('')
  }

  const handleInvoice = async () => {
    setMailLoading(true)
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    await sendPdf(id).then((response:any) => {
      if (response.status_code == 200) {
        setInvoiceMessage(response.message)
        setAlert('true')
        setTimeout(() => {
          setAlert('');
          window.location.reload();
        }, 1000);
      } else if(response.status_code == 400) {
        setInvoiceMessage(response.message)
        setAlert('false')
        setTimeout(() => {
          setAlert('');
        }, 2000);
      }else{
        setInvoiceMessage('Something went wrong')
        setAlert('false')
        setTimeout(() => {
          setAlert('');
        }, 2000);
      }
      setMailLoading(false)
    }).catch((error) => {
      console.log('Error Occured: ', error);
    });
  }
  return (
    <div className='custom-order-tax'>
      <Grid container className='custom-tax-container'>
        <Grid item xs={12}>
          <TaxSummaryTable taxDetails={taxDetails} amountDetails={amountDetails} />
        </Grid>
      </Grid>
      <Grid container className='custom-totalAmount-container'>
        <Grid item xs={7} >

        </Grid>
        <Grid item xs={5}>
          <Grid container>
            <Grid item xs={7} pl={3}>
              <h1 className='custom-tax-totalAmount'>Amount Summary</h1>
            </Grid>
            <Grid item xs={3} pl={3}>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={7} pl={3}>
              <p className='custom-amountSummary'>Line item subtotal</p>
            </Grid>
            <Grid item xs={3} pl={3}>
              <p className='custom-amountSummary'>{amountDetails?.subTotalAmount}</p>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={7} pl={3}>
              <p className='custom-amountSummary'>Gross amount</p>
            </Grid>
            <Grid item xs={3} pl={3}>
              <p className='custom-amountSummary'>{amountDetails?.grossAmount}</p>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={7} pl={3}>
              <p className='custom-amountSummary'><b>Net Amount</b></p>
            </Grid>
            <Grid item xs={3} pl={3}>
              <p className='custom-amountSummary'><b>{amountDetails?.netAmount}</b></p>
            </Grid>
          </Grid>
          {/* <Grid container>
          <Grid item xs={7} pl={3}>
            <p className='custom-amountSummary'>Total withholding tax amount</p>
          </Grid>
          <Grid item xs={3} pl={3}>
            <p className='custom-amountSummary'>$ 73.75 USD</p>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={7} pl={3}>
            <p className='custom-amountSummary'>Total tax amount</p>
          </Grid>
          <Grid item xs={3} pl={3}>
            <p className='custom-amountSummary'>$ 73.75 USD</p>
          </Grid>
        </Grid> */}
          <Grid container>
            <Grid item xs={7} pl={3}>
              <p className='custom-amountSummary'><b>Amount Due</b></p>
            </Grid>
            <Grid item xs={3} pl={3}>
              <p className='custom-amountSummary'><b>{amountDetails?.dueAmount}</b></p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {(orderStatus == '1') ?
        <Grid container justifyContent={'end'}>
          {(loading) ?
            <Grid item xs={4} pt={3} mr={2} textAlign={'end'}>
              <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} style={{ color: 'white', backgroundColor: '#5755FF', padding: '2px 12px', fontSize: '12px', textTransform: 'initial' }} variant="contained" > Loading </LoadingButton>
            </Grid>
            :
            <Grid item xs={4} pt={3} mr={2} textAlign={'end'}>
              {(SentAribaDisabled) ?
                <Button className='custom-Create-sentAriba' onClick={handleSentAriba}>Send Ariba</Button> : ''} &nbsp;
              {(SentCheckAribaDisabled) ? <Button className='custom-Create-Shipment' onClick={handleCheckSentAriba}>Check Ariba Status</Button> : ''}
            </Grid>
          }
        </Grid>
        : ''}

      {(props.stage == undefined) ? (sendInvoice == 'Approved' || sendInvoice == 'Invoiced') ?
        <Grid container justifyContent={'end'}>
          {(mailLoading) ?
            <Grid item xs={4} pt={3} mr={2} textAlign={'end'}>
              <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} style={{ color: 'white', backgroundColor: '#5755FF', padding: '2px 12px', fontSize: '12px', textTransform: 'initial' }} variant="contained" > Loading </LoadingButton>
            </Grid>
            :
            <Grid item xs={4} pt={3} mr={2} textAlign={'end'}>
              <Button className='custom-Create-Shipment' style={{ margin: '0rem 2.5rem 0rem 0rem', padding: '2px 10px !important' }} onClick={handleInvoice}>Send Invoice</Button>
            </Grid>
          }
          {/* {(alert == 'true') ? <Alert severity='success' onClose={alertClose}>Mail Sent Successfully</Alert> : (alert == 'false') ? <Alert onClose={alertClose} severity='error'>Some thing went wrong</Alert> : ''}          */}
          {
            (alert == 'true') ?
              <Grid container justifyContent={'end'}>
                <Grid item xs={3} mr={2} pt={1} textAlign={'end'}>
                  <Alert severity='success' style={{ fontSize: '12px', paddingTop: '0px', paddingBottom: '0px' }} onClose={alertClose}>{invoiceMessage}</Alert>
                </Grid>
              </Grid>
              :
              (alert == 'false') ?
                <Grid container justifyContent={'end'}>
                  <Grid item xs={3} mr={2} pt={1} textAlign={'end'}>
                    <Alert onClose={alertClose} style={{ fontSize: '12px', paddingTop: '0px', paddingBottom: '0px' }} severity='error'>{invoiceMessage}</Alert>
                  </Grid>
                </Grid>
                : ''
          }
        </Grid>
        :
        '' : (props.stage == 4) ? <Grid container justifyContent={'end'}>
          {(mailLoading) ?
            <Grid item xs={4} pt={3} mr={5}  textAlign={'end'}>
              <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} style={{ color: 'white', backgroundColor: '#5755FF', padding: '2px 12px', fontSize: '12px', textTransform: 'initial' }} variant="contained" > Loading </LoadingButton>
            </Grid>
            :
            <Grid item xs={4} pt={3} mr={2} textAlign={'end'}>
              <Button className='custom-Create-Shipment' style={{ margin: '0rem 2.5rem 0rem 0rem', padding: '2px 10px !important' }} onClick={handleInvoice}>Send Invoice</Button>
            </Grid>
          }
          {/* {(alert == 'true') ? <Alert severity='success' onClose={alertClose}>Mail Sent Successfully</Alert> : (alert == 'false') ? <Alert onClose={alertClose} severity='error'>Some thing went wrong</Alert> : ''}          */}
          {
            (alert == 'true') ?
              <Grid container justifyContent={'end'}>
                <Grid item xs={3} mr={2} pt={1} textAlign={'end'}>
                  <Alert severity='success' style={{ fontSize: '12px', paddingTop: '0px', paddingBottom: '0px' }} onClose={alertClose}>{invoiceMessage}</Alert>
                </Grid>
              </Grid>
              :
              (alert == 'false') ?
                <Grid container justifyContent={'end'}>
                  <Grid item xs={3} mr={2} pt={1} textAlign={'end'}>
                    <Alert onClose={alertClose} style={{ fontSize: '12px', paddingTop: '0px', paddingBottom: '0px' }} severity='error'>{invoiceMessage}</Alert>
                  </Grid>
                </Grid>
                : ''
          }
        </Grid> : ''}
    </div>
  )
}

