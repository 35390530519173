import React from 'react'
import InvoiceHeaderNav from './invoice_header/invoice_header'
import InvoiceShipment from './invoice_shippment/invoice_shipment.component'
import { Alert, Button, CircularProgress, Grid, TextField } from '@mui/material'
import InvoiceFooter from './invoice_footer/invoice_footer.component'
import { getSalesOrderDetails, sendPdf, downloadPdf } from './invoice._api'
import { useLocation } from 'react-router-dom'
import html2pdf from 'html2pdf.js';
import { InvoiceLineItemsTable } from './invoice_lineItems/invoice_lineItems.component'
import InvoiceTaxCalculation from './invoice_Tax/invoice_tax_details.component'
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { Icon } from '@iconify/react'
import Swal from 'sweetalert2';
import LinearProgress from '@mui/material/LinearProgress';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import PDFViewer from './invoice_Tax/pdf_viewer'
import './invoice_Tax/pdfview.css';

interface SalesOrderResponse {
    url: string;
    // other properties if any
}

export default function Invoice() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const [salesOrderDetails, setSalesOrderDetails] = React.useState();
  const [salesShippingDetails, setShippingOrderDetails] = React.useState();
  const [salesLineItemDetails, setLineItemDetails] = React.useState();
  const [salesTaxDetails, setTaxDetails] = React.useState();
  const [orderId, setOrderId] = React.useState<any>();
  const [customerName, setCustomerName] = React.useState<any>();
  const [alert, setAlert] = React.useState<string>();
  const [spinner, setSpinner] = React.useState(false);
  const [htmlContent, setHtmlContent] = React.useState<string>(''); // Initialize with an empty string
  const [pdfView, setPdfView] = React.useState<any>();
  const [invoiceMessage, setInvoiceMessage] = React.useState<any>();

  const ALERT_DURATION = 5000;
  
  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    const type = searchParams.get("type");
    setOrderId(id)
    setCustomerName(localStorage.getItem("customerName"))
    const status = searchParams.get("status");
    let salesOrder: any;
    const fetchOrderData = async () => {
      setSpinner(true)
      try {
        // Get Api for sales order details
        await getSalesOrderDetails(id).then((res) => {
          if (res) {
            // const pdfUrl = `${apiUrl}${res}`;
            // setPdfView(pdfUrl)
            setHtmlContent(res);
            setSpinner(false)
            // setSalesOrderDetails(data['orderDetails']);
            // setShippingOrderDetails(data['shipping']);
            // setLineItemDetails(data['lineItems']);
            // setTaxDetails(data['taxSummary']);
            // if (type == 'mail') {
            //   setSpinner(true);
            //   const timer = setTimeout(() => {
            //     handleMail();
            //   }, 20000);

            //   return () => {
            //     // Clean up the timer if the component unmounts before one minute
            //     clearTimeout(timer);
            //   };
            // }

          } else {
            console.log("Sales order not found or missing order details.");
          }
        });

      } catch (error) {
        console.error('Error occurred:', error);
      }
    };
    fetchOrderData();
  }, []);

  // const handleSubmit = () => {
  //   const element = document.getElementById('invoicePdf');
  //   html2pdf().from(element).save(`${orderId}_${customerName}.pdf`).get('pdf')
  //     .then((pdf: any) => {
  //       const pdfBlob = new Blob([pdf.output('blob')], { type: 'application/pdf' });

  //     });
  // }

  const handlePrint = () => {
    // const printContent = document.getElementById('print-section');
    // if (printContent) {
    //   const printWindow = window.open('', '_blank');
    //   printWindow!.document.write(`
    //     <!DOCTYPE html>
    //     <html>
    //       <head>
    //         <title>Print Invoice</title>
    //       </head>
    //       <body>
    //         ${printContent.innerHTML}
    //       </body>
    //     </html>
    //   `);
    //   printWindow!.print();
    //   printWindow!.document.close();
    // }
    window.print();
  }
  const alertClose = () => {
    setAlert('')
  }

  const handlePdfDownload = async() => {
    setSpinner(true)
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");

    await downloadPdf(id).then((response) => {
      console.log('responseeee',response);
      setSpinner(false)
    }).catch((error) => {
      console.log('Error Occured: ', error);
    });
  }
  const handleMail = async () => {
    setSpinner(true)
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");

    await sendPdf(id).then((response:any) => {
      if (response.status_code == 200) {
        setInvoiceMessage(response.message)
        setAlert('true')
        setTimeout(() => {
          setAlert('');
          window.location.reload();
        }, 1000);
      } else if(response.status_code == 400) {
        setInvoiceMessage(response.message)
        setAlert('false')
        setTimeout(() => {
          setAlert('');
        }, 2000);
      }else{
        setInvoiceMessage('Something went wrong')
        setAlert('false')
        setTimeout(() => {
          setAlert('');
        }, 2000);
      }
      setSpinner(false)
    }).catch((error) => {
      console.log('Error Occured: ', error);
    });
  }
  return (
    <>
      {(spinner) ? <LinearProgress /> : ''}
      <div className='main-content'>
        <Grid container>
          <Grid item xs={1} py={1} pl={2} textAlign={'start'}>
            <Icon icon="ic:sharp-arrow-back" color="#a1acb3" width="24" height="24" style={{ cursor: 'pointer' }} onClick={() => { window.location.href = '/fulfillment' }} /> &nbsp;
          </Grid>
          <Grid item xs={11} py={1} pr={1} textAlign={'end'}>
            <Icon icon="ic:baseline-email" color="#a1acb3" width="24" height="24" style={{ cursor: 'pointer' }} onClick={handleMail} /> &nbsp;
            <PrintRoundedIcon className='custom-icon' onClick={handlePrint} /> &nbsp;
            <FileDownloadRoundedIcon className='custom-icon' onClick={handlePdfDownload} />
          </Grid>
        </Grid>
        <Grid container id="invoicePdf" >
          {(alert == 'true') ? <Alert severity='success' onClose={alertClose}>{invoiceMessage}</Alert> : (alert == 'false') ? <Alert onClose={alertClose} severity='error'>{invoiceMessage}</Alert> : ''}
          <Grid item xs={12}>
            {/* <div className="watermark">
                    </div> */}
            {/* <InvoiceHeaderNav salesOrderDetails={salesOrderDetails} />
            <InvoiceShipment salesShippingDetails={salesShippingDetails} salesOrderDetails={salesOrderDetails} />
            <InvoiceLineItemsTable salesLineItemDetails={salesLineItemDetails} />
            <InvoiceTaxCalculation salesTaxDetails={salesTaxDetails} invoice={'invoice'} />
            <InvoiceFooter /> */}
            {/* <div id='print-section' className="html-content" dangerouslySetInnerHTML={{ __html: htmlContent }}></div> */}
            {/* <div>
              <PDFViewer pdfUrl={pdfUrl} />
            </div> */}
            {/* <div className="pdf-container"> */}
              {/* <Document file='/files/invoice.pdf'>
                <Page pageNumber={1} />
              </Document> */}
              {/* <iframe src='/files/invoice.pdf' width="100%" height="600px" title="PDF Viewer" /> */}
            {/* </div> */}
            {/* <div className="">
              <PDFViewer pdfUrl={pdfView} />
            </div> */}
          </Grid>
        </Grid>
      </div>
      <div id='print-section' className="html-content" dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
    </>

  )
}
