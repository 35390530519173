import React from 'react'
import './shipping_editAddress_modal.css'
import { Box, Button, Grid, TextField } from '@mui/material';
import { Icon } from '@iconify/react';
import { Formik, Form, Field, ErrorMessage, FormikProps } from "formik";
import * as Yup from "yup";
import { shipAddress } from '../resubmission_api';


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: '#FFFFFF;',
    boxShadow: 2,
    maxHeight: '80vh', // Set a maximum height for the modal container
    overflowY: 'auto',

};
export default function ShippingEditAddressModal(props: any) {
    const formRef = React.useRef<FormikProps<any>>(null);
    const handleClose = () => {
        props.handleCloseUnitModal()
    }
    let shippingAddress = props.shippingDetail.editAddress;
    const initialValues = {
        shippingFromName: shippingAddress.shipFrom.name,
        shippingFromDepartmentName: "",
        shippingFromAddress1: shippingAddress.shipFrom.address,
        shippingFromAddress2: "",
        shippingFromAddress3: "",
        shippingFromCity: shippingAddress.shipFrom.city,
        shippingFromState: shippingAddress.shipFrom.state,
        shippingFromCountry: shippingAddress.shipFrom.country,
        shippingFromZip: shippingAddress.shipFrom.zip,
        shippingToName: "",
        shippingToDepartmentName: "",
        shippingToAddress1: "",
        shippingToAddress2: "",
        shippingToAddress3: "",
        shippingToCity: "",
        shippingToState: "",
        shippingToCountry: "",
        shippingToZip: "",
        deliverTo: ""
    };

    React.useEffect(() => {
        formRef.current?.setFieldValue('shippingToName', shippingAddress.shipTo.name)
        formRef.current?.setFieldValue('shippingToAddress1', shippingAddress.shipTo.address)
        formRef.current?.setFieldValue('shippingToAddress2', shippingAddress.shipTo.address1)
        formRef.current?.setFieldValue('shippingToCity', shippingAddress.shipTo.city)
        formRef.current?.setFieldValue('shippingToState', shippingAddress.shipTo.state)
        formRef.current?.setFieldValue('shippingToCountry', shippingAddress.shipTo.country)
        formRef.current?.setFieldValue('shippingToZip', shippingAddress.shipTo.zip)

    }, [])



    const handleSubmit = async (values: any) => {
        props.handleEditAddress(values)
        try {
            await shipAddress(values).then((response: any) => {
                handleClose()
            })
        } catch (error) {
            console.log("Error Ocuured :", error)
        }
    }
    return (
        <div>
            <Box sx={style} className='custom-shipping-edit-modal'>
                <Grid container className='custom-ShippingEditAddressModal-container'>
                    <Grid item md={6} py={2}>
                        <h1 className='custom-shipping-edit-head'>View / Edit Address</h1>
                    </Grid>
                    <Grid item md={6} py={2} textAlign={'end'}>
                        <Icon icon="carbon:close-filled" color="#e01313" width="20" height="20" onClick={handleClose} />
                    </Grid>
                </Grid>
                <Formik initialValues={initialValues} onSubmit={handleSubmit} innerRef={formRef}>
                    <Form>
                        <Grid container>
                            <Grid item xs={6} >
                                <Grid container>
                                    <Grid item xs={6}>
                                        <p className='custom-ShippingEditAddressModal-head'> Shipping From :</p>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} pr={2} py={1}>
                                        <Field as={TextField} fullWidth className='custom-shipping-edit-fields' size='small' id="shippingFromName" name="shippingFromName" label="Name" variant="outlined" disabled />
                                    </Grid>
                                </Grid>
                                {/* <Grid container>
                                        <Grid item xs={12} pr={2} py={1} >
                                             <Field as={TextField} fullWidth className='custom-shipping-edit-fields' size='small' value={shippingAddress.shipFrom.department} id="shippingFromDepartmentName" name="shippingFromDepartmentName" label="Department Name" variant="outlined" disabled/>
                                        </Grid>
                                    </Grid> */}
                                <Grid container>
                                    <Grid item xs={12} pr={2} py={1} >
                                        <Field as={TextField} fullWidth className='custom-shipping-edit-fields' size='small' id="shippingFromAddress1" name="shippingFromAddress1" label="Address 1" variant="outlined" disabled />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} pr={2} py={1} >
                                        <Field as={TextField} fullWidth className='custom-shipping-edit-fields' size='small' id="shippingFromAddress2" name="shippingFromAddress2" label="Address 2" variant="outlined" disabled />
                                    </Grid>
                                </Grid>
                                {/* <Grid container>
                                        <Grid item xs={12} pr={2} py={1} >
                                             <Field as={TextField} fullWidth className='custom-shipping-edit-fields' size='small'value={shippingAddress.shipFrom.department} id="shippingFromAddress3" name="shippingFromAddress3" label="Address 3" variant="outlined" />
                                        </Grid>
                                    </Grid> */}
                                <Grid container>
                                    <Grid item xs={12} pr={2} py={1} >
                                        <Field as={TextField} fullWidth className='custom-shipping-edit-fields' size='small' id="shippingFromCity" name="shippingFromCity" label="City" variant="outlined" disabled />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} pr={2} py={1} >
                                        <Field as={TextField} fullWidth className='custom-shipping-edit-fields' size='small' id="shippingFromState" name="shippingFromState" label="State" variant="outlined" disabled />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} pr={2} py={1} >
                                        <Field as={TextField} fullWidth className='custom-shipping-edit-fields' size='small' id="shippingFromZip" name="shippingFromZip" label="Zip" variant="outlined" disabled />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} pr={2} py={1} >
                                        <Field as={TextField} fullWidth className='custom-shipping-edit-fields' size='small' id="shippingFromCountry" name="shippingFromCountry" label="Country / Region" variant="outlined" disabled />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} pr={2} py={1} >
                                        <p className='custom-ShippingEditAddressModal-text'>This selection will refresh the page content</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} >
                                <Grid container>
                                    <Grid item xs={6} >
                                        <p className='custom-ShippingEditAddressModal-head'> Shipping To</p>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} py={1}>
                                        <Field as={TextField} fullWidth className='custom-shipping-edit-fields' size='small' id="shippingToName" name="shippingToName" label="Name" variant="outlined" />
                                    </Grid>
                                </Grid>
                                {/* <Grid container>
                                        <Grid item xs={12} py={1} >
                                             <Field as={TextField} fullWidth className='custom-shipping-edit-fields' size='small' value={shippingAddress.shipTo.department} id="shippingToDepartmentName" name="shippingToDepartmentName" label="Department Name" variant="outlined" />
                                        </Grid>
                                    </Grid> */}
                                <Grid container>
                                    <Grid item xs={12} py={1} >
                                        <Field as={TextField} fullWidth className='custom-shipping-edit-fields' size='small' id="shippingToAddress1" name="shippingToAddress1" label="Address 1" variant="outlined" />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} py={1} >
                                        <Field as={TextField} fullWidth className='custom-shipping-edit-fields' size='small' id="Address 2" name="shippingToAddress2" label="Address 2" variant="outlined" />
                                    </Grid>
                                </Grid>
                                {/* <Grid container>
                                        <Grid item xs={12} py={1} >
                                             <Field as={TextField} fullWidth className='custom-shipping-edit-fields' size='small' id="shippingToAddress3" name="shippingToAddress3" label="Address 3" variant="outlined" />
                                        </Grid>
                                    </Grid> */}
                                <Grid container>
                                    <Grid item xs={12} py={1} >
                                        <Field as={TextField} fullWidth className='custom-shipping-edit-fields' size='small' id="shippingToCity" name="shippingToCity" label="City" variant="outlined" />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} py={1} >
                                        <Field as={TextField} fullWidth className='custom-shipping-edit-fields' size='small' id="shippingToState" name="shippingToState" label="State" variant="outlined" />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} py={1} >
                                        <Field as={TextField} fullWidth className='custom-shipping-edit-fields' size='small' id="shippingToZip" name="shippingToZip" label="Zip" variant="outlined" />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} py={1} >
                                        <Field as={TextField} fullWidth className='custom-shipping-edit-fields' size='small' id="shippingToCountry" name="shippingToCountry" label="Country / Region" variant="outlined" />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} py={1} >
                                        <p className='custom-ShippingEditAddressModal-text'>This selection will refresh the page content</p>
                                    </Grid>
                                </Grid>
                                {/* <Grid container>
                                        <Grid item xs={12} py={1} >
                                             <Field as={TextField} fullWidth className='custom-shipping-edit-fields' size='small' value={shippingAddress.shipTo.department} id="deliverTo" name="deliverTo" label="Deliver To" variant="outlined" />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} py={1} >
                                            <p className='custom-ShippingEditAddressModal-text'>This selection will refresh the page content</p>
                                        </Grid>
                                    </Grid> */}
                            </Grid>
                        </Grid>
                        <Grid container pt={3} className='custom-editModal-footer'>
                            <Grid item md={6} textAlign={"end"}>
                                <Button type="submit" className="custom-shipping-edit-save-btn">Ok</Button>
                            </Grid>
                            <Grid item md={6} pl={2} textAlign={"start"}>
                                <Button className="custom-shipping-edit-cancel-btn" onClick={handleClose}>Cancel</Button>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </Box>
        </div>
    )
}

