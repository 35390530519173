import './order_acks_details_table.css'
import Grid from '@mui/material/Grid';
import Button, { buttonClasses } from '@mui/base/Button';
import React from 'react';
import { LineItemsTable } from './order_line_table.component';
import { orderConfirm } from '../order_acks_details/order_details_api';
import { useLocation } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

export default function OrderAcksDetailsTable(props: any) {
  const [lineItems, setLineItems] = React.useState()
  const [totalAmount, setTotalAmount] = React.useState()
  // const [status, SetStatus] = React.useState(localStorage.getItem('stage'))
  const [status, SetStatus] = React.useState<any>()
  const [orderButton, SetOrderButton] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const location = useLocation();
  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    const status = searchParams.get("status");
    SetStatus(status)
    let autoProcess = localStorage.getItem('autoprocess');
    if (autoProcess) {
      if (autoProcess == 'true' || status == '0') {
        SetOrderButton(true)
      } else {
        SetOrderButton(false)
      }
    }

    if (props.salesLineItemDetails) {
      // SetStatus(localStorage.getItem('stage'))
      setLineItems(props.salesLineItemDetails['itemDetails'])
      setTotalAmount(props.salesLineItemDetails['totalAmount'])
    }
  }, [props.salesLineItemDetails])

  const handleOrderConfirm = async () => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    setLoading(true)
    await orderConfirm(id).then((res: any) => {
    })
  }
  return (
    <div className="custom-OrderAcksLineDetailsTable-item-table">
      <Grid container className='custom-LineDetails-container' >
        <Grid item xs={12}>
          <Button className='custom-LineDetails-Header'>Line Items</Button>
        </Grid>
      </Grid>
      <Grid container >
        <Grid item xs={12}>
          <LineItemsTable lineItems={lineItems} />
        </Grid>
      </Grid>
      <div className='custom-totalamout-container'>
        <Grid container >
          <Grid item xs={10} pl={3} >
            <h1 className='custom-totalAmount'>SubTotal </h1>
          </Grid>
          <Grid item xs={2} pl={3}>
            <h1 className='custom-totalAmount' >{totalAmount}</h1>
          </Grid>
        </Grid>
        {status == 'New' ?
          <Grid container justifyContent={'right'}>
            {(loading) ?
              <Grid item xs={2} pt={2} mr={4} textAlign={'end'}>
                <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} style={{ color: 'white', backgroundColor: '#5755FF',padding:'2px 12px',textTransform:'initial' }} variant="contained" > Loading </LoadingButton>
              </Grid>
              :
              <Grid item xs={4} md={3} lg={2} pt={2} textAlign={'end'}>
                <Button className='custom-Create-Order-Shipment' onClick={handleOrderConfirm}>Create Order Confirmation</Button>
              </Grid>
            }
          </Grid>
          : ''}
      </div>
    </div>
  )
}


