import { ApiService } from "../../../../service/api.service";
import Swal from 'sweetalert2';

const apiService = new ApiService();

export const customerAddressType = async () => {
  let result;
  await apiService.getAll('api/addressType/').then((response: any) => {
    if (response.status_code == 200) {
      result = response.data
    }
  })
    .catch(error => {
      console.error('Error occurred:', error);
      if (error.response.status == 401) {
        //apiService.tokenExpired();
      }
    });
  return result;
}

export const getCustomerAddress = async (orderId: any, type: any) => {
  let result;
  await apiService.getAll(`api/getContactInfo/?orderId=${orderId}&type=${type}`).then((response: any) => {
    if (response.status_code == 200) {
      result = response.data
    }
  })
    .catch(error => {
      console.error('Error occurred:', error);
      if (error.response.status == 401) {
        //apiService.tokenExpired();
      }
    });
  return result;
}

