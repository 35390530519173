import React from 'react'
import { Grid, InputAdornment, TextField, FormControl, Select, MenuItem, InputLabel, Paper, Button, Modal } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import TableViewIcon from '@mui/icons-material/TableView';
import AddIcon from '@mui/icons-material/Add';
import './customer.css';



export default function CustomerFilters(props:any) {
    return (
        <div>
            <Grid container>
                <Grid item xs={6}>
                    <h1 className='custom-customer-header'>Customer Management</h1>
                </Grid>
                <Grid item xs={6} textAlign={'right'} px={2} py={2}>
                    <PrintRoundedIcon className='custom-icon' />
                    <FileDownloadRoundedIcon className='custom-icon' />
                </Grid>
            </Grid>

            <Grid container px={2}>
                <Grid item xs={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={1}>
                            <div className='custom-table-icon'>
                                <TableViewIcon/>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField className='custom-text-field' placeholder='Search'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon className='search-icon' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container justifyContent={'right'}>
                       <a href='/addCustomer'><Button variant="contained" className='custom-customer-btn'><AddIcon className='custom-add-icon'/>&nbsp;Add Customer</Button></a>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
