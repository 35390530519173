import React from 'react'
import { Grid, InputAdornment, TextField, Button, Modal, IconButton, Menu, MenuItem, Box, Typography, Alert, Backdrop } from '@mui/material';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import { TableColumnSettingsComponent } from '../../table_settings/table_settings.component';
import './item_list.css'
import { Icon } from '@iconify/react';
import Swal from 'sweetalert2';
import { deleteItem, downloadItemCsv, downloadItemPdf, itemsFileUpload } from '../add_item/add_item';
import LoadingButton from '@mui/lab/LoadingButton';

const itemstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 480,
    bgcolor: 'background.paper',
    border: '0px solid #ffffff',
    // boxShadow: 24,
    p: '16px 32px',
    borderRadius:2,
};
export default function ItemListComponent(props: any) {
    const [openTable, setOpenTable] = React.useState(false);
    const handleOpenTable = () => setOpenTable(true);
    const handleCloseTable = () => setOpenTable(false);
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const [fileName, setFileName] = React.useState<any>("");
    const [file, setFile] = React.useState("");
    const [loading, setLoading] = React.useState<boolean>(false);
    const [commentError, setCommentError] = React.useState(false);
    const [alert, setAlert] = React.useState<string>();
    const [responseMsg, setResponseMsg] = React.useState();
    const ALERT_DURATION = 1500;

    
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCloseTableModal = () => {
        setOpenTable(false);
    }
    const handleSearchQueryChange = (event: any) => {
        const { value } = event.target;
        props.serachQuery(value);
    };
    const [buttonId, setButtonId] = React.useState();
    React.useEffect(() => {
        setButtonId(props.buttonId);
    }, [props.buttonId]);
    const handleButtonId = (buttonId: any) => {
        props.handleButtonId(buttonId)
    }
    const handleOrderDelete = async () => {
        Swal.fire({
            title: 'Delete Item',
            text: 'Are you sure you want to delete this item?',
            // icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            allowOutsideClick:false
        }).then(async result => {
            if (result.isConfirmed) {
                await deleteItem(props.selectedRowData).then((response) => {
                    Swal.fire({title:response,allowOutsideClick:false}).then(() => { window.location.reload(); });
                });
            }
        });
    }
    // console.log('hhhhhhhh',props.itemTableColumns);

   
    const handleDownloadExcel = async () => {
        console.log(props.itemTableColumns)
        const data = props.itemTableColumns
        const namesOnly = data.map((item: { name: any; }) => item.name);
        let result = await downloadItemCsv(props.selectedRowData,namesOnly);
        setAnchorEl(null);
    }
    const handleDownloadPdf = async () => {
        let result = await downloadItemPdf(props.selectedRowData);
        setAnchorEl(null);
    }

    const handleFileChange = (event: any) => {
        let file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            setFileName(file.name);
            setFile(file)
            setCommentError(false);
          };
          reader.readAsBinaryString(file);
        }
      };
      
      
      const handleItemsFileUpload = () => {
        setLoading(true)
        if (!file) {
          setCommentError(true);
        } else {
          const formData = new FormData();
          formData.append('FILE', file);
          let response = itemsFileUpload(formData).then((res) => {   
            if(res?.status == 200){
                setResponseMsg(res.message)
                setLoading(false)
                setAlert('true')
                setTimeout(() => {
                    setAlert('');
                    window.location.reload();
                }, ALERT_DURATION);
                //  Swal.fire(res.message ).then(() => { window.location.reload(); });
            }
            if(res?.status == 400){
                setResponseMsg(res.message)
                setLoading(false)
                setAlert('false')
                setTimeout(() => {
                    // setAlert('');
                    setFile('')
                    setFileName('');
                    // window.location.reload();
                }, ALERT_DURATION);
                // Swal.fire( res.message).then(() => { window.location.reload(); });
           }
           if(res?.status == 500){
            setResponseMsg(res.message)
            setLoading(false)
            setAlert('false')
            setTimeout(() => {
                // setAlert('');
                setFile('')
                setFileName('');
                // window.location.reload();
            }, ALERT_DURATION);
            // Swal.fire( res.message).then(() => { window.location.reload(); });
       }      
          });
        }
      };
      
    const handleCloseModal = () => {
        setFileName("")
        setFile("")
        setOpen(false);
    }
    const alertClose = () => {
        setAlert('')
        // window.location.reload();
    }
    return (
        <div>
            <Grid container>
                <Grid item xs={6} pl={2}>
                    {/* <h1 className='custom-itemList-header'>Item Management</h1> */}
                </Grid>
                <Grid item xs={6} textAlign={'right'} px={2} py={2}>
                    <Grid container justifyContent={'end'}>
                        <Grid item >
                            {/* <PrintRoundedIcon className='custom-icon' /> */}
                        </Grid>
                        <Grid item >
                            {auth && (
                                <div>
                                    <IconButton style={{ padding: '0px' }}
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleMenu}
                                        color="inherit"
                                        className='custom-download-icon-button'
                                    >
                                        <Icon icon="ic:file-download" color="#a1acb3" width="24" height="24" className='custom-icon' />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        <MenuItem onClick={handleDownloadExcel}>Export as xlsx</MenuItem>
                                        {/* <MenuItem onClick={handleDownloadPdf}>Export as PDF</MenuItem> */}
                                    </Menu>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container px={2} pt={1}>
                <Grid item xs={6}>
                    <Grid container spacing={2} className='custom-itemlist-fieldItem-1'>
                        <Grid item  pt={2}>
                            <div className='custom-itemList-icon' onClick={handleOpenTable}>
                                <Icon icon="majesticons:table" className='custom-item-table-icon' color="#464f60" height='20px' width='20px' onClick={handleOpenTable} />
                            </div>
                        </Grid>
                        <Grid item xs={5} mt={1} className='custom-itemlist-fieldItem-2'>
                            <TextField fullWidth className='custom-itemList-field' placeholder='Search' onChange={handleSearchQueryChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon icon="carbon:search" height='15px' width='15px' color="#868fa0" className='search-icon' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container justifyContent={'right'}>
                        <Grid item textAlign={'end'} pr={1} style={{ paddingTop: '3px' }}>
                            {(props.deleteDisable) ? <Icon icon="ic:baseline-delete" onClick={handleOrderDelete} style={{ cursor: 'pointer' }} color="red" width="20" height="20" /> : ''}
                        </Grid>
                        <Grid item textAlign={'end'} pr={1} style={{ paddingRight:'14px' }}>
                            {/* <Icon icon="ic:outline-upload-file" onClick={handleOpen} style={{ cursor: 'pointer' }} color="#868fa0" width="22" height="22" /> */}
                            <Button className='custom-itemList-btn' onClick={handleOpen}><Icon icon="ic:outline-file-upload" height='17px' width='17px'/>&nbsp; Bulk Items </Button>
                        </Grid>
                        <Grid item textAlign={'end'}>
                            <a href='/addItem'><Button className='custom-itemList-btn'><AddIcon className='custom-add-icon' />&nbsp;Add Item</Button></a>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Modal open={openTable} onClose={handleCloseTable}>
                <div>
                    <TableColumnSettingsComponent handleCloseTableModal={handleCloseTableModal} handleButtonId={handleButtonId} type={'item'} buttonId={buttonId} buttonLabel={props.buttonLabel} />
                </div>
            </Modal>
            <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropComponent={Backdrop}
                BackdropProps={{ style: { pointerEvents: 'none' },  }}
            >
                <Box sx={itemstyle}>
                {(alert == 'true') ? <Alert onClose={alertClose}>{responseMsg}</Alert> : (alert == 'false') ? <Alert onClose={alertClose} severity='error'>{responseMsg}</Alert> : ''}
                    <Grid container >
                        <Grid item xs={12} textAlign={'start'}>
                            <Typography pb={3} className='model-tilte'>Upload Bulk Items </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="Items Upload" variant="outlined" size='small' className="custom-file-upload" 
                                  value={fileName} 
                                fullWidth InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton color="primary" aria-label="upload picture" component="label">
                                                <input hidden type="file" accept='.xlsx'  onChange={handleFileChange}
                                                  key={file} 
                                                />
                                                <AttachFileIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}>
                            </TextField>
                            {commentError && <p className="custom-error-message">File is required.</p>}
                        </Grid>
                        <Grid item xs={12}>
                            <p className="custom-error-message" style={{color:'black'}}>*Upload .xlsx Files only.</p>
                        </Grid>
                    </Grid>
                    
                    <Grid container mt={2} justifyContent={'center'} columnGap={2}>
                        {(loading) ?
                            <Grid item lg={4} md={4} sm={5} xs={5} textAlign={'end'}>
                                <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} style={{ color: 'white', backgroundColor: '#1976d2' }} variant="contained" > Loading </LoadingButton>
                            </Grid>
                            :
                            <Grid item lg={4} md={4} sm={5} xs={5} textAlign={'end'}>
                                <Button type="submit" variant="contained" color="primary" onClick={handleItemsFileUpload}> Upload </Button>
                            </Grid>
                        }
                        <Grid item lg={3} md={4} sm={5} xs={5}>
                            <Button variant="contained" color="error" onClick={handleCloseModal}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    )
}