import axios from 'axios';

// Function to set the JWT token in the Authorization header of axios requests
export const setAuthToken = (): void => {
  let token = getAuthToken()
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

// Function to retrieve the JWT token from local storage
export const getAuthToken = (): string | null => {
  return localStorage.getItem('authToken');
};

// Function to store the JWT token in local storage
export const saveAuthToken = (token: string): void => {
  localStorage.setItem('authToken', token);
  setAuthToken();
};

// Function to remove the JWT token from local storage
export const removeAuthToken = (): void => {
  localStorage.removeItem('authToken');
};

export class Authentication{
  public isAuthenticated(): boolean {
    const token = localStorage.getItem('authToken');
    if (token) {
     return true;
    } else {
      return false;
    }
  }
}


// export class Authentication{
//   public isAuthenticated(): boolean {
//     const token = localStorage.getItem('authToken');
//     if (token) {
//       // Decode the token and check its expiration time
//       const decodedToken = decodeJwtToken(token)
//       const currentTime = Date.now() / 1000;
  
//       if (decodedToken && typeof decodedToken === 'object' && decodedToken.exp) {
//         const expirationTime = decodedToken.exp as number;
//         if (expirationTime > currentTime) {
//           return true;
//         }
//       }
//     } else {
//       return false;
//     }
//     return false;
//   }
// }

// function decodeJwtToken(token: string): { [key: string]: any } | null {
//   const parts = token.split('.');
  
//   if (parts.length !== 3) {
//     return null; // Invalid JWT token
//   }
  
//   const encodedPayload = parts[1];
//   const decodedPayload = atob(encodedPayload);
//   const jsonPayload = decodeURIComponent(Array.prototype.map.call(decodedPayload, (c: string) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
  
//   try {
//     const decodedToken = JSON.parse(jsonPayload);
//     return decodedToken;
//   } catch (error) {
//     return null; // Failed to parse the JSON payload
//   }
//   }
