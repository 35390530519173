import Grid from '@mui/material/Grid';
import { Button, Paper, Typography } from "@mui/material";
import './order_acks_comment.css'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { StyledTextarea } from '../../../customer/add_customer/basic_details/add_comment/styled_text_area';
import React from 'react';
import { getComment, saveComment } from './order_acks_comment_api';
import { useLocation } from 'react-router-dom';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
export default function OrderAcksComment(props:any) {
  const [commentError, setCommentError] = React.useState(false);
  const [commentSave, setCommentSave] = React.useState(false);
  const [comments,setComments]=React.useState()
  const textareaRef = React.useRef(null);
  const location = useLocation();
  const ALERT_DURATION = 2800;
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const textareaValue = (textareaRef.current as unknown as HTMLTextAreaElement)?.value || '';
      let activeStep=localStorage.getItem('activeStep')      
      let orderType;
      
      if (textareaValue) {
        const searchParams = new URLSearchParams(location.search);
        const id = searchParams.get("id");
        orderType = searchParams.get("status");
        setCommentError(false)
        //  calling for save comments api
        let response = await saveComment(textareaValue, id, orderType)
        if (response == 200) {
          (textareaRef.current as unknown as HTMLTextAreaElement).value = '';
          // window.location.reload();
          setCommentSave(true)
          setInterval(()=>{
          setCommentSave(false)
          },ALERT_DURATION)
        }
      } else {
        setCommentError(true)
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };
  const comment=(e:any)=>{
    setComments(e.target.value)
    if(comments!=undefined){     
      setCommentError(false)
    }
  }
  const [fetchComments,setFetchComments]=React.useState<any>({ type: '' })
  React.useEffect(()=>{
      const fetchdata= async()=>{
        try{
          let activeStep
          if(props.stage == undefined){
             activeStep=localStorage.getItem('activeStep')   
          }else{
             activeStep=props.stage
          }
            
          let orderType;
          const searchParams = new URLSearchParams(location.search);
        const id = searchParams.get("id");
        orderType = searchParams.get("status");
        let response= await getComment(id,orderType)
        setFetchComments(response)
      }
      catch (error) {
        console.error('Error occurred:', error);
    }
    }
  fetchdata()
  },[props.stage])
  const formatTime = (timeString:any) => {
    const time = new Date(`2000-01-01T${timeString}`);
    const formattedTime = time.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
    return formattedTime;
  };
  
  return (
    <div className='custom-comment-row'>
      <Paper className='custom-paper-OrderAcksComment'>
        <Grid container>
          <Grid item xs={12}>
            <h1 className='custom-basic-comments'>Add a comment</h1>
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit}>
          <Grid container px={2} className="custom-comment-grid">
            <Grid item xs={0}>
              <AccountCircleIcon />
            </Grid>
            <Grid item xs={11} pl={2}>
              <StyledTextarea onChange={comment} aria-label="minimum height" minRows={3} ref={textareaRef} />
              {commentError && <p className="custom-error-message">Comment is required.</p>}
            </Grid>
          </Grid>
          <Grid container pb={2}>
            <Grid item  pl={7}>
              <Button type='submit' className='custom-comment-send-btn'>Save</Button>
             {/* {commentSave && <FileDownloadDoneIcon style={{marginTop:'0px'}}/> } */}
            </Grid>
            <Grid item  >
              {/* <Button type='submit' className='custom-comment-send-btn'>Save</Button> */}
             {commentSave && <FileDownloadDoneIcon  style={{marginTop:'0px',color:'#00B929'}}/> }
            </Grid>
          </Grid>
        </form>
      </Paper>
      {/* <Grid container>
        <Grid item xs={12}>
        {fetchComments &&
        Array.isArray(fetchComments) &&
        fetchComments.map((item, index) => {
          return (
            <Timeline key={index} position="right" className="custom-orderComment-expansion" >
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot />
                  {index !== fetchComments.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <TimelineContent sx={{ pt: '0px', px: 2 }}>
                    <Typography component="span" style={{ display: '-webkit-inline-box' }} >
                      <span className="custom-orderComment-comments"> {item.commentInfo} &nbsp;<span className="custom-orderComment-time"> commented by &nbsp; <span className="custom-orderComment-comments">{item.createdBy} </span>&nbsp;on </span> &nbsp;  {item.date} {formatTime(item.time)}</span>
                    </Typography>
                  </TimelineContent>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          );
        })}
        </Grid>
      </Grid> */}
    </div>
  )
}
