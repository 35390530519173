import { Card, Container, Grid, Box, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Button, Link as MuiLink, SelectChangeEvent, Paper, FormHelperText, Alert, IconButton, InputAdornment } from '@mui/material';
import './register.css'
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import { ApiService } from '../../service/api.service';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { Icon } from '@iconify/react';
import Swal from 'sweetalert2';

const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    username: Yup.string().required("User Name is required").test("no-spaces", "Username cannot contain spaces", value => {
        return !/\s/.test(value); // Check if the username contains spaces
    }),
    // password: Yup.string().required("Password is required").matches(
    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d@"!"#$%&'()*+,-./:;<=>?[\]^_`{|}~"]{8,}$/,
    //     "Password must be 8+ characters with uppercase, lowercase, digit, and special character"
    // ),
    password: Yup.string().required("Password is required").matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/,
        "Password must be 8+ characters with uppercase, lowercase, digit, and special character"
    ),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    // user_type: Yup.string().required("User Type  is required"),
    secretKey: Yup.string().required("Secret Key  is required"),
});
export default function RegisterComponent() {
    const apiService = new ApiService();
    const navigate = useNavigate();
    const [alert, setAlert] = React.useState(false);
    const [responseMsg, setResponseMsg] = React.useState<string>();
    const ALERT_DURATION = 5000;
    const alertClose = () => {
        setAlert(false)
    }
    const initialValues = {
        first_name: "",
        last_name: "",
        username: "",
        password: "",
        email: "",
        user_type: "employee",
        secretKey: ""
    };
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (values: any, { setSubmitting }: FormikHelpers<any>) => {
        apiService.add('api/register/', values).then(response => {
            const status = response.status_code;
            if (status === 200) {
                Swal.fire({title:'Registered Successfully!',allowOutsideClick:false}).then(() => {
                    navigate('/login')
                });
            }
            setSubmitting(false);
        })
            .catch(function (error) {
                // handle the error
                console.error("errr", error);
                const status = error.response.data.status_code;
                let errors = error.response.data.message;
                if (status === 403 || status === 409) {
                    setResponseMsg(errors)
                    setAlert(true)
                    setTimeout(() => {
                        setAlert(false);
                    }, ALERT_DURATION);
                } else {
                    setResponseMsg("Network Error")
                    setAlert(true)
                    setTimeout(() => {
                        setAlert(false);
                    }, ALERT_DURATION);
                }
                setSubmitting(false);
            });
    };
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    return (
        <>
            <div className="form-container">
                <Container component="main" maxWidth="xs">
                    <Box
                        sx={{
                            marginTop: 2,
                            marginBottom: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Grid container>
                            <Grid item>
                                <h1 className='custom-register-heading'>Sign up</h1>
                            </Grid>
                        </Grid>
                        <Box sx={{ mt: 3 }}>
                            <Formik initialValues={initialValues} validateOnChange={true} validateOnBlur={true} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {({ errors, touched }) => (
                                    <Form>
                                        <Grid container spacing={2} className="custom-font">
                                            <Grid item xl={12} lg={6} md={6} sm={6} xs={12} className="custom-register-grid">
                                                <Field as={TextField} size='medium' className='custom-register-fields' fullWidth id="first_name" label="First Name" name="first_name" variant="outlined" sx={{ mb: 2 }}
                                                    // error={errors.first_name} helperText={touched.first_name && errors.first_name && <ErrorMessage name="first_name" />}
                                                    error={(touched.first_name && !!errors.first_name) || (submitted && !!errors.first_name)}
                                                    helperText={touched.first_name && (errors.first_name || (submitted && errors.first_name))}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Icon icon="mdi:user" color="#171c26" width="20" height="20" />
                                                            </InputAdornment>
                                                        )
                                                    }} />
                                            </Grid>
                                            <Grid item xl={12} lg={6} md={6} sm={6} xs={12} className="custom-register-grid">
                                                <Field as={TextField} size='medium' fullWidth id="last_name" label="Last Name" className='custom-register-fields' name="last_name" variant="outlined" sx={{ mb: 2 }}
                                                    // error={errors.last_name} helperText={touched.last_name && errors.last_name &&<ErrorMessage name="last_name" />}
                                                    error={(touched.last_name && !!errors.last_name) || (submitted && !!errors.last_name)}
                                                    helperText={touched.last_name && (errors.last_name || (submitted && errors.last_name))}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Icon icon="mdi:user" color="#171c26" width="20" height="20" />
                                                            </InputAdornment>
                                                        )
                                                    }} />
                                            </Grid>
                                            <Grid item xl={12} lg={12} md={12} sm={6} xs={12} className="custom-register-grid">
                                                <Field as={TextField} size='medium' fullWidth id="email" label="Email Address" className='custom-register-fields' name="email" autoComplete="email" variant="outlined" sx={{ mb: 2 }}
                                                    // error={errors.email} helperText={<ErrorMessage name="email" />}
                                                    error={(touched.email && !!errors.email) || (submitted && !!errors.email)}
                                                    helperText={touched.email && (errors.email || (submitted && errors.email))}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Icon icon="dashicons:email-alt" color="#171c26" width="20" height="20" />
                                                            </InputAdornment>
                                                        )
                                                    }} />
                                            </Grid>
                                            <Grid item xl={12} lg={12} md={12} sm={6} xs={12} className="custom-register-grid">
                                                <Field as={TextField} size='medium' fullWidth name="username" label="Username" className='custom-register-fields' id="username" sx={{ mb: 2 }}
                                                    // error={errors.username} helperText={<ErrorMessage name="username" />}
                                                    error={(touched.username && !!errors.username) || (submitted && !!errors.username)}
                                                    helperText={touched.username && (errors.username || (submitted && errors.username))}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Icon icon="mdi:user" color="#171c26" width="20" height="20" />
                                                            </InputAdornment>
                                                        )
                                                    }} />
                                            </Grid>
                                            <Grid item xs={12} className="custom-register-grid">
                                                <Field as={TextField} size='medium' fullWidth className='custom-Registerpassword-textfield'
                                                    label="Password" name="password" variant="outlined" sx={{ mb: 2 }}
                                                    //  error={errors.password} helperText={<ErrorMessage name="password" />}
                                                    error={(touched.password && !!errors.password) || (submitted && !!errors.password)}
                                                    helperText={touched.password && (errors.password || (submitted && errors.password))}
                                                    type={showPassword ? 'text' : 'password'}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Icon icon="mdi:password" color="#171c26" width="20" height="20" />
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="custom-register-grid">
                                                <Field as={TextField} size='medium' fullWidth name="secretKey" label="Secret Key" className='custom-register-fields' type="text" id="secretKey" autoComplete="secretKey" sx={{ mb: 2 }}
                                                    // error={errors.secretKey} helperText={<ErrorMessage name="secretKey" />}
                                                    error={(touched.secretKey && !!errors.secretKey) || (submitted && !!errors.secretKey)}
                                                    helperText={touched.secretKey && (errors.secretKey || (submitted && errors.secretKey))}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Icon icon="mingcute:key-2-fill" color="#171c26" width="20" height="20" />
                                                            </InputAdornment>
                                                        )
                                                    }} />
                                            </Grid>
                                            {/* <Grid item xs={12}>
                                                <FormControl fullWidth error={!!errors.user_type && touched.user_type}>
                                                    <InputLabel id="demo-simple-select-helper-label">Type</InputLabel>
                                                    <Field as={Select} id="user_type" label="User Type" className='custom-register-fields' name="user_type" >
                                                        <MenuItem value="employee">Caracal</MenuItem>
                                                        <MenuItem value="customer">Customer</MenuItem>
                                                    </Field>
                                                    {errors.user_type && touched.user_type && (
                                                        <FormHelperText error className="error-message">
                                                            {errors.user_type}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid> */}
                                            {/* <Grid item xs={12}>
                                                <FormControlLabel control={<Checkbox value="allowExtraEmails" color="primary" />} label="Accept all Terms and Conditions" />
                                            </Grid> */}
                                        </Grid >
                                        {(alert) ? <Alert onClose={alertClose} variant="filled" severity="error">{responseMsg}</Alert> : ''}
                                        <Grid container justifyContent={'center'}>
                                            <Grid item lg={4} md={4} sm={4} xs={5} pt={1}>
                                                <Button type="submit" fullWidth className='custom-register-button'> Sign up </Button>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Box>
                </Container>
            </div>
        </>
    )
}