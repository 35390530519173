import { Alert, Backdrop, Button, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Modal, Paper, Select, TextField } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import './add_item.css'
import { createItem, getItemSupplier, getItemType, getItemUnit, getItemById, updateItem } from './add_item';
import React from "react";
import { useLocation } from "react-router-dom";
import { useRef } from "react";
import AddItemsModal from "./add_item_modal.component";
import { Icon } from '@iconify/react';
import LinearProgress from '@mui/material/LinearProgress';
const validationSchema = Yup.object().shape({
    itemName: Yup.string().matches(/^[a-zA-Z0-9_]+$/,  'Invalid Special characters').required("Item Name is required"),
    commodityCode: Yup.string().required("Commodity Code is required").matches(/^[0-9]+$/, "Please enter numbers only "),
    description: Yup.string().required("Item Description is required"),
    itemTypeId: Yup.string().required("Item Type is required"),
    supplier: Yup.string().required("Supplier is required"),
    uomId: Yup.string().required("Unit is required"),
    unitPrice: Yup.number().required("Unit Price is required") 
    .typeError('Unit Price must be a number')
    .test(
      'is-decimal',
      'Unit Price should  not exceed 6 digits',
      value => /^\d{1,6}(?:\.\d{1,2})?$/.test(value.toString())
    )
    ,
    SKU: Yup.string().matches(/^[a-zA-Z0-9_]+$/,  'Invalid Special characters').required("SKU is required"),
});

interface ItemData {
    itemName: any;
    itemId: any;
    commodityCode: any;
    description: any;
    itemTypeId: any;
    supplier: any;
    uomId: any;
    unitPrice: any;
    SKU: any;
}

export default function AddItem() {
    const [itemTypeList, setItemTypeList] = React.useState<[]>();
    const [itemUnitList, setItemUnitList] = React.useState<[]>();
    const [itemSupplierList, setItemSupplierList] = React.useState<[]>();
    const [alert, setAlert] = React.useState<string>();
    const [responseMsg, setResponseMsg] = React.useState();
    const [type, setType] = React.useState<any>();
    const [itemId, setItemId] = React.useState<any>();
    const [itemName, setItemName] = React.useState<any>();
    const [openUnit, setOpenUnit] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const handleOpenUnit = () => setOpenUnit(true);
    const handleCloseUnit = () => setOpenUnit(false);
    const formikRef: any = useRef();
    const [itemIdValue, setItemIdValue] = React.useState("");

    const initialValues = {
        itemName: "",
        itemId: null,
        commodityCode: "",
        description: "",
        itemTypeId: "",
        supplier: "",
        uomId: "",
        unitPrice: "",
        SKU: "",
    };

    const location = useLocation();
    const ALERT_DURATION = 1500;
    const alertClose = () => {
        setAlert('')
    }

    const handleCloseUnitModal = () => {
        setOpenUnit(false);
    }

    const handleFormReset = (values: any) => {
        values = {};
    };

    React.useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const type = searchParams.get("type");
        const itemId = searchParams.get("itemId");
        const itemName = searchParams.get("itemName");
        setType(type as any)
        setItemId(itemId as any)
        setItemName(itemName as any)
        if(type == 'update'){
            setSpinner(true)
        }
        const fetchData = async () => {
            try {
                let itemType = await getItemType();
                setItemTypeList(itemType)
                let itemUnit = await getItemUnit();
                setItemUnitList(itemUnit)
                let itemSupplier = await getItemSupplier();
                setItemSupplierList(itemSupplier)
                if (type == 'update') {
                    // setSpinner(true)
                    let itemData = await getItemById(itemId, 'item') as unknown as ItemData
                    if (itemData) {
                        // setSpinner(false);
                        setItemIdValue(itemData.itemId);
                        formikRef.current?.setFieldValue("itemName", itemData.itemName, false);
                        formikRef.current?.setFieldValue("itemId", itemData.itemId, false);
                        formikRef.current?.setFieldValue("commodityCode", itemData.commodityCode, false);
                        formikRef.current?.setFieldValue("description", itemData.description, false);
                        formikRef.current?.setFieldValue("itemTypeId", itemData.itemTypeId, false);
                        formikRef.current?.setFieldValue("supplier", itemData.supplier, false);
                        formikRef.current?.setFieldValue("uomId", itemData.uomId,false);
                        formikRef.current?.setFieldValue("unitPrice", itemData.unitPrice, false);
                        formikRef.current?.setFieldValue("SKU", itemData.SKU, false); 
                    }
                    setSpinner(false);
                }
            } catch (error) {
                console.error('Error occurred:', error);
            }
        };
        fetchData();
    }, [itemId]);

    const handleUnit = async () => {
        let itemUnit = await getItemUnit();
        setItemUnitList(itemUnit)
    }

    const handleSubmit = async (values: any) => {
        try {
            if (type == 'update') {
                let response = await updateItem(values.itemId, values);
                setResponseMsg(response)
                setAlert('true')
                // setTimeout(() => {
                //     setAlert('');
                // }, ALERT_DURATION);
            } else {
                await createItem(values).then((response: any) => {
                    console.log(response)
                    if (response.status == 200) {
                        setResponseMsg(response.message)
                        setAlert('true')
                        setTimeout(() => {
                            setAlert('');
                            window.location.reload();
                        }, ALERT_DURATION);
                    } else if (response.status == 409) {
                        setResponseMsg(response.message)
                        setAlert('false')
                        setTimeout(() => {
                            setAlert('');
                        }, ALERT_DURATION);
                    } else {
                        setResponseMsg(response.message)
                        setAlert('false')
                        setTimeout(() => {
                            setAlert('');
                        }, ALERT_DURATION);
                    }
                });
            }
        } catch (error: any) {
            console.error('Error occurred:', error);
        }
    };

    return (
        <>
              {(spinner)?<LinearProgress />:''}

                <div className="custom-addItem-details">
                    <Paper className="custom-addItem-paper">
                        {(alert == 'true') ? <Alert onClose={alertClose}>{responseMsg}</Alert> : (alert == 'false') ? <Alert onClose={alertClose} severity='error'>{responseMsg}</Alert> : ''}
                        <Grid container pt={1}>
                            <Grid item md={4} xs={6} sm={6} >
                                {(type == 'update') ? <h1 className="custom-addItem-heading">{itemName}</h1> : <h1 className="custom-addItem-heading">New Item</h1>}
                            </Grid>
                            <Grid item md={8} xs={6} sm={6} textAlign={'end'}>
                                <a href='/item'><Button type="button" className="custom-addItem-send-btn"> Back </Button></a>
                            </Grid>
                        </Grid>
                        <Formik innerRef={formikRef} validateOnChange={true} validateOnBlur={false} initialValues={initialValues} enableReinitialize validationSchema={validationSchema} onSubmit={handleSubmit} onReset={handleFormReset}>
                            {({ errors, touched }) => (
                                <Form style={{ paddingLeft: '18px' }}>
                                    <Grid container className="custom-addItem-container">
                                        <Grid item xs={12} sm={4} lg={4} md={4} pt={2}>
                                            {(type == 'update') ? <Field fullWidth as={TextField} id="itemName" name="itemName" label="Item Name" variant="outlined" className="custom-addItem-text-field" error={errors.itemName && touched.itemName} helperText={<ErrorMessage name="itemName" />} disabled />
                                                :
                                                <Field fullWidth as={TextField} id="itemName" name="itemName" label="Item Name" variant="outlined" className="custom-addItem-text-field" error={errors.itemName && touched.itemName} helperText={<ErrorMessage name="itemName" />} />
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={4} md={4} pt={2}>
                                            <Field fullWidth as={TextField} label="Item Id" value={itemIdValue} id="itemId" name="itemId" variant="outlined" className="custom-addItem-text-field"  disabled />
                                        </Grid>
                                    </Grid>
                                    <Grid container className="custom-addItem-container">
                                        <Grid item xs={12} md={4} sm={4} pt={2}>
                                            {(type == 'update') ? <Field fullWidth as={TextField} label="SKU" id="sku" name="SKU" variant="outlined" className="custom-addItem-text-field" error={errors.SKU && touched.SKU} helperText={<ErrorMessage name="SKU" />} disabled />
                                                :
                                                <Field fullWidth as={TextField} label="SKU" id="sku" name="SKU" variant="outlined" className="custom-addItem-text-field" error={errors.SKU && touched.SKU} helperText={<ErrorMessage name="SKU" />} />
                                            }
                                        </Grid>
                                        <Grid item xs={12} md={4} sm={4} pt={2}>
                                            {/* <FormControl className="custom-textarea" fullWidth error={!!errors.description && touched.description}>
                                                <InputLabel className='custom-customer-addItem-select-label' id="description">Item Description</InputLabel>
                                                <Field fullWidth as={ItemTextarea} id="description" name="description" label="Item Description" variant="outlined" className="custom-addItem-text-field" />
                                                {errors.description && touched.description && (
                                                    <FormHelperText error className="error-message">
                                                        {errors.description}
                                                    </FormHelperText>
                                                )}
                                            </FormControl> */}
                                            <Field fullWidth as={TextField} id="description" name="description" label="Item Description" variant="outlined" className="custom-addItem-text-field" error={errors.description && touched.description} helperText={<ErrorMessage name="description" />} />
                                        </Grid>
                                    </Grid>
                                    <Grid container className="custom-addItem-container">
                                        <Grid item md={4} sm={4} xs={12} pt={2}>
                                            <FormControl fullWidth error={!!errors.itemTypeId && touched.itemTypeId}>
                                                <InputLabel className='custom-customer-addItem-select-label' id="itemTypeId">Item Type</InputLabel>
                                                <Field fullWidth as={Select} className="custom-customer-addItem-select-field" id="itemTypeId" name="itemTypeId" labelId="item-type" label="Item Type" variant="outlined">
                                                    {Array.isArray(itemTypeList) && itemTypeList.map((itemType: any) => (
                                                        <MenuItem key={itemType.id} value={itemType.id}>{itemType.itemTypeName}</MenuItem>
                                                    ))}
                                                </Field>
                                                {errors.itemTypeId && touched.itemTypeId && (
                                                    <FormHelperText error className="error-message">
                                                        {errors.itemTypeId}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={4} sm={4} xs={12} pt={2}>
                                            <FormControl fullWidth error={!!errors.supplier && touched.supplier}>
                                                <InputLabel className='custom-customer-addItem-select-label' id="supplier">Supplier</InputLabel>
                                                <Field fullWidth as={Select} className="custom-customer-addItem-select-field" id="supplier" name="supplier"  labelId="supplier" label="supplier" variant="outlined">
                                                    {Array.isArray(itemSupplierList) && itemSupplierList.map((itemSupplier: any) => (
                                                        <MenuItem key={itemSupplier.id} value={itemSupplier.id}>{itemSupplier.supplierCompanyName}</MenuItem>
                                                    ))}
                                                </Field>
                                                {errors.supplier && touched.supplier && (
                                                    <FormHelperText error className="error-message">
                                                        {errors.supplier}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container className="custom-addItem-container">
                                        <Grid item md={4} sm={4} xs={12} pt={2}>
                                            <Field fullWidth as={TextField} type="number" id="commodityCode" name="commodityCode" label="Commodity Code" variant="outlined" className="custom-addItem-text-field" error={errors.commodityCode && touched.commodityCode} helperText={<ErrorMessage name="commodityCode" />} />
                                        </Grid>
                                        <Grid item md={4} sm={4} xs={12} pt={2}>
                                            <Field fullWidth as={TextField}  type="number"  id="unitPrice" name="unitPrice" label="Unit Price" variant="outlined" className="custom-addItem-text-field" error={errors.unitPrice && touched.unitPrice} helperText={<ErrorMessage name="unitPrice" />} />
                                        </Grid>
                                    </Grid>
                                    <Grid container className="custom-addItem-container">
                                        <Grid item md={4} sm={4} xs={12} pt={2}>
                                            <Grid container >
                                                <Grid item md={10} xs={10} textAlign={'end'}>
                                                    <FormControl fullWidth error={!!errors.uomId && touched.uomId}>
                                                        <InputLabel className='custom-customer-addItem-select-label' id="unit">Unit</InputLabel>
                                                        <Field fullWidth as={Select} className="custom-customer-addItem-select-field-1" id="uomId" name="uomId" labelId="Unit" label="Unit" variant="outlined">
                                                            {Array.isArray(itemUnitList) && itemUnitList.map((itemUnit: any) => (
                                                                <MenuItem key={itemUnit.id} value={itemUnit.id}>{itemUnit.itemUnit}</MenuItem>
                                                            ))}
                                                        </Field>
                                                        {errors.uomId && touched.uomId && (
                                                            <FormHelperText error className="error-message">
                                                                {errors.uomId}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item md={1} xs={1} pl={1} pt={1} textAlign={'end'}>
                                                    <Icon icon="material-symbols:add-circle-rounded" style={{cursor:'pointer'}} width="25" height="25" onClick={handleOpenUnit} />
                                                </Grid>
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                    <Grid container pt={2}>
                                        <Grid item xs={5} md={1.5} sm={2} lg={1} xl={0.8} textAlign={"start"}>
                                            <Button type="submit" className="custom-addItem-send-btn">
                                                Save
                                            </Button>
                                        </Grid>
                                        <Grid item xs={5} md={1} sm={2} lg={1} xl={1} textAlign={"start"}>
                                            <Button type="reset" className="custom-addItem-delete-btn">Reset</Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Paper>

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                        open={openUnit} onClose={handleCloseUnit}>
                        <div>
                            <AddItemsModal handleCloseUnitModal={handleCloseUnitModal} handleUnit={handleUnit} />
                        </div>
                    </Modal>

                </div>
        </>
    )
}
