import * as React from 'react';
import { Button, TextField, Box, Grid, Paper, Typography, InputAdornment, FormControl, InputLabel, Select, MenuItem, Alert } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import IconButton from '@mui/material/IconButton';
import { createOrder, getSupplierName } from './new_order_api';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import './new_order.css'
import { Margin } from '@mui/icons-material';

interface SupplierData {
  id: number;
  supplierName: string;
}

interface ApiResponse {
  status: number;
  message: string;
}

export default function NewOrder(props: any) {
  const [file, setFile] = React.useState("");
  const [supplierList, setSupplierList] = React.useState<SupplierData[]>();
  const [supplierName, setsupplierName] = React.useState("")
  const [fileName, setFileName] = React.useState<any>("");
  const [alert, setAlert] = React.useState<string>();
  const [responseMsg, setResponseMsg] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [commentError, setCommentError] = React.useState(false);
  const ALERT_DURATION = 2500;

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        // calling Get Api for Supplier Name
        let supplierName = await getSupplierName();
        setSupplierList(supplierName)
      } catch (error) {
        console.error('Error occurred:', error);
      }
    };
    fetchData();
  }, []);

  const handleFileChange = (event: any) => {
    let file = event.target.files[0]
    if (file) {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = () => {
        const fileData = reader.result as string;
        setFileName(file.name)
        setFile(fileData);
        setCommentError(false)
      }
    }
  };

  function handleSupplierChange(id: any) {
    const selectedSupplierId = id;
    if (supplierList !== undefined) {
      const filteredData = supplierList.filter(supplier => supplier.id === selectedSupplierId);
      setsupplierName(filteredData[0].supplierName)
    }
  }
  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      if (!file) {
        setCommentError(true)

      } else {
        setLoading(true)
        let supplier;

        if (supplierName == "") {
          if (supplierList !== undefined) {
            const filteredData = supplierList.filter(supplier => supplier.id === 1);
            supplier = filteredData[0].supplierName
            setsupplierName(filteredData[0].supplierName)
          }
        } else {
          supplier = supplierName
        }
        let response: ApiResponse | undefined = await createOrder(file, supplier);
        if (response && response['status'] === 400) {
          setLoading(false)
          setAlert('false');
          setResponseMsg(response['message']);
          setTimeout(() => {
            // setAlert('');
            setFileName("")
            setFile("")
          }, ALERT_DURATION);
        } else if (response && response['status'] === 200) {
          setLoading(false)
          setAlert('true');
          setResponseMsg(response['message']);
          setTimeout(() => {
            setAlert('');
            window.location.reload();
          }, ALERT_DURATION);
        } else {
          setLoading(false)
          setAlert('false');
          setResponseMsg('Some Error Occured in cXML');
          setTimeout(() => {
            // setAlert('');
            setFileName("")
            setFile("")
            // window.location.reload();
          }, ALERT_DURATION);
        }
      }

    } catch (error: any) {

    }
  };

  const handleCancel = () => {
    setFileName("")
    setFile("")
    props.handleClose(false)
  }

  const alertClose = () => {
    setAlert('')
    // window.location.reload();
  }

  return (
    <Box className='custom-order-modal'>
      <Paper className='model-paper'>
        {(alert == 'true') ? <Alert severity='success' onClose={alertClose}>{responseMsg}</Alert> : (alert == 'false') ? <Alert onClose={alertClose} severity='error'>{responseMsg}</Alert> : ''}
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <Typography pb={3} className='model-tilte'>Upload XML File</Typography>
              <Grid container >
                <Grid item xs={6}>
                  <TextField label="File Upload" variant="outlined" size='small' className="custom-file-upload" disabled={true} value={fileName} fullWidth InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton color="primary" aria-label="upload picture" component="label">
                          <input hidden type="file" accept="text/xml" onChange={handleFileChange} key={file} />
                          <AttachFileIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}>
                  </TextField>
                  {commentError && <p className="custom-error-message">File is required.</p>}
                </Grid>
                <Grid item xs={6} pl={2}>
                  <FormControl fullWidth sx={{ minWidth: 120, fontSize: '12px' }} size='small'>
                    <InputLabel id="supplierName">Supplier Name</InputLabel>
                    <Select labelId="supplierName" id="supplierName" label="Supplier Name" className='custom-Supplier-field' defaultValue={1} onChange={(event) => handleSupplierChange(event.target.value)}>
                      {Array.isArray(supplierList) && supplierList.map((supplier: any) => (
                        <MenuItem key={supplier.id} value={supplier.id}>{supplier.supplierName}</MenuItem>
                      ))}
                      {/* <MenuItem value='Taylor'>Taylor</MenuItem> */}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt={2} justifyContent={'center'} columnGap={'1rem'}>
            {(loading) ?
              <Grid item lg={3} md={4} sm={5} xs={6} textAlign={'end'}>
                <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} style={{ color: 'white', backgroundColor: '#1976d2' }} variant="contained" > Loading </LoadingButton>
              </Grid>
              :
              <Grid item lg={3} md={4} sm={5} xs={6} textAlign={'end'}>
                <Button type="submit" variant="contained" color="primary"> Upload </Button>
              </Grid>
            }
            <Grid item lg={3} md={4} sm={5} xs={6} textAlign={'start'}>
              <Button variant="contained" color="error" onClick={handleCancel}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>

  )
}
