import axios, { AxiosResponse } from 'axios';
import { setAuthToken } from './auth.utils';
import axiosInstance from './axios_instance';
import Swal from 'sweetalert2';

const apiUrl = process.env.REACT_APP_API_URL;

setAuthToken()

export class ApiService {
  axios: any;

  public async getAll(url: string) {
    const response: AxiosResponse<any[]> = await axiosInstance.get(apiUrl+url);
    return response.data;
  }

  public async add(url: string, data: any){
    const response: AxiosResponse<any> = await axiosInstance.post(apiUrl+url, data);
    return response.data;
  }

  public async update(url: string, data: any){
    const response: AxiosResponse<any> = await axiosInstance.put(apiUrl+url, data);
    return response.data;
  }

  public async delete(url: string){
    const response: AxiosResponse<any> = await axiosInstance.delete(apiUrl+url);
    return response.data;
  }

  public tokenExpired(){
    Swal.fire({
      title: 'Session Expired',
      allowOutsideClick: false
    }).then(() => {
      localStorage.clear(); 
      window.location.reload(); 
    });
  }

}