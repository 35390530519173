import { Paper } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import './order_line_table.css'
import React from "react";
 
  export  function LineItemsTable(props:any) {
    const[lineItems, setLineItems] = React.useState<any[]>([]);
    React.useEffect(() => {
        if(props.lineItems){
          setLineItems(props.lineItems)
        }
      },[props.lineItems])
    return (
      <TableContainer component={Paper} className='custom-shipping-table-container'>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
            <TableCell className='custom-LineItems-table-header' align="center">#</TableCell>
              <TableCell className='custom-LineItems-table-header' align="center">Line No</TableCell>
              <TableCell className='custom-LineItems-table-header' align="center">Sku</TableCell>
              <TableCell className='custom-LineItems-table-header' align="center">Item Name</TableCell>
              <TableCell className='custom-LineItems-table-header' align="center">Commodity Code</TableCell>
              <TableCell className='custom-LineItems-table-header' align="center">Qty/Uom</TableCell>
              <TableCell className='custom-LineItems-table-header' align="center">Unit Price</TableCell>
              <TableCell className='custom-LineItems-table-header' align="center">Type</TableCell>
              <TableCell className='custom-LineItems-table-header' align="center">Sub Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {lineItems.map((item: any, index: number) => (
             <TableRow  key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
             <TableCell className='custom-LineItems-table-fields' align="center">{index+1}</TableCell>
             <TableCell className='custom-LineItems-table-fields' align="center">{item.lineNumber}</TableCell>
             <TableCell className='custom-LineItems-table-fields' align="center">{item.sku}</TableCell>
             <TableCell className='custom-LineItems-table-fields' align="center">{item.itemName}</TableCell>
             <TableCell className='custom-LineItems-table-fields' align="center">{item.commodityCode}</TableCell>
             <TableCell className='custom-LineItems-table-fields' align="center">{item.quantity}</TableCell>
             <TableCell className='custom-LineItems-table-fields' align="center">{item.unitPrice}</TableCell>
             <TableCell className='custom-LineItems-table-fields' align="center">Material</TableCell>
             <TableCell className='custom-LineItems-table-fields' align="center">{item.subtotalAmount}</TableCell>
           </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }