import axios, { AxiosResponse } from "axios";
import { ApiService } from "../../../../service/api.service";
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import axiosInstance from "../../../../service/axios_instance";


const apiService = new ApiService();
const apiUrl = process.env.REACT_APP_API_URL;

let customerName: any;
 //  Get Api for sales order details 
export const getSalesOrderDetails = async (id: any, stage: any, statusType: any) => {
  let salesOrderDetails;
  let url;
  if (statusType == 'New') {
    url = 'orders/invoiceCxmlPayload/'
    await apiService.getAll(url + id + '/').then((response: any) => {
      if (response.status_code == 200) {
        let result = response.data.cXML
        let orderDetails = response.data.SalesInvoice;
        let status = orderDetails['RoutingStatus']
        let activeStep: any;
        if (status == 'New') {
          activeStep = 0
        } else if (status == 'Ack & Tax Calculated' || status == 'Sent to Ariba' ||  status == 'Sent' ||  status == 'PayloadID Exist' || status == 'Processing')  {
          activeStep = 1
        } else if (status == 'shipped'|| status == 'Sent to Ariba' ||  status == 'Sent') {
          activeStep = 2
        } else if (status == 'Resubmitted' || status == 'Rejected') {
          activeStep = 3
        } else if (status == 'Invoiced' || status == 'Approved') {
          activeStep = 4
        }
        if (stage != undefined) {
          localStorage.setItem('activeStep', stage)
        } else {
          localStorage.setItem('activeStep', activeStep)
        }
        // localStorage.setItem('stage', activeStep)
        let lineItem = result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem
        let totalAmount = 0;
        let lineItemDetails = [];
        for (let i = 0; i < lineItem.length; i++) {
          let details = {
            "lineNumber": Number(lineItem[i]['@invoiceLineNumber']),
            "sku": response.data.CaracaItem[i].sku,
            "commodityCode": response.data.CaracaItem[i].commodityCode,
            "itemName": lineItem[i].InvoiceDetailItemReference.ItemID.SupplierPartID,
            "quantity": lineItem[i]['@quantity'] + '/' + lineItem[i]['UnitOfMeasure'],
            "unitPrice": '$ ' + lineItem[i].UnitPrice.Money['#text'] + ' ' + lineItem[i].UnitPrice.Money['@currency'],
            "subtotalAmount": '$ ' + lineItem[i].SubtotalAmount.Money['#text'] + ' ' + lineItem[i].SubtotalAmount.Money['@currency']
          }
          lineItemDetails.push(details);
          let subAmount = parseFloat(Number(lineItem[i].SubtotalAmount.Money['#text']).toFixed(2))
          totalAmount = totalAmount + subAmount
        }
        customerName = orderDetails.CustomerName
        localStorage.setItem('customerName', customerName)

        let shipToName = result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].Name['#text'] 
        let shipToNameAdd;
        if(shipToName == undefined){
          shipToNameAdd = "No Name"
        }else{
          shipToNameAdd = result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].Name['#text'] 
        }

        let orderDate = orderDetails.ProcessedTime
        const orderDateTime = new Date(orderDate);
        const formattedOrderDateTime = format(new Date(orderDateTime), 'EEEE dd MMMM yyyy');
        console.log()

        salesOrderDetails = {
          "orderDetails": {
            "orderId": orderDetails.OrderId,
            "TaylorOrderId": orderDetails.TaylorOrderId,
            "TaylorOldInvoiceId": orderDetails.TaylorOldInvoiceId,            
            "orderDate": formattedOrderDateTime,
            "customerName": orderDetails.CustomerName,
            "purchaseOrder": orderDetails.RoutingStatus,
            "payloadId":result['@payloadID'],
            "Comments":orderDetails.Comments
          },
          "shipping": {
            "shippingFrom": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[0].Name['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[0].PostalAddress['City'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[0].PostalAddress['Street'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[0].PostalAddress.Country['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[0].PostalAddress.PostalCode,
            "shippingTo": shipToNameAdd + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].PostalAddress['City'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].PostalAddress['Street'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].PostalAddress.Country['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].PostalAddress.PostalCode,
            "billingTo": result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[2].Contact.Name['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[2].Contact.PostalAddress['City'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[2].Contact.PostalAddress.Street[0] + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[2].Contact.PostalAddress.Street[1] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[2].Contact.PostalAddress.Country['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[2].Contact.PostalAddress.PostalCode,
            "remitTo": result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[3].Contact.Name['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[3].Contact.PostalAddress['City'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[3].Contact.PostalAddress.Street[0] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[3].Contact.PostalAddress.Country['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[3].Contact.PostalAddress.PostalCode
          },
          "extrinsic": {
            "catalog": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].Extrinsic['#text'],
            "costCenter" : result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].Distribution.Accounting.AccountingSegment['@id']
          },
          "lineItems": {
            "itemDetails": lineItemDetails,
            "totalAmount": '$ ' + totalAmount + ' ' + lineItem[0].SubtotalAmount.Money['@currency']
          },
          "taxSummary": {
            "amountDetails": {
              "subTotalAmount": '$ ' + totalAmount + ' ' + lineItem[0].SubtotalAmount.Money['@currency'],
              "grossAmount": '$ ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.GrossAmount.Money['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.GrossAmount.Money['@currency'],
              "netAmount": '$ ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.NetAmount.Money['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.NetAmount.Money['@currency'],
              "dueAmount": '$ ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.DueAmount.Money['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.DueAmount.Money['@currency']
            },
            "taxDetails": {
              "category": result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.TaxDetail['@category'],
              "details": result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.Description['#text'],
              "baseAmt": '$ ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.TaxDetail.TaxAmount.Money['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.TaxDetail.TaxAmount.Money['@currency'],
              "rate": result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.TaxDetail['@percentageRate'] + ' %'
            }
          }
  
        }
      }
  
  
    })
      .catch(error => {
        console.error('Error occurred:', error);
        if(error.response.status == 401){
          //apiService.tokenExpired();
      }
      if(error.response.status == 500){
        Swal.fire({title:'Error',text:"Internal Server Error",allowOutsideClick:false});
    }
      });
  
    return salesOrderDetails;
  } else {
    let extrinsicDetails;
    if(stage == undefined || stage !=0){
      url = 'orders/getUpdatedInvoiceCxmlPayload/'
    }else{
      url = 'orders/getOldInvoiceCxmlPayload/'
    }    
    await apiService.getAll(url + id + '/').then((response: any) => {
      if (response.status_code == 200) {
        let result = response.data.cXML
        let orderDetails = response.data.SalesInvoice;
        let status = orderDetails['RoutingStatus']
        let activeStep: any;
        if (status == 'New') {
          activeStep = 0
        } else if (status == 'Ack & Tax Calculated' || status == 'Sent to Ariba' ||  status == 'Sent' ||  status == 'PayloadID Exist' || status == 'Processing') {
          activeStep = 1
        } else if (status == 'shipped' || status == 'Sent to Ariba' ||  status == 'Sent') {
          activeStep = 2
        } else if (status == 'Resubmitted' || status == 'Rejected') {
          activeStep = 3
        } else if (status == 'Invoiced' || status == 'Approved') {
          activeStep = 4
        }

        if(stage == undefined){
          localStorage.setItem('activeStep', activeStep)
          let legalEntity = result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].Extrinsic[1]['#text']
          let catalogCode = legalEntity.substring(1)
          extrinsicDetails = {
            "catalog" : catalogCode,
            "businessUnit": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].Extrinsic[0]['#text'],
            "legalEntity": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].Extrinsic[1]['#text'],
            "costCenter" : result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].Distribution.Accounting.AccountingSegment['@id']
          }
        }else{
          localStorage.setItem('activeStep', stage)
          if(stage==0){
            extrinsicDetails = {
              "catalog": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].Extrinsic['#text'],
              "costCenter" : result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].Distribution.Accounting.AccountingSegment['@id']
            }
          
          }else{
            let legalEntity = result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].Extrinsic[1]['#text']
            let catalogCode = legalEntity.substring(1)
            extrinsicDetails = {
              "catalog" : catalogCode,
              "businessUnit": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].Extrinsic[0]['#text'],
              "legalEntity": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].Extrinsic[1]['#text'],
              "costCenter" : result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].Distribution.Accounting.AccountingSegment['@id']
            }
          }
        }
        
        localStorage.setItem('stage', activeStep)
        let lineItem = result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem
        let totalAmount = 0;
        let lineItemDetails = [];
        
        for (let i = 0; i < lineItem.length; i++) {
          let details = {
            "lineNumber": Number(lineItem[i]['@invoiceLineNumber']),
            "sku": response.data.CaracalItems[i].sku,
            "commodityCode": response.data.CaracalItems[i].commodityCode,
            "itemName": lineItem[i].InvoiceDetailItemReference.ItemID.SupplierPartID,
            "quantity": lineItem[i]['@quantity'] + '/' + lineItem[i]['UnitOfMeasure'],
            "unitPrice": '$ ' + lineItem[i].UnitPrice.Money['#text'] + ' ' + lineItem[i].UnitPrice.Money['@currency'],
            "subtotalAmount": '$ ' + lineItem[i].SubtotalAmount.Money['#text'] + ' ' + lineItem[i].SubtotalAmount.Money['@currency']
          }
          lineItemDetails.push(details);
          let subAmount = parseFloat(Number(lineItem[i].SubtotalAmount.Money['#text']).toFixed(2))
          totalAmount = totalAmount + subAmount
        }

        let invoiceDate = result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader['@invoiceDate']
        
        const datetime = new Date(invoiceDate);
        const formattedDate = datetime.toISOString().split("T")[0];
        const date = new Date(formattedDate + "T00:00:00");
        const formattedInvoiceDateTime = date.toLocaleString('en-US', {weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'}).replace(/,/g, '');
        // const options: Intl.DateTimeFormatOptions = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'};
        // const formattedInvoiceDateTime = datetime.toLocaleString('en-US', options);
        // const formattedInvoiceDateTime = format(new Date(formattedDate), 'EEEE dd MMMM yyyy');

        let orderDate = orderDetails.ProcessedTime
        const orderDateTime = new Date(orderDate);
        const formattedOrderDateTime = format(new Date(orderDateTime), 'EEEE dd MMMM yyyy');

        customerName = orderDetails.CustomerName
        localStorage.setItem('customerName', customerName)

        let shipToName = result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].Name['#text'] 
        let shipToNameAdd;
        if(shipToName == undefined){
          shipToNameAdd = "No Name"
        }else{
          shipToNameAdd = result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].Name['#text'] 
        }
        salesOrderDetails = {
          "orderDetails": {
            "orderId": orderDetails.OrderId,
            "TaylorOrderId": orderDetails.TaylorOrderId,
            "TaylorOldInvoiceId": orderDetails.TaylorOldInvoiceId,            
            "orderDate": formattedOrderDateTime,
            "customerName": orderDetails.CustomerName,
            "purchaseOrder": orderDetails.RoutingStatus,
            "invoiceId" :  result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader['@invoiceID'],
            "invoiceDate" :  formattedInvoiceDateTime,
            "dueAmount" : '$ ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.DueAmount.Money['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.DueAmount.Money['@currency'],
            "payloadId":result['@payloadID'],
            "Comments":orderDetails.Comments
          },
          "shipping": {
            "shippingFrom":result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[0].Name['#text']+ ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[0].PostalAddress['City'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[0].PostalAddress['Street'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[0].PostalAddress.Country['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[0].PostalAddress.PostalCode,
            "shippingTo": shipToNameAdd +' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].PostalAddress['City'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].PostalAddress['Street'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].PostalAddress.Country['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].PostalAddress.PostalCode,
            "billingTo": result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[1].Contact.Name['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[1].Contact.PostalAddress['City'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[1].Contact.PostalAddress.Street[0] + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[1].Contact.PostalAddress.Street[1] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[1].Contact.PostalAddress.Country['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[1].Contact.PostalAddress.PostalCode,
            "remitTo": result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[0].Contact.Name['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[0].Contact.PostalAddress['City'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[0].Contact.PostalAddress.Street[0] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[0].Contact.PostalAddress.Country['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[0].Contact.PostalAddress.PostalCode
          },
          "extrinsic": extrinsicDetails,
          "lineItems": {
            "itemDetails": lineItemDetails,
            "totalAmount": '$ ' + totalAmount.toFixed(2) + ' ' + lineItem[0].SubtotalAmount.Money['@currency']
          },
          "taxSummary": {
            "amountDetails": {
              "subTotalAmount": '$ ' + totalAmount + ' ' + lineItem[0].SubtotalAmount.Money['@currency'],
              "grossAmount": '$ ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.GrossAmount.Money['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.GrossAmount.Money['@currency'],
              "netAmount": '$ ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.NetAmount.Money['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.NetAmount.Money['@currency'],
              "dueAmount": '$ ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.DueAmount.Money['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.DueAmount.Money['@currency']
            },
            "taxDetails": {
              "category": result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.TaxDetail['@category'],
              "details": result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.Description['#text'],
              "baseAmt": '$ ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.TaxDetail.TaxAmount.Money['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.TaxDetail.TaxAmount.Money['@currency'],
              "rate": result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.TaxDetail['@percentageRate'] + ' %'
            }
          }
  
        }
      }
  
    })
      .catch(error => {
        console.error('Error occurred:', error);
        if(error.response.status == 401){
          //apiService.tokenExpired();
      }
        if(error.response.status == 400){
          Swal.fire({title:'Error',text:error.response.data.message,allowOutsideClick:false}).then(() => {window.location.href='/fulfillment';});
      }
      if(error.response.status == 500){
        Swal.fire({title:'Error',text:"Internal Server Error",allowOutsideClick:false});
    }
      });
    return salesOrderDetails;
  }
}
// Api for order confrm 
export const orderConfirm = async (data: any) => {
  let result;
  await apiService.add(`orders/autoProcessOff/?customerName=${localStorage.getItem('customerName')}&orderId=${data}`, data).then((response: any) => {
    if (response.statusCode == 200) {
      let orderId = data;
      let status = 'Ack%20%26%20Tax%20Calculated';
      result = response.message
      localStorage.removeItem('stage')
      localStorage.removeItem('activeStep')
      Swal.fire({title:'success',text:response.message,allowOutsideClick:false}).then(() => {window.location.href=`/orderAcks?id=${orderId}&status=${status}`;});
      // window.location.href=`/orderAcks?id=${orderId}&status=${status}`;
    }
  })
    .catch(error => {
      console.error('Error occurred:', error);
      if(error.response.status == 401){
        //apiService.tokenExpired();
    }
    if(error.response.status == 500){
      Swal.fire({title:'Error',text:"Internal Server Error",allowOutsideClick:false});
  }
  if(error.response.status == 500){
    Swal.fire({title:'Error',text:"Internal Server Error",allowOutsideClick:false});
}
    });
  return result;
}
// Api for sent Ariba 
export const SentAriba = async (data: any) => {
  let result;
  await axiosInstance.post(`${apiUrl}ariba/senttoariba/?orderId=${data}`).then((response: any) => {
    if (response.status == 200) {
      result = response.status
      let status = 'Sent to Ariba'
      window.location.href = `/orderAcks?id=${data}&status=${status}`
    }
  })
    .catch(error => {
      console.error('Error occurred:', error);
      if(error.response.status == 401){
        //apiService.tokenExpired();
    }
    if(error.response.status == 500){
      Swal.fire({title:'Error',text:"Internal Server Error",allowOutsideClick:false});
    }
    if(error.response.status == 400){
      Swal.fire({title:'Error',text:error.response.data.content,allowOutsideClick:false}).then(()=>{window.location.href='/fulfillment';});
    }
    });
  return result;
}
// Api for check Ariba 
export const checkAriba = async (data: any) => {
  let result;
  let status: any;
  await axiosInstance.post(`${apiUrl}ariba/checkstatusariba/?orderId=${data}`).then((response: any) => {
    if (response.data.status_code == 200) {      
      result = response.data.body;
      localStorage.removeItem('stage')
      localStorage.removeItem('activeStep')      
      Swal.fire({title:'Success',text:result,allowOutsideClick:false}).then(()=>{window.location.href='/fulfillment';});
    }
  })
    .catch((error:any) => {
      console.error('Error occurred:', error);
      if(error.response.status == 401){
        //apiService.tokenExpired();
    }
    if(error.response.status == 500){
      result='Internal Server Error'
      Swal.fire({title:'Error',text:"Internal Server Error",allowOutsideClick:false}).then(()=>{window.location.href='/fulfillment'});
  }
  if(error.response.status == 400){
    result='Rejected'
    Swal.fire({title:'Rejected',text:error.response.data.body,allowOutsideClick:false}).then(()=>{window.location.href='/fulfillment'});
  }
    });
  return result;
}

// Api for check Ariba 
export const addPayloadId = async (data: any,payload:any) => {
  let result;
  let status: any;
  await axiosInstance.post(`${apiUrl}orders/changePayloadId?orderId=${data}&payloadID=${payload}`).then((response: any) => {
    if (response.data.status_code == 200) {      
      result = response.data.body;   
      Swal.fire({title:'Success',text:result,allowOutsideClick:false}).then(()=>{window.location.href='/fulfillment';});
    }
  })
    .catch((error:any) => {
      console.error('Error occurred:', error);
    if(error.response.status == 500){
      result='Internal Server Error'
    Swal.fire({text:error.response.data.body,allowOutsideClick:false}).then(()=>{window.location.href='/fulfillment'});
  }
  if(error.response.status == 400){
    result='Rejected'
    Swal.fire({text:error.response.data.body,allowOutsideClick:false}).then(()=>{window.location.href='/fulfillment'});
  }
    });
  return result;
}

//Get Rejected Error response
export const getResubmissionError= async (orderid:any)=>{
  let result;
  await axiosInstance.get(`${apiUrl}ariba/getaribaresponsedb/${orderid}/`).then((res:any)=>{
   if(res.data.status_code ==200){
    result = res.data.aribaResponse    
    }
  })
  return result
}