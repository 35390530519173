import './invoice_header.css'
import { Backdrop, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Paper, Select, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import InvoiceHeaderEditAddress from './invoice_header_editModal';
import React, { forwardRef, useImperativeHandle } from 'react';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { Formik, Form, Field, ErrorMessage, FormikProps } from "formik";
import dayjs, { Dayjs } from 'dayjs';
import { customerAddressType, getCustomerAddress } from './invoice_api';
import { useLocation } from 'react-router-dom';

interface ChildComponentProps {
  handleEditFields: () => void; // Define the 'handleEditFields' property with the correct type
  isFieldsEnabled: boolean; // Update the type if necessary
  invoiceHeaderDetail: any;
}

export interface ChildComponentRef {
  invoiceSubmit: () => void;
}

const InvoiceHeader = forwardRef<ChildComponentRef, ChildComponentProps>((props: any, ref: any) => {
  const formRef = React.useRef<FormikProps<any>>(null);
  const [openUnit, setOpenUnit] = React.useState(false);
  const [addressType, setAddressType] = React.useState<any>();
  const [invoiceDate, setInvoiceDate] = React.useState<any>();
  const [billTo, setBillTo] = React.useState<any>([]);
  const [addressChange, setAddressChange] = React.useState<any>(false);
  const [orderId, setOrderId] = React.useState<any>();
  const location = useLocation();
  const handleOpenUnit = () => setOpenUnit(true);
  const handleCloseUnit = () => setOpenUnit(false);
  const handleCloseUnitModal = () => {
    setOpenUnit(false);
  }
  const initialValues = {
    invoiceNumber: "",
    description: "",
    supplierTax: "",
    customerAddress: "",
    invoiceDate: "",
  };

  useImperativeHandle(ref, () => ({
    invoiceSubmit() {
      if (formRef.current) {
        return formRef.current.values;
      }
    }
  }));

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    setOrderId(id)
    if (props.invoiceHeaderDetail) {
      setInvoiceDate(props.invoiceHeaderDetail.invoceDate)
      setBillTo(props.invoiceHeaderDetail?.editAddress)
      formRef.current?.setFieldValue('invoiceNumber', props.invoiceHeaderDetail.invoiceNumber);
      formRef.current?.setFieldValue('description', props.invoiceHeaderDetail.description);
      formRef.current?.setFieldValue('supplierTaxId', props.invoiceHeaderDetail.supplierTaxId);
    }
    const fetchData = async () => {
      try {
        await customerAddressType().then((address) => {
          setAddressType(address);
        })

      } catch (error) {
        console.error('Error occurred:', error);
      }
    };
    fetchData();
  }, [props.invoiceHeaderDetail])
  const handleDateFormat = (date: any) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    formRef.current?.setFieldValue('invoiceDate', formattedDate);
  }

  const handleAddressChange = async (type: any) => {
    await getCustomerAddress(orderId, type).then((response: any) => {
      if (response) {
        let billTo = [{
          "billToName": response.customerName,
          "billToCity": response.city,
          "billToState": response.state,
          "billToCountry": response.country,
          "billToZip": response.postalCode,
        }]
        setAddressChange(true)
        setBillTo(billTo)
      }
    }).catch((error) => {
      console.error('Error occurred:', error);
    });
  }

  return (
    <div>
      <Paper className='custom-invoiceHeader-paper'>
        <Grid container className='custom-invoiceHeader-container'>
          <Grid item xs={12} md={12}>
            <Stack direction="row" spacing={2}>
              <h1 className='custom-invoice-header'>Invoice Header</h1>
            </Stack>
          </Grid>
        </Grid>
        {/* <Grid container>
          <Grid item xs={12} textAlign={'end'} pr={2} py={1}>
            <p className='custom-invoice-viewAddress' onClick={handleOpenUnit}><span > <Icon icon="el:pencil-alt" style={{ paddingTop: '2px', cursor: 'pointer' }} height={14} width={14} color="#5755ff" />  &nbsp;</span> Edit Address</p>
          </Grid>
        </Grid> */}
        <Grid container pt={1}>
          <Grid item md={4} lg={4} sm={4} xs={4} textAlign={'center'}>
            <Grid container justifyContent={'center'}>
              <Formik initialValues={initialValues} innerRef={formRef} onSubmit={() => { }}>
                <Form>
                  <Grid item md={8} lg={11} sm={8} xs={8} pb={1}>
                    <Field as={TextField} fullWidth size='small' id="invoiceNumber" name="invoiceNumber" label="Invoice Number" className='custom-invoice-textField' variant="outlined" disabled />
                  </Grid>
                  <Grid item md={8} lg={11} sm={8} xs={8} pb={1} textAlign={'center'} className='custom-invoice-datepicker-item'>
                    <LocalizationProvider fullWidth dateAdapter={AdapterDayjs} id='invoiceDate' name="invoiceDate">
                      <DemoContainer components={['DatePicker', 'DesktopDatePicker',]}><DemoItem >
                        <DesktopDatePicker className='custom-invoice-dateField' label="Invoice Date" value={dayjs(invoiceDate)} disabled onChange={handleDateFormat} />
                      </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item md={8} lg={11} sm={8} xs={8} py={1}>
                    <Field as={TextField} fullWidth size='small' id="description" name="description" label="Description " className='custom-invoice-textField' variant="outlined" disabled={!props.isFieldsEnabled} />
                  </Grid>
                  {/* <Grid item md={8} lg={11} sm={8} xs={8} py={1}>
                    <Field as={TextField} fullWidth size='small' id="supplierTax" name="supplierTax" label="Supplier Tax ID" className='custom-invoice-textField' variant="outlined" disabled={!props.isFieldsEnabled} />
                  </Grid> */}
                  {/* <Grid item md={8} lg={11} sm={8} xs={8} py={1}>
                    <FormControl fullWidth size='small' className='custom-invoice-selectField-formcontrol'>
                      <InputLabel id="CatalogCode">Choose customer address</InputLabel>
                      <Field as={Select} className='custom-invoice-selectField' labelId="choosecustomerAddress"
                        name="customerAddress" id="customerAddress" label="Choose Customer Address" disabled={!props.isFieldsEnabled} >
                        {Array.isArray(addressType) && addressType.map((addressType: any) => (
                          <MenuItem key={addressType.id} value={addressType.id}  onClick={() => handleAddressChange(addressType.id)}> {addressType.addressTypeName}</MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid> */}
                </Form>
              </Formik>
            </Grid>
          </Grid>
          <Grid item md={3} lg={4} sm={3} xs={3} textAlign={'center'}>
            <Grid container >
              <Grid item md={4} lg={3} sm={4} xs={4}>
                <p className='custom_invoiceHeader_bill'> Remit To :</p>
              </Grid>
              <Grid item md={7} lg={7} sm={7} xs={7}>
                <p className='custom_invoiceHeader_bill_head'>  {props.invoiceHeaderDetail?.editAddress?.remitToName}</p>
                <p className='custom_invoiceHeader_bill'>{props.invoiceHeaderDetail?.editAddress?.address}</p>
              </Grid>
            </Grid>
            <Grid container >
              <Grid item md={4} lg={3} sm={4} xs={4}>
                <p className='custom_invoiceHeader_bill'> Bill To :</p>
              </Grid>
              {(addressChange) ?
                <Grid item md={7} lg={7} sm={7} xs={7}>
                  <p className='custom_invoiceHeader_bill_head'> {billTo[0]?.billToName} </p>
                  <p className='custom_invoiceHeader_bill'>{billTo[0]?.billToAddress}&nbsp;{billTo[0]?.billToCity} &nbsp;
                    {billTo[0]?.billToState}&nbsp;{billTo[0]?.billToCountry}&nbsp;{billTo[0]?.billToZip}
                  </p>
                </Grid>
                :
                <Grid item md={7} lg={7} sm={7} xs={7}>
                  <p className='custom_invoiceHeader_bill_head'> {props.invoiceHeaderDetail?.editAddress?.billToName} </p>
                  <p className='custom_invoiceHeader_bill'>{props.invoiceHeaderDetail?.editAddress?.billToAddress}&nbsp;{props.invoiceHeaderDetail?.editAddress?.billToCity} &nbsp;
                    {props.invoiceHeaderDetail?.editAddress?.billToState}&nbsp;{props.invoiceHeaderDetail?.editAddress?.billToCountry}&nbsp;{props.invoiceHeaderDetail?.editAddress?.billToZip}
                  </p>
                </Grid>

              }

            </Grid>
          </Grid>
          <Grid item md={5} lg={4} sm={5} xs={5}>
            <Grid container>
              <Grid item md={7} lg={7} sm={7} xs={7} pl={3} textAlign={'end'} >
                <p className='custom_invoiceHeader_text'> <b> Subtotal:</b></p>
              </Grid>
              <Grid item md={5} lg={5} sm={4} xs={4} pl={3}>
                <p className='custom_invoiceHeader_text'><b>{props.invoiceHeaderDetail?.subTotalAmount || ''}</b></p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={7} lg={7} sm={7} xs={7} pl={3} textAlign={'end'}>
                <p className='custom_invoiceHeader_text'>Total Tax:</p>
              </Grid>
              <Grid item md={4} lg={5} sm={4} xs={4} pl={3}>
                <p className='custom_invoiceHeader_text'>{props.invoiceHeaderDetail?.totalTax || ''}</p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={7} lg={7} sm={7} xs={7} pl={3} textAlign={'end'}>
                <p className='custom_invoiceHeader_text'>Total Gross Amount:</p>
              </Grid>
              <Grid item md={4} lg={5} sm={4} xs={4} pl={3}>
                <p className='custom_invoiceHeader_text'>{props.invoiceHeaderDetail?.totalGrossAmount || ''}</p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={7} lg={7} sm={7} xs={7} pl={3} textAlign={'end'}>
                <p className='custom_invoiceHeader_text'>Total withholding tax amount</p>
              </Grid>
              <Grid item md={4} lg={5} sm={4} xs={4} pl={3}>
                <p className='custom_invoiceHeader_text'>{props.invoiceHeaderDetail?.totalHoldingTaxAmount || ''}</p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={7} lg={7} sm={7} xs={7} pl={3} textAlign={'end'}>
                <p className='custom_invoiceHeader_text'>Total Net Amount:</p>
              </Grid>
              <Grid item md={4} lg={5} sm={4} xs={4} pl={3}>
                <p className='custom_invoiceHeader_text'>{props.invoiceHeaderDetail?.totalNetAmount || ''}</p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={7} lg={7} sm={7} xs={7} pl={3} textAlign={'end'}>
                <p className='custom_invoiceHeader_text'><b>Amount Due</b></p>
              </Grid>
              <Grid item md={4} lg={5} sm={4} xs={4} pl={3}>
                <p className='custom_invoiceHeader_text'><b>{props.invoiceHeaderDetail?.totalDueAmount || ''}</b></p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        open={openUnit} onClose={handleCloseUnit}>
        <div >
          <InvoiceHeaderEditAddress invoiceHeaderDetail={props.invoiceHeaderDetail} handleCloseUnitModal={handleCloseUnitModal} />
        </div>
      </Modal>
    </div>
  )
});

export default InvoiceHeader
