import { IOSSwitch } from './line_items_table'
import './resubmission_line_items.css';
import { Formik, Form, Field, ErrorMessage, FieldProps, FormikProps, FieldArray } from "formik";
import * as Yup from "yup";
import { Button, Checkbox, FormControl, FormControlLabel, Grid, Icon, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import React, { forwardRef, useImperativeHandle } from 'react';
import './line-items-table.css'
import { getItemById } from '../../../items/add_item/add_item';
import { getTaxcategory } from '../resubmission_api';


interface ChildComponentProps {
  handleEditFields: () => void; // Define the 'handleEditFields' property with the correct type
  isFieldsEnabled: boolean; // Update the type if necessary
  lineItemDetail: any
}

export interface ChildComponentRef {
  lineItemsSubmit: () => void;
}

const ReSubmissionLineItems = forwardRef<ChildComponentRef, ChildComponentProps>((props: any, ref: any) => {
  const formRef = React.useRef<FormikProps<any>>(null);
  const lineItemRef = React.useRef<{ handleAdd: () => void } | null>(null);
  const [taxData, setTaxData] = React.useState<any>();

  const initialValues = {
    taxCategoryCheckbox: false,
    taxcategory: "",
    shippingDocument: false,
    specialHandling: false,
    Discount: false,
    lineItems: [{
      lineItemsInclude: false,
      lineItemsType: "",
      lineItemsSku: "",
      lineItemsDescription: "",
      lineItemsQuantity: "",
      lineItemsUnit: "",
      lineItemsUnitPrice: "",
      lineItemsUnitSubtotal: "",
      lineItemsCommissionAmount: "",
      lineItemsGoods: "",
      lineItemsCommodityCode: "",
    }]

  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        if (props.lineItemDetail) {
          await getTaxcategory().then((response: any) => {
            setTaxData(response)
            let salesTaxCategory = response.find((item: any) => item.taxCategoryName === props.lineItemDetail.taxCategory);
            formRef.current?.setFieldValue('taxcategory', salesTaxCategory.id)
          })
          // console.log(props.lineItemDetail.itemId[0].itemName)
          let length = props.lineItemDetail.itemId.length
          for (let i = 0; i < length; i++) {
            await getItemById(props.lineItemDetail.itemId[i].itemName, 'resubmission').then((response: any) => {
              formRef.current?.setFieldValue(`lineItems[${i}].lineItemsType`, response.itemName);
              formRef.current?.setFieldValue(`lineItems[${i}].lineItemsSku`, response.SKU);
              formRef.current?.setFieldValue(`lineItems[${i}].lineItemsDescription`, response.description);
              formRef.current?.setFieldValue(`lineItems[${i}].lineItemsQuantity`, props.lineItemDetail.itemId[i].quantity);
              formRef.current?.setFieldValue(`lineItems[${i}].lineItemsUnit`, response.uomId);
              formRef.current?.setFieldValue(`lineItems[${i}].lineItemsUnitPrice`, response.unitPrice);
              formRef.current?.setFieldValue(`lineItems[${i}].lineItemsUnitSubtotal`, props.lineItemDetail.itemId[i].subtotalAmount);
              formRef.current?.setFieldValue(`lineItems[${i}].lineItemsCommodityCode`, response.commodityCode);
            })
          }
        }

      } catch (error) {
        console.error('Error occurred:', error);
      }
    }
    fetchData();
  }, [props.lineItemDetail])


  useImperativeHandle(ref, () => ({
    lineItemsSubmit() {
      if (formRef.current) {
        return formRef.current.values;
      }
    }
  }));


  return (
    <>
      <Paper className='custom-ReSubmissionLineItems-paper' >
        <Grid container className='custom-ReSubmissionLineItems-container'>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <h1 className='custom-ReSubmissionLineItems-header'> Line Items</h1>
            </Stack>
          </Grid>
        </Grid>
        <Grid container >
          <Grid item xs={6} py={1} textAlign={'start'} pl={2}>
            {/* <p className='custom-ReSubmissionLineItems-bill'>Insert Line Item Options</p> */}
          </Grid>
          <Grid item xs={6} py={1} textAlign={'end'} pr={2}>
            {/* <p className='custom-ReSubmissionLineItems-bill'>2 Line items, 2 Included, 0 Prevoiusly Fuly Invoiced</p> */}
          </Grid>
        </Grid>
        <Formik initialValues={initialValues} innerRef={formRef} onSubmit={() => { }}>
          {({ values }) => (
            <Form>
              <Grid container justifyContent={'space-evenly'}>
                <Grid item md={4} lg={4} sm={4} xs={4}>
                  <Grid container >
                    <Grid item md={6} lg={6} sm={6} xs={6} pt={2} textAlign={'end'}>
                      <Field name='taxCategoryCheckbox'>
                        {({ field }: FieldProps<boolean>) => (
                          <FormControlLabel className="custom-ReSubmissionLineItems-checkbox"
                            control={<Checkbox {...field} />}
                            label="Tax Category :" disabled
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={6} py={1} >
                      <FormControl sx={{ m: 1 }} size="small" fullWidth className='custom-lineitems-select'>
                        <InputLabel className='custom-lineitems-textField' >Tax category</InputLabel>
                        <Field as={Select} className='custom-lineitems-selectfield' fullWidth id="taxcategory" name="taxcategory" label="Tax category" variant="outlined" disabled>
                          {Array.isArray(taxData) && taxData.map((tax: any) => (
                            <MenuItem key={tax.id} value={tax.id} >{tax.taxCategoryName}</MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={3} lg={3} sm={3} xs={2} textAlign={'center'}>
                  <Grid container >
                    <Grid item md={12} lg={12} sm={12} xs={12} pt={2}  >
                      <Field name='shippingDocument'>
                        {({ field }: FieldProps<boolean>) => (
                          <FormControlLabel className="custom-ReSubmissionLineItems-checkbox"
                            control={<Checkbox {...field} />}
                            label="Shipping Documents" disabled
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={2} lg={2} sm={2} xs={2}>
                  <Grid container >
                    <Grid item md={12} lg={12} sm={12} xs={12} pt={2}  >
                      <Field name='specialHandling'>
                        {({ field }: FieldProps<boolean>) => (
                          <FormControlLabel className="custom-ReSubmissionLineItems-checkbox"
                            control={<Checkbox {...field} />}
                            label="Special Handling" disabled
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={1} lg={1} sm={1} xs={1}>
                  <Grid container >
                    <Grid item md={12} lg={12} sm={12} xs={12} pt={2}  >
                      <Field name='Discount'>
                        {({ field }: FieldProps<boolean>) => (
                          <FormControlLabel className="custom-ReSubmissionLineItems-checkbox"
                            control={<Checkbox {...field} />}
                            label="Discount" disabled
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </Grid>

                <FieldArray name="lineItems">
                  {({ push, remove }) => (
                    <>
                      {/* <Grid item md={2} lg={2} sm={2} xs={2}>
                        <Grid container >
                          <Grid item md={12} lg={12} sm={12} xs={12} pt={3} textAlign={'center'} >
                            <Button type="submit" className="custom-PaymentTerm-save-btn" disabled={!props.isFieldsEnabled} onClick={() => push({ initialValues })} > Add Line items</Button>
                          </Grid>
                        </Grid>
                      </Grid> */}

                      <Grid>

                        <TableContainer component={Paper} className='custom-lineItems-table-container'>
                          <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead >
                              <TableRow>
                                {/* <TableCell sx={{ minWidth: 20 }} className='custom-lineItems-table-head' align="center">#</TableCell> */}
                                <TableCell sx={{ minWidth: 20 }} className='custom-lineItems-table-head' align="center">No</TableCell>
                                {/* <TableCell sx={{ minWidth: 20 }} className='custom-lineItems-table-head' align="center">Include</TableCell> */}
                                <TableCell sx={{ minWidth: 20 }} className='custom-lineItems-table-head' align="center">Type </TableCell>
                                <TableCell sx={{ minWidth: 40 }} className='custom-lineItems-table-head' align="center">SKU</TableCell>
                                <TableCell sx={{ minWidth: 80 }} className='custom-lineItems-table-head' align="center">Description</TableCell>
                                <TableCell sx={{ minWidth: 30 }} className='custom-lineItems-table-head' align="center">Quantity</TableCell>
                                <TableCell sx={{ minWidth: 50 }} className='custom-lineItems-table-head' align="center">Unit</TableCell>
                                <TableCell sx={{ minWidth: 50 }} className='custom-lineItems-table-head' align="center">Unit Price</TableCell>
                                <TableCell sx={{ minWidth: 80 }} className='custom-lineItems-table-head' align="center">Subtotal</TableCell>
                                <TableCell sx={{ minWidth: 20 }} className='custom-lineItems-table-head' align="center">commission Amount</TableCell>
                                <TableCell sx={{ minWidth: 20 }} className='custom-lineItems-table-head' align="center">goods</TableCell>
                                <TableCell sx={{ minWidth: 20 }} className='custom-lineItems-table-head' align="center">Commodity Code</TableCell>
                                <TableCell sx={{ minWidth: 10 }} className='custom-lineItems-table-head' align="center"></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody >
                              {values.lineItems.map((_: any, index: any) => (
                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                  {/* <TableCell sx={{ minWidth: 20 }} className='custom-lineItems-table-fields' align="center">
                                    <Checkbox className="custom-lineitems-checkbox" disabled={!props.isFieldsEnabled} />
                                  </TableCell> */}
                                  <TableCell sx={{ minWidth: 20 }} className='custom-lineItems-table-fields' align="center">
                                    <p>{index + 1}</p>
                                  </TableCell>
                                  {/* <TableCell sx={{ minWidth: 40 }} className='custom-lineItems-table-fields' align="center">
                                    <IOSSwitch id="lineItemsInclude" name="lineItemsInclude" />
                                  </TableCell> */}
                                  <TableCell sx={{ minWidth: 90 }} className='custom-lineItems-table-fields' align="center">
                                    {/* <FormControl fullWidth className='custom-ReSubmissionTax-select-formcontrol'>
                                      <InputLabel className='custom-ReSubmissionTax-textField' id="Taxcategory">Type</InputLabel>
                                      <Field as={Select} fullWidth size="small" className='custom-ReSubmissionTax-select' id={`lineItems[${index}].lineItemsType`} name={`lineItems[${index}].lineItemsType`} labelId="lineItemsType" label="Tax category" variant="outlined" disabled={!props.isFieldsEnabled}>
                                        <MenuItem value="1">Chase_001_250</MenuItem>
                                      </Field>
                                    </FormControl> */}
                                    <Field as={TextField} size="small" id={`lineItems[${index}].lineItemsType`} name={`lineItems[${index}].lineItemsType`} className='custom-ReSubmissionLineItems-textField' variant="outlined" disabled />
                                  </TableCell>
                                  <TableCell sx={{ minWidth: 90 }} className='custom-lineItems-table-fields' align="center">
                                    <Field as={TextField} size="small" id={`lineItems[${index}].lineItemsSku`} name={`lineItems[${index}].lineItemsSku`} className='custom-ReSubmissionLineItems-textField' variant="outlined" disabled />
                                  </TableCell>
                                  <TableCell sx={{ minWidth: 150 }} className='custom-lineItems-table-fields' align="center">
                                    <Field as={TextField} fullWidth size="small" id={`lineItems[${index}].lineItemsDescription`} name={`lineItems[${index}].lineItemsDescription`} className='custom-ReSubmissionLineItems-textField' variant="outlined" disabled />
                                  </TableCell>
                                  <TableCell sx={{ minWidth: 30 }} className='custom-lineItems-table-fields' align="center">
                                    <Field as={TextField} size="small" id={`lineItems[${index}].lineItemsQuantity`} name={`lineItems[${index}].lineItemsQuantity`} className='custom-ReSubmissionLineItems-textField' variant="outlined" disabled />
                                  </TableCell>
                                  <TableCell sx={{ minWidth: 50 }} className='custom-lineItems-table-fields' align="center">
                                    <Field as={TextField} size="small" id={`lineItems[${index}].lineItemsUnit`} name={`lineItems[${index}].lineItemsUnit`} className='custom-ReSubmissionLineItems-textField' variant="outlined" disabled />
                                  </TableCell>
                                  <TableCell sx={{ minWidth: 50 }} className='custom-lineItems-table-fields' align="center">
                                    <Field as={TextField} size="small" id={`lineItems[${index}].lineItemsUnitPrice`} name={`lineItems[${index}].lineItemsUnitPrice`} className='custom-ReSubmissionLineItems-textField' variant="outlined" disabled />
                                  </TableCell>
                                  <TableCell sx={{ minWidth: 80 }} className='custom-lineItems-table-fields' align="center">
                                    <Field as={TextField} size="small" id={`lineItems[${index}].lineItemsUnitSubtotal`} name={`lineItems[${index}].lineItemsUnitSubtotal`} className='custom-ReSubmissionLineItems-textField' variant="outlined" disabled />
                                  </TableCell>
                                  <TableCell sx={{ minWidth: 20 }} className='custom-lineItems-table-fields' align="center">
                                    <Field as={TextField} fullWidth size="small" id={`lineItems[${index}].lineItemsCommissionAmount`} name={`lineItems[${index}].lineItemsCommissionAmount`} className='custom-ReSubmissionLineItems-textField' variant="outlined" disabled />
                                  </TableCell>
                                  <TableCell sx={{ minWidth: 20 }} className='custom-lineItems-table-fields' align="center">
                                    <Field as={TextField} fullWidth size="small" id={`lineItems[${index}].lineItemsGoods`} name={`lineItems[${index}].lineItemsGoods`} className='custom-ReSubmissionLineItems-textField' variant="outlined" disabled />
                                  </TableCell>
                                  <TableCell sx={{ minWidth: 20 }} className='custom-lineItems-table-fields' align="center">
                                    <Field as={TextField} fullWidth size="small" id={`lineItems[${index}].lineItemsCommodityCode`} name={`lineItems[${index}].lineItemsCommodityCode`} className='custom-ReSubmissionLineItems-textField' variant="outlined" disabled />
                                  </TableCell>
                                  {/* <TableCell>
                                  {(index != 0) ?
                                    <Grid item lg={2} md={4} sm={4} xs={5} textAlign={'end'}>
                                      <Button className="custom-PaymentTerm-cancel-btn" onClick={() => remove(index)} >Remove</Button>
                                    </Grid> 
                                    : ''}
                                    {(index == 0) ?
                                  <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"start"}>
                                    <Button className="custom-PaymentTerm-cancel-btn" 
                                      onClick={() => {
                                        const lineItems = formRef.current?.values.lineItems || [];
                                        lineItems.forEach((_: any, index: any) => remove(index));
                                      }}>
                                       Reset
                                      </Button>
                                  </Grid>
                                  : ''}
                                  <div>
                                  
                                  </div>
                                  </TableCell> */}

                                </TableRow>

                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </>
                  )}
                </FieldArray>


              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  )
});

export default ReSubmissionLineItems;







