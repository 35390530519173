import Footer from '../../components/footer/footer';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Box, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Button, Link as MuiLink, SelectChangeEvent, FormHelperText, Grid, Alert, IconButton, InputAdornment, OutlinedInput, Paper } from '@mui/material';
import { Icon } from '@iconify/react';
import React from 'react';
import { forgotPassword } from './forgot_password_api';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { VisibilityOff, Visibility } from '@mui/icons-material';
var CryptoJS = require("crypto-js");
const secret_key = process.env.REACT_APP_SECRET_KEY_URL;
const SECRET_KEY=CryptoJS.enc.Hex.parse(secret_key)
const iv=SECRET_KEY
export default function ForgotPassword() {
    const [submitted, setSubmitted] = React.useState(false);
    const [alert, setAlert] = React.useState<any>();
    const [responseMsg, setResponseMsg] = React.useState();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [otp, setOtp] = React.useState<boolean>(false);
    const [password, setPassword] = React.useState(false);

    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);


    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const initialValues = {
        email: '',
        otp: '',
        newPassword: '',
        confirmNewPassword: '',
    }

    let validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email format').required('Email is required'),
        otp: Yup.string(),
        newPassword: Yup.string(),
        confirmNewPassword: Yup.string(),
    });

    if (otp) {
        validationSchema = validationSchema.shape({
            otp: Yup.string().required('OTP is required'),
        });
    }

    if (password) {
        validationSchema = validationSchema.shape({
        //   newPassword: Yup.string().required('New password is required').matches(
        //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d@"!"#$%&'()*+,-./:;<=>?[\\]^_`{|}~"]{8,}$/,
        //     "Password must be 8+ characters with uppercase, lowercase, digit, and special character"
        // ),
        newPassword: Yup.string().required('New password is required').matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/,
            "Password must be 8+ characters with uppercase, lowercase, digit, and special character"
        ),
          confirmNewPassword: Yup.string()
            .required('Confirm new password is required')
            .oneOf([Yup.ref('newPassword')], 'Passwords do not match'), // Add custom validation rule
        });
      }


    const ALERT_DURATION = 3000;
    const alertClose = () => {
        setAlert('')
    }

    const handleSubmit = async (values: any, { setSubmitting }: FormikHelpers<any>) => {
        const errors = {};
        setLoading(true)
        let type;
        if (otp == true) {
            type = 'otp'
        } else if (password == true) {
            type = 'password'
        } else {
            type = 'email'
        }


        const confirmpssword = CryptoJS.AES.encrypt(values.confirmNewPassword, SECRET_KEY, { iv }).toString()
        const newpassword = CryptoJS.AES.encrypt(values.newPassword, SECRET_KEY, { iv }).toString()
        await forgotPassword({ ...values, 'confirmNewPassword': confirmpssword, 'newPassword': newpassword }, type).then((res: any) => {
            setLoading(false)
            if (res.status == 200) {
                setResponseMsg(res.message)
                setAlert('true')
                setTimeout(() => {
                    setAlert('');
                }, ALERT_DURATION);
                setOtp(true);
                if (password == true) {
                    setOtp(false);
                    setTimeout(() => {
                        window.location.href = '/login'
                    }, ALERT_DURATION);
                }
                if (res.message == 'OTP Verified.') {
                    if (otp == true)
                        setOtp(false);
                    setPassword(true);
                }


            } else if (res.status == 400) {
                setResponseMsg(res.message)
                setAlert('false')
                setTimeout(() => {
                    setAlert('');
                }, ALERT_DURATION);
            } else {
                setResponseMsg(res.message)
                setAlert('false')
                setTimeout(() => {
                    setAlert('');
                }, ALERT_DURATION);
            }
        });
        setSubmitted(false);
        setSubmitting(false);
    }
    return (
        <>
            <div className='custom-login-backgroundImage'>
                <Grid container spacing={2} className='custom-loginpage-container'>
                    <Grid item xl={7} lg={7} md={6} sm={5} xs={12} className="custom-img-grid" textAlign={'center'}>
                        <img src='../sidebar/caracal-vertical.png' className='custom-caracal-img' alt="login" />
                    </Grid>
                    <Grid item xl={5} lg={5} md={6} sm={7} xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Paper elevation={3} sx={{ maxHeight: 'inherit', width: '70%' }} className='custom-login-paper'>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    // width: '80%',
                                    padding: '1rem 2rem 2rem 2rem',
                                }}
                            >
                                {(alert == 'true') ? <Alert onClose={alertClose}>{responseMsg}</Alert> : (alert == 'false') ? <Alert onClose={alertClose} severity='error'>{responseMsg}</Alert> : ''}
                                <Grid container>
                                    <Grid item xs={12}>
                                        <h1 className='custom-login-heading' >Forgot Password</h1>
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent={'center'}>
                                    <Grid item xs={12}>
                                        <Formik initialValues={initialValues} validateOnChange={false} validateOnBlur={false} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                            {({ errors }) => (
                                                <Form>
                                                    <Grid container>
                                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                                                            <Field as={TextField} type="email" size='medium' className='custom-login-textfield' fullWidth label="Enter Email" id='email' name="email" variant="outlined" sx={{ mb: 2 }}
                                                                error={(!!errors.email) || (submitted && !!errors.email)}
                                                                helperText={(errors.email || (submitted && errors.email))}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <Icon icon="dashicons:email" color="#171c26" width="20" height="20" />
                                                                        </InputAdornment>
                                                                    )
                                                                }} />
                                                        </Grid>
                                                        {(otp) ?
                                                            // <Grid container>
                                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                                                                <Field as={TextField} type="text" size='medium' className='custom-login-textfield' fullWidth label="Enter Otp" id='otp' name="otp" variant="outlined" sx={{ mb: 2 }}
                                                                    error={(!!errors.otp) || (submitted && !!errors.otp)}
                                                                    helperText={(errors.otp || (submitted && errors.otp))}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <Icon icon="teenyicons:otp-solid" color="#171c26" width="20" height="20" />
                                                                            </InputAdornment>
                                                                        )
                                                                    }} />
                                                            </Grid>
                                                            // </Grid>

                                                            : ''}
                                                        {(password) ?
                                                            <Grid container>
                                                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                                                                    <Field as={TextField} size='medium' fullWidth className='custom-login-textfield'
                                                                        label="New Password" name="newPassword" variant="outlined" sx={{ mb: 2 }}
                                                                        error={(!!errors.newPassword) || (submitted && !!errors.newPassword)}
                                                                        helperText={(errors.newPassword || (submitted && errors.newPassword))}
                                                                        type={showNewPassword ? 'text' : 'password'}
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    <Icon icon="mdi:password" color="#171c26" width="20" height="20" />
                                                                                </InputAdornment>
                                                                            ),
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <IconButton
                                                                                        aria-label="toggle password visibility"
                                                                                        onClick={handleClickShowNewPassword}
                                                                                        onMouseDown={handleMouseDownPassword}
                                                                                        edge="end"
                                                                                    >
                                                                                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                                                                    <Field as={TextField} size='medium' fullWidth className='custom-login-textfield'
                                                                        label="Confirm New Password" name="confirmNewPassword" variant="outlined" sx={{ mb: 2 }}
                                                                        error={(!!errors.confirmNewPassword) || (submitted && !!errors.confirmNewPassword)}
                                                                        helperText={(errors.confirmNewPassword || (submitted && errors.confirmNewPassword))}
                                                                        type={showConfirmPassword ? 'text' : 'password'}
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    <Icon icon="mdi:password" color="#171c26" width="20" height="20" />
                                                                                </InputAdornment>
                                                                            ),
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <IconButton
                                                                                        aria-label="toggle password visibility"
                                                                                        onClick={handleClickShowConfirmPassword}
                                                                                        onMouseDown={handleMouseDownPassword}
                                                                                        edge="end"
                                                                                    >
                                                                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            : ''}


                                                        <Grid container justifyContent={'center'}>
                                                            <Grid item lg={4} md={4} sm={4} xs={6} pb={1}>
                                                                {(loading) ?
                                                                    <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} style={{padding:'5px 11px',borderRadius:'10px'}} className='custom-login-button' variant="contained" > Loading </LoadingButton>
                                                                    :
                                                                    <Button type="submit" fullWidth className='custom-login-button' style={{padding:'7px 8px',width:'92%'}}> Submit </Button>}

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </>
    )
}