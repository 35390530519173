import { ApiService } from "../../../service/api.service";
import { format } from 'date-fns';
import Swal from 'sweetalert2';

const apiService = new ApiService();

export const getSalesOrderDetails = async (id: any, stage: any) => {
  let salesOrderDetails;
  await apiService.getAll('orders/getUpdatedInvoiceCxmlPayload/' + id + '/').then((response: any) => {
    if (response.status_code === 200) {
      let result = response.data.cXML
      let orderDetails = response.data.SalesInvoice;
      let status = orderDetails['RoutingStatus']
      let activeStep: any;
      if (status == 'New') {
        activeStep = 0
      } else if (status == 'Ack & Tax Calculated' || status == 'Sent to Ariba' || status == 'Sent' || status == 'Processing') {
        activeStep = 1
      } else if (status == 'shipped' || status == 'Sent to Ariba' || status == 'Sent') {
        activeStep = 2
      } else if (status == 'Resubmitted' || status == 'Rejected') {
        activeStep = 3
      } else if (status == 'Invoiced' || status == 'Approved') {
        activeStep = 4
      }
      if (stage != undefined) {
        localStorage.setItem('activeStep', stage)
      } else {
        localStorage.setItem('activeStep', activeStep)
      }
      localStorage.setItem('stage', activeStep)
      let invoiceDate = result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader['@invoiceDate']
      const invoiceDatetime = new Date(invoiceDate);
      const formattedInvoiceDateTime = format(new Date(invoiceDatetime), 'EEEE dd MMMM yyyy');

      let orderDate = orderDetails.ProcessedTime
      const orderDateTime = new Date(orderDate);
      const formattedOrderDateTime = format(new Date(orderDateTime), 'EEEE dd MMMM yyyy');

      let lineItem = result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem

      let lineItemDetails = [];
      console.log(result)
      for (let i = 0; i < lineItem.length; i++) {
        let details = {
          "itemName": lineItem[i].InvoiceDetailItemReference.ItemID.SupplierPartID,
          "quantity": lineItem[i]['@quantity'] + '/' + lineItem[i]['UnitOfMeasure'],
          "subtotalAmount": '$ ' + lineItem[i].SubtotalAmount.Money['#text'] + ' ' + lineItem[i].SubtotalAmount.Money['@currency']
        }
        lineItemDetails.push(details);
      }

      let legalEntity = result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].Extrinsic[1]['#text']

      let catalogCode = legalEntity.substring(1)

      salesOrderDetails = {
        "orderDetails": {
          "orderId": orderDetails.OrderId,
          "TaylorOrderId": orderDetails.TaylorOrderId,
          "TaylorOldInvoiceId": orderDetails.TaylorOldInvoiceId,            
          "orderDate": formattedOrderDateTime,
          "customerName": orderDetails.CustomerName,
          "purchaseOrder": orderDetails.RoutingStatus,
          "invoiceId": result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader['@invoiceID'],
          "invoiceDate": formattedInvoiceDateTime,
          "dueAmount":'$ ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.DueAmount.Money['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.DueAmount.Money['@currency'],
          "payloadId":result['@payloadID'],
          "Comments":orderDetails.Comments
        },
        "invoiceHeader": {
          "invoiceNumber": result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader['@invoiceID'],
          "invoceDate": invoiceDatetime,
          "description": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailItemReference.Description["#text"],
          "supplierTaxId": "",
          "customerAddress": "",
          "remitTo": result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[0].Contact.Name["#text"] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[0].Contact.PostalAddress.Street + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[0].Contact.PostalAddress.State + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[0].Contact.PostalAddress.PostalCode + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[0].Contact.PostalAddress.Country['#text'],
          "billTo": result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[1].Contact.Name["#text"] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[1].Contact.PostalAddress.Street + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[1].Contact.PostalAddress.State + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[1].Contact.PostalAddress.PostalCode + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[1].Contact.PostalAddress.Country['#text'],
          "subTotalAmount": '$ ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.SubtotalAmount.Money['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.SubtotalAmount.Money['@currency'],
          "totalTax": '$ ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.Money['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.Money['@currency'],
          "totalGrossAmount": '$ ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.GrossAmount.Money['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.GrossAmount.Money['@currency'],
          "totalHoldingTaxAmount": "",
          "totalNetAmount": '$ ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.NetAmount.Money['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.NetAmount.Money['@currency'],
          "totalDueAmount": '$ ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.NetAmount.Money['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.NetAmount.Money['@currency'],
          "editAddress": {
            "taxIdSupplier": "",
            "remitToName": result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[0].Contact.Name["#text"],
            "address": result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[0].Contact.PostalAddress.Street + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[0].Contact.PostalAddress.State + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[0].Contact.PostalAddress.PostalCode + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[0].Contact.PostalAddress.Country['#text'],
            "remitToId": "",
            "accountRecievableId": "",
            "billToChooseAddress": "",
            "billToName": result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[1].Contact.Name["#text"],
            "billToAddress": result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[1].Contact.PostalAddress.Street,
            "billToCity": result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[1].Contact.PostalAddress.City,
            "billToState": result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[1].Contact.PostalAddress.State,
            "billToZip": result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[1].Contact.PostalAddress.PostalCode,
            "billToCountry": result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[1].Contact.PostalAddress.Country['#text']
          }
        },
        "orderInformation": {
          "customerOrder": "",
          "contractNumber": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailOrderInfo['MasterAgreementReference']['@agreementID'],
          "salesOrder": "",
          "salesOrderDate": ""
        },
        "extrinsicInfo": {
          "catalogCode": catalogCode,
          "businessUnit": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].Extrinsic[0]['#text'],
          "legalEntity": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].Extrinsic[1]['#text'],
          "costCenter": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].Distribution.Accounting.AccountingSegment['@id']
        },
        "tax": {
          "taxLocation": result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.TaxDetail.TaxLocation['#text'],
          "taxableAmount": '$ ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.Money['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.Money['@currency'],
          "taxAmount": '$ ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.TaxDetail.TaxAmount.Money['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.TaxDetail.TaxAmount.Money['@currency'],
          "taxCategory": result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.TaxDetail['@category'],
          "rate": result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.TaxDetail['@percentageRate'],
        },
        "shipping": {
          "shipFromName": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[0].Name['#text'],
          "shipFromAddress": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[0].PostalAddress['City'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[0].PostalAddress['Street'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[0].PostalAddress.Country['#text'],
          "shipToName": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].Name['#text'],
          "shipToAddress": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].PostalAddress['City'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].PostalAddress['Street'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].PostalAddress.Country['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].PostalAddress['State'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].PostalAddress['PostalCode'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].PostalAddress.Country['#text'],
          "editAddress": {
            "shipTo": {
              "name": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].Name['#text'],
              "department": "",
              "address": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].PostalAddress['Street'],
              "city": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].PostalAddress['City'],
              "state": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].PostalAddress['State'],
              "zip": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].PostalAddress['PostalCode'],
              "country": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].PostalAddress.Country['#text']
            },
            "shipFrom": {
              "name": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[0].Name['#text'],
              "department": "",
              "address": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[0].PostalAddress['Street'],
              "city": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[0].PostalAddress['City'],
              "state": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[0].PostalAddress['State'],
              "zip": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[0].PostalAddress['PostalCode'],
              "country": result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[0].PostalAddress.Country['#text']
            }
          }
        },
        "paymentTerm": {
          "netTerm": result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.PaymentTerm['@payInNumberOfDays']
        },
        "lineItems": {
          "taxCategory": result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.TaxDetail['@category'],
          "itemId": lineItemDetails
        }
      }
    }
  })
    .catch(error => {
      console.error('Error occurred:', error);
      if (error.response.status == 401) {
        //apiService.tokenExpired();
      }
    });
  return salesOrderDetails
}

export const getTaxcategory = async () => {
  let result;
  await apiService.getAll('api/taxCategory/').then((response: any) => {
    if (response.status_code == 200) {
      result = response.data
    }
  })
    .catch(error => {
      console.error('Error occurred:', error);
      if (error.response.status == 401) {
        //apiService.tokenExpired();
      }
    });
  return result;
}

export const resubmissionForm = async (data: any) => {
  let result;
  let details = {
    "orderid": data.orderId,
    "customer_name": data.customerName,
    "orderInformation": {
      "contractNumber": data.orderInformationDetail.contractNumber,
      "salesOrder": data.orderInformationDetail.salesOrder,
      "salesOrderDate": data.orderInformationDetail.salesOrderDate,
    },
    "ExtrinsicInfo": data.extrinsicInfoDetail,
    "HeaderLevelTax": data.resubmissionTaxDetail,
    "HeaderLevelShipping": data.shippingDetail,
    "PaymentTerms": {
      "netTerm": data.paymentDetail.paymentTerms[0].netTerm
    }
  }
  await apiService.update('orders/UpdateSalesInvoiceDetails/', details).then((response: any) => {
    if (response.status_code == 200) {
      result = response.message
      Swal.fire({title:'Success', text:result,allowOutsideClick:false}).then(() => { window.location.href = '/fulfillment'; });
    }
  }).catch(error => {
    console.error('Error occurred:', error);
    if (error.response.status == 401) {
      //apiService.tokenExpired();
    }
  });
  return result
}

export const extrinsicCode = async (catalogCode: any, costCenter: any) => {
  let result
  await apiService.getAll(`orders/extrinsicCalculate/?catalogCode=${catalogCode}&costCenter=${costCenter}`).then((response: any) => {
    result = response;
  }).catch(error => {
    console.error('Error occurred:', error);
    if (error.response.status == 401) {
      //apiService.tokenExpired();
    }
  });
  return result
}

export const shipAddress = async (data: any) => {
  let result;
  let details = {
    "addressTitle": data.shippingToName,
    "addressType": 2,
    "addressLine1": data.shippingToAddress1,
    "addressLine2": data.shippingToAddress2,
    "country": data.shippingToCountry,
    "state": data.shippingToState,
    "city": data.shippingToCity,
    "postalCode": data.shippingToZip
  }
  await apiService.add('api/customer/?type=contactDetails', details).then((response: any) => {
    if (response.status_code) {
      result = response.message
    }
  }).catch(error => {
    console.error('Error occurred:', error);
    if (error.response.status == 401) {
      //apiService.tokenExpired();
    }
  });
}


