import { Paper } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import './order_tax_calculation.component.css'
import React from 'react';

export function TaxSummaryTable(props:any) {
  const [taxDetails, setTaxDetails] = React.useState<any>();
  const [amountDetails, setAmountDetails] = React.useState<any>()
  React.useEffect(() => {
    if(props.taxDetails){
      setTaxDetails(props.taxDetails);
    }    
    if(props.amountDetails){
      setAmountDetails(props.amountDetails);
    }    
  }, [props.taxDetails]);
  return (
    <TableContainer component={Paper} className='custom-TaxSummaryTable-table-container'>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className='custom-tax-summary' colSpan={6}>Tax Summary</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='custom-tax-table-head' align="center">#</TableCell>
            <TableCell className='custom-tax-table-head' align="center">Category</TableCell>
            <TableCell className='custom-tax-table-head' align="center">Details</TableCell>
            <TableCell className='custom-tax-table-head' align="center">Base Amount</TableCell>
            <TableCell className='custom-tax-table-head' align="center">Rate %</TableCell>
            <TableCell className='custom-tax-table-head' align="center">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell className='custom-tax-table-fields' align="center">1</TableCell>
              <TableCell className='custom-tax-table-fields' align="center">{taxDetails?.category}</TableCell>
              <TableCell className='custom-tax-table-fields' align="center">{taxDetails?.details}</TableCell>
              <TableCell className='custom-tax-table-fields' align="center">{taxDetails?.baseAmt}</TableCell>
              <TableCell className='custom-tax-table-fields' align="center">{taxDetails?.rate}</TableCell>
              <TableCell className='custom-tax-table-fields' align="center">{amountDetails?.dueAmount}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}