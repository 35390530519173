import { Button, Grid, Paper } from "@mui/material";
import './add_comment.css';
import {StyledTextarea} from './styled_text_area';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function AddComment(){
    return(
        <div>
            <Paper className='custom-paper-outline'>
                <Grid container>
                    <Grid item xs={12}>
                        <h1 className='custom-basic-comments'>Add a comment</h1>
                    </Grid>
                </Grid>
                <Grid container px={2} className="custom-comment-grid">
                    <Grid item xs={0}>
                        <AccountCircleIcon/>
                    </Grid>
                    <Grid item xs={11} pl={2}>
                        <StyledTextarea aria-label="minimum height" minRows={3}/>
                    </Grid>
                </Grid>
                <Grid container pb={2}>
                <Grid item xs={8} pl={7}>
                    <Button variant="contained" className='custom-send-btn'>Send</Button>
                </Grid>
            </Grid>
            </Paper>
        </div>
    )
}