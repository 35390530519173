import { Paper } from "@mui/material";
import { Icon } from '@iconify/react';
import { ApiService } from "../../../service/api.service";

const apiService = new ApiService();

export const allColumns = [
  {
    label: "#", name: "id", options: {
      setCellProps: () => ({ style: { width: '50px' } }),
      filter: true,
      sort: true,
    }
  },
  {
    label: "Order Id", name: "orderId", options: {
      customBodyRender: (params: any, tableMeta: any) => {
        const orderId = params;
        const rowIndex = tableMeta.rowIndex;
        let rowData = tableMeta.rowData[2];
        if (rowData == 'Ack & Tax Calculated'){
            rowData = 'Ack%20%26%20Tax%20Calculated'
        }
        return <a href={`/orderAcks?id=${params}&status=${rowData}`} className="custom-link-order">{params}</a>
      },
      filter: true,
      sort: true,
      setCellProps: () => ({ style: { width: '150px' } })
    }
  },

  {
    label: "Status", name: 'routingStatus', options: {
      customBodyRender: (params: any) => {
        const value = params?.toString()?.toLowerCase();
        const color = value === 'new' ? '#4800D9' : value === 'rejected' ? '#D1293D' : value === 'sent to ariba' ? '#E67F00' : value === 'approved' ? '#00B929' : value === 'invoiced' ? '#00B929' : value === 'sent' ? 'rgb(214 217 0)' : value === 'resubmitted' ? '#006CD9' :value === 'payloadid exist' ? 'rgb(239 87 87)' : '#5A6376';
        const backgroundColor = value === 'new' ? '#F3EDFF' : value === 'rejected' ? '#FFEDEF' : value === 'invoiced' ? '#EDFFF6' : value === 'sent to ariba' ? '#FFF7ED' : value === 'sent' ? 'rgb(255 255 237)' : value === 'resubmitted' ? '#EDF6FF' : value === 'approved' ? '#EDFFF6' :value === 'payloadid exist' ? 'rgb(245 235 233)' : '#E9EDF5';   
        const width = value === 'new' ? '55px' : value === 'rejected' ? '85px' : value === 'sent' ? '64px' : value === 'sent to ariba' ? '106px' : value === 'approved' ? '84px' : value === 'invoiced' ? '84px' : value === 'resubmitted' ? '95px' : value === 'processing' ? '90px' : value === 'payloadid exist' ? '127px' : '150px';
        return <Paper style={{ color, backgroundColor, width }} className='custom-status-paper'><span><Icon icon="mdi:square-rounded" className='custom-status-icon' /></span> {params}</Paper>;
      },
      setCellProps: () => ({ style: { width: '150px' } }),
      filter: true,
      sort: true,
    }
  },
  {
    label: "Taylor Order Id", name: "taylorOrderId", options: {
      filter: true,
      sort: true,
      setCellProps: () => ({ style: { width: '150px' } })
    }
  },
  {
    label: "Taylor Invoice Id", name: "taylorInvoiceId", options: {
      filter: true,
      sort: true,
      setCellProps: () => ({ style: { width: '150px' } })
    }
  },
  {
    label: "Invoice Id", name: "InvoiceId", options: {
      filter: true,
      sort: true,
      setCellProps: () => ({ style: { width: '150px' } })
    }
  },
  {
    label: "Date", name: 'createdAt', options: {
      customBodyRender: (params: any) => {
        var date = params.split('T')[0];
        return date;
      },
      setCellProps: () => ({ style: { width: '120px' } }),
      filter: true,
      sort: true,
    }
  },
  {
    label: "Vendor", name: 'supplierName', options: {
      setCellProps: () => ({ style: { width: '130px' } }),
      filter: true,
      sort: true,
    }
  },
  {
    label: "Customer", name: 'customerName', options: {
      setCellProps: () => ({ style: { width: '150px' } }),
      filter: true,
      sort: true,
    }
  },
  {
    label: "Ship Address Line", name: 'shipAddressLines', options: {
      setCellProps: () => ({ style: { width: '150px' } }),
      filter: true,
      sort: true,
    }
  },
  {
    label: "Ship State", name: 'shipToState', options: {
      setCellProps: () => ({ style: { width: '150px' } }),
      filter: true,
      sort: true,
    }
  },
  {
    label: "Ship Zip", name: 'shipToZip', options: {
      setCellProps: () => ({ style: { width: '150px' } }),
      filter: true,
      sort: true,
    }
  },
  {
    label: "Ship Country", name: 'shipToCountry', options: {
      setCellProps: () => ({ style: { width: '150px' } }),
      filter: true,
      sort: true,
    }
  },
  {
    label: "SubTotal ", name: 'subTotal', options: {
      setCellProps: () => ({ style: { width: '150px' } }),
      filter: true,
      sort: true,
    }
  },
  {
    label: "Tax", name: 'taxAmount', options: {
      setCellProps: () => ({ style: { width: '150px' } }),
      filter: true,
      sort: true,
    }
  },

  {
    label: "Amount", name: 'netAmount', options: {
      setCellProps: () => ({ style: { width: '100px' } }),
      filter: true,
      sort: true,
    }
  },
  {
    label: "Last Updated", name: 'updatedAt', options: {
      customBodyRender: (params: any) => {
        var date = params.split('T')[0];
        return date;
      },
      setCellProps: () => ({ style: { width: '160px' } }),
      filter: true,
      sort: true,
    }
  }
];


export const getAllOrder = async (fromDate: any, toDate: any, customer: any, search: any, pageNumber:any, pageSize:any,value:any, sort:any) => {
  let result,type;
  if (fromDate == undefined) {
    fromDate = null;
  }
  if (toDate == undefined) {
    toDate = null;
  }
  if (customer == undefined) {
    customer = null;
  }
  if (search == undefined) {
    search = null;
  }
  if (value == 1){ 
    type = 'New'
  } else if (value == 3){
    type = '[Rejected,PayloadID Exist]'
  } else if (value == 4){ 
    type = 'Resubmitted'
  } else if (value == 5){ 
    type = 'Approved'
  } else if (value == 6){ 
    type = '[Invoiced,Paid]'
  } else if (value == 2){
    type = 'Ack'
  } else {
    type = 'All'
  }


  await apiService.getAll(`orders/fulfillmentFilter/?customerId=${customer}&fromDate=${fromDate}&toDate=${toDate}&searchQuery=${search}&page=${pageNumber+1}&page_size=${pageSize}&status=${type}&sort=${sort}`).then((response: any) => {
    if (response.status_code == 200) {
      
      let count = {
        'new': response.count.newOrderCount,
        'allOrders': response.count.filteredDataCount,
        'ackTax': response.count.acknowledgedAndTaxCalcOrderCount,
        'reject': response.count.rejectedOrderCount,
        'resubmit': response.count.resubmittedOrderCount,
        'invoice': response.count.invoicedOrderCount,
        'approved': response.count.approvedOrderCount
      }
      
      let data = response.data;
      // const acksOrders = data.filter((order: any) => order.routingStatus === 'Ack & Tax Calculated');
      // const rejectOrders = data.filter((order: any) => order.routingStatus === 'Rejected');
      // const resubmitOrders = data.filter((order: any) => order.routingStatus === 'Resubmitted');
      // const invoiceOrders = data.filter((order: any) => order.routingStatus === 'Invoiced');
      // const approvedOrders = data.filter((order: any) => order.routingStatus === 'Approved');
      // console.log(acksOrders)
      // let ordersData = {
      //   'allOrders': data,
      //   'acksOrders': acksOrders,
      //   'rejectOrders': rejectOrders,
      //   'resubmitOrders': resubmitOrders,
      //   'invoiceOrders': invoiceOrders,
      //   'approvedOrders': approvedOrders
      // }
      result = {
        'count': count,
        'data': data,
        'pageCount' : response.page_count,
        'dataCount' : response.data_count,
        'page' : response.page
      }
    }
  })
    .catch(error => {
      console.error('Error occurred:', error);
      if(error.response.status == 401){
        //apiService.tokenExpired();
      }
    });
  return result;
}