import React, { forwardRef, useImperativeHandle } from 'react'
import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField } from '@mui/material'
import './extrinsic_information.css'
import { Formik, Form, Field, ErrorMessage, FormikProps } from "formik";
import * as Yup from "yup";
import { extrinsicCode } from '../resubmission_api';
import { useFormikContext } from 'formik';

interface ChildComponentProps {
  handleEditFields: () => void; // Define the 'handleEditFields' property with the correct type
  isFieldsEnabled: boolean; // Update the type if necessary
  extrinsicInfoDetail: any;
}



export interface ChildComponentRef {
  extrinsicInformationSubmit: () => void;
}

const ExtrinsicInformation = forwardRef<ChildComponentRef, ChildComponentProps>((props: any, ref: any) => {
  const formRef = React.useRef<FormikProps<any>>(null);

  const initialValues = {
    catalogCode: "",
    legalEntity: "",
    costCenter: "",
    businessUnit: "",
  };
  React.useEffect(() => {
    if (props.extrinsicInfoDetail) {
      formRef.current?.setFieldValue('businessUnit', props.extrinsicInfoDetail.businessUnit);
      formRef.current?.setFieldValue('catalogCode', props.extrinsicInfoDetail.catalogCode);
      formRef.current?.setFieldValue('costCenter', props.extrinsicInfoDetail.costCenter);
      formRef.current?.setFieldValue('legalEntity', props.extrinsicInfoDetail.legalEntity);
    }
  }, [props.extrinsicInfoDetail])
  useImperativeHandle(ref, () => ({
    extrinsicInformationSubmit() {
      if (formRef.current) {
        return formRef.current.values;
      }
    }
  }));
  interface CustomFieldProps {
    field: any;
    form: any;
  }

  const CustomField: React.FC<CustomFieldProps> = ({ field, form, ...props }) => {
    const handleCode = async (e: React.ChangeEvent<HTMLInputElement>) => {
      // Call Formik's onChange first
      field.onChange(e);
      // Then get the new value
      const { name, value } = e.target;
      let catalogCode;
      let costCenter;
      if (name == 'catalogCode') {
        catalogCode = value;
        costCenter = formRef.current?.getFieldProps('costCenter').value
      } else {
        catalogCode = formRef.current?.getFieldProps('catalogCode').value;
        costCenter = value
      }

      if (catalogCode.length == 4 && costCenter.length == 6) {
        console.log(catalogCode, costCenter)
        await extrinsicCode(catalogCode, costCenter).then((response: any) => {
          formRef.current?.setFieldValue('businessUnit', response[1]);
          formRef.current?.setFieldValue('legalEntity', response[0]);
        })
      }
    };

    return <TextField {...field} {...props} onChange={handleCode} />;
  };

  // const { setFieldValue } = useFormikContext();

  // const handleCode = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   setFieldValue(name, value);
  //   console.log(name, value);
  // };

  return (
    <div>
      <Paper className='custom-extrinsicInformation-paper'>
        <Grid container className='custom-extrinsicInformation-container'>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <h1 className='custom-extrinsicInformation-header'>Extrinsic Information</h1>
            </Stack>
          </Grid>
        </Grid>
        <Formik initialValues={initialValues} innerRef={formRef} onSubmit={() => { }}>
          <Form>
            <Grid container pt={1} justifyContent={'center'} style={{ padding: '0rem 10rem 0rem 10rem' }}>
              <Grid item md={5} sm={5} xs={5} py={1} pr={2} textAlign={'end'}>
                <Field component={CustomField} fullWidth size='small' id="catalogCode" name="catalogCode" label="Catalog Code" className='custom-extrinsicInformation-textField' variant="outlined" disabled={!props.isFieldsEnabled} />
              </Grid>
              <Grid item md={5} sm={5} xs={5} py={1} pr={2} textAlign={'center'}>
                <Field as={TextField} fullWidth size='small' id="legalEntity" name="legalEntity" label="Legal Entity" className='custom-extrinsicInformation-textField' variant="outlined" disabled />
              </Grid>
              <Grid item md={5} sm={5} xs={5} py={1} pr={2} textAlign={'start'}>
                <Field component={CustomField} fullWidth size='small' id="costCenter" name="costCenter" label="Cost Center" className='custom-extrinsicInformation-textField' variant="outlined" disabled={!props.isFieldsEnabled} />
              </Grid>
              <Grid item md={5} sm={5} xs={5} py={1} pr={2} textAlign={'start'}>
                <Field as={TextField} fullWidth size='small' id="businessUnit" name="businessUnit" label="Business Unit" className='custom-extrinsicInformation-textField' variant="outlined" disabled />
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Paper>
    </div>
  )
});

export default ExtrinsicInformation
