import { ApiService } from "../../../../service/api.service";


const apiService = new ApiService();
// api for comments history
export const historyComment= async (id:any)=>{
    let result;

    await apiService.getAll(`api/comments/?orderId=${id}`).then((response:any)=>{
        if (response.status_code == 200) {
            result = response.data;   
        }
    })
    .catch(error => {
        console.error('Error occurred:', error);
    });
    return result;
}

