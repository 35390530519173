import { Icon } from '@iconify/react'
import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, List, ListItem, ListItemButton, ListItemText, MenuItem, Paper, Select, TextField } from '@mui/material'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { LocalizationProvider, DatePicker, DateValidationError } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import './log.css'
import { GetByIdLogTable, GetLogTable, handleDownloadLog } from './log.service'

export function LogTable(props: any) {
    const handleShowDetails = (payloadId: any,ObjectId:any) => {
        props.setPayloadDetails('showDetails')
        props.handleGetbyIDLogTable(payloadId,ObjectId)
    }
    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: '#',
            flex: 0
        },
        {
            field: 'payloadId',
            headerName: 'Payload ID',
            flex: 1.3,
            editable: false,
            cellClassName: () => 'custom-width-cell',
            renderCell: (params: any) => {
                const payloadId = params.value.split('@')[0];
                return (
                    <Grid
                        // onClick={() => handleShowDetails(params.row.payloadId,params.row.objectId)}
                        // style={{ cursor: 'pointer', color: '#1976d2 ', fontWeight: 600 }}
                    >
                        {payloadId}
                    </Grid>
                );
            },
        },
        {
            field: 'invoice_id',
            headerName: 'Invoice Id',
            flex: 0.8,
            editable: false,
            cellClassName: () => 'custom-width-cell',
        },
        {
            field: 'comments',
            headerName: 'Comments',
            flex: 1.3,
            editable: false,
            cellClassName: () => 'custom-width-cell',
        },
        {
            field: 'date',
            headerName: 'Date',
            flex: 1,
            editable: false,
            cellClassName: () => 'custom-width-cell',
        },
        // {
        //     field: 'time',
        //     headerName: 'Time',
        //     flex: 1,
        //     editable: false,
        //     cellClassName: () => 'custom-width-cell',
        // },
        {
            field: 'updatedBy',
            headerName: 'Updated By',
            flex: 1,
            editable: false,
            cellClassName: () => 'custom-width-cell',
        },
        {
            field: 'payloadStatus',
            headerName: 'Status',
            type: 'number',
            flex: 1,
            editable: false,
            cellClassName: () => 'custom-width-cell',
            renderCell: (params: any) => {
                const value = params.row.payloadStatus?.toString()?.toLowerCase();
                const color = value === 'rejected' ? '#D1293D' : value === 'accepted' ? '#00B929' : '#5A6376';
                const backgroundColor = value === 'rejected' ? '#FFEDEF' : value === 'accepted' ? '#EDFFF6' : '#E9EDF5';
                const width = value === 'rejected' ? '85px' : value === 'accepted' ? '84px' : '150px';
                return (
                    <>
                        {params.row.payloadStatus &&
                            <Paper style={{ color, backgroundColor, width }} className='custom-status-paper'><span><Icon icon="mdi:square-rounded" className='custom-status-icon' /></span> {params.row.payloadStatus}</Paper>
                        }
                    </>
                )
            },
        },
            {
                field: 'action',
                headerName: 'Action',
                flex: 1,
                editable: false,
                cellClassName: () => 'custom-width-cell',
                renderCell: (params: any) => {
                    return (
                        <>
                        <Icon icon="mdi:eye" color="#1976d2" width="25" height="25" style={{cursor:'pointer'}} onClick={() => handleShowDetails(params.row.payloadId,params.row.objectId)} />
                       
                        </>
                    );
                },
            },
    ];
    return { columns }
}
export default function Log() {
    const initialStartDate = dayjs('2024-01-31');
    const [filterMonth, setFilterMonth] = useState('fiveDays');
    const [filterStatusData, setFilterStatusData] = useState('all');
    const [filterFromDate, setFilterFromDate] = useState<any>(null);
    const [filterToDate, setFilterToDate] = useState<any>(null);
    const [filterDatePicker, setFilterDatePicker] = useState('');
    const [logData, setLogData] = useState<any[]>([]);
    const [rowCount, setRowCount] = useState();
    const [payloadDetails, setPayloadDetails] = useState<any>('');
    const [fromDateError, setFromDateError] = useState('');
    const [toDateError, setToDateError] = useState('');    
    const [payloadData, setPayloadData] = useState<any>([]);
    const [listShow, setListShow] = useState<any>(false);
    const [maxDate, setMaxDate] = useState<any>(null);
    const [minDate, setMinDate] = useState<any>(null);
    const [tableRecords, setTableRecords] = useState('');
    const [iconText, setIconText] = useState(false);
    const [paginationModel, setPaginationModel] = useState({
      page: 0,
      pageSize: 10
    });
    
    const handlePaginationModelChange = (newPaginationModel:any) => {
        setPaginationModel(newPaginationModel);
      };

    const filterSelectData =[
        { id:1,name:'Last 5 Days',value:'fiveDays'},
        { id:2,name:' Last 10 Days',value:'tenDays'},
        { id:3,name:' Last 15 Days',value:'fifteenDays'},
        { id:4,name:'custom',value:'custom'},
    ]
    const filterSelectStatus =[
        { id:1,name:'All',value:'all'},
        { id:2,name:'Rejected',value:'Rejected'},
        { id:3,name:'Accepted',value:'Accepted'},
    ]
    const handleChange = (event: any) => {
        setFilterToDate(null)
        setFilterFromDate(null);
        setPayloadDetails('')
        const filterData = event.target.value;
        let report = ''
        let ObjectId=''
        let filterFromDate = ''
        let filterToDate = ''
        let payloadId = '' 
        let page_size = paginationModel.pageSize
        let page_number =paginationModel.page        
        setFilterMonth(filterData);
        if(filterData == 'custom'){
            setFilterDatePicker('custom')
        }else{
            setFilterDatePicker('auto')
        }
        if(filterData !== 'custom'){
            
            GetByIdLogTable(filterData,payloadId,filterFromDate,filterToDate,report,page_size,page_number,ObjectId,filterStatusData).then((res:any)=>{
                setTableRecords('Loading...')
                if(res.status_code == 200){                    
                    if(res.body.length > 0){
                        setLogData(res.body)
                        setRowCount(res.totalCount)
                    }else{
                        setLogData(res.body)
                        setRowCount(res.totalCount)
                        setTableRecords('No Records Found...')
                    }
                }else{
                    setLogData([])
                    setTableRecords('No Records Found...')
                }
            })
        }
    };
    const handleStatusChange = (event: any) => {
        setPayloadDetails('')
        // setFilterToDate(null)
        // setFilterFromDate(null);
        const filterStatusData = event.target.value;
        let report = ''
        let ObjectId=''
        let payloadId = ''
        let page_size = paginationModel.pageSize
        let page_number =paginationModel.page        
        setFilterStatusData(filterStatusData);
        // setFilterDatePicker('status')
               
            GetByIdLogTable(filterMonth,payloadId,filterFromDate,filterToDate,report,page_size,page_number,ObjectId,filterStatusData).then((res:any)=>{
                setTableRecords('Loading...')
                if(res.status_code == 200){                    
                    if(res.body.length > 0){
                        setLogData(res.body)
                        setRowCount(res.totalCount)
                    }else{
                        setLogData(res.body)
                        setRowCount(res.totalCount)
                        setTableRecords('No Records Found...')
                    }
                }else if(res.status_code == 500){
                    setLogData([])
                    setTableRecords('No Records Found...')
                }else{
                    setLogData([])
                    setTableRecords('No Records Found...')
                }
            })
        
    };
    const handleFilterFromDate = (date: any) => {
        const filterFromDate = dayjs(date).format('YYYY-MM-DD');
        const minDate = dayjs(filterFromDate);
        setMinDate(minDate)
        const maxDate = dayjs(date).add(29, 'days').format('YYYY-MM-DD')
        const maxDateFormatted = dayjs(maxDate);
        setMaxDate(maxDateFormatted);
        setFilterFromDate(filterFromDate);
        setFromDateError('');
    };
    const handleFilterToDate = (date: any) => {
        const filterToDate =  dayjs(date).format('YYYY-MM-DD');
        setFilterToDate(filterToDate);
        setToDateError('');
    };
   
    const handeFilter = async () => {
        let filterData = filterMonth
        let report = ''
        let payloadId = ''
        let ObjectId =''
        let page_size = paginationModel.pageSize
        let page_number =paginationModel.page
        if (filterMonth === 'custom') {
            if (!filterFromDate) {
              setFromDateError('Please select From Date');
            }
            if (!filterToDate) {
              setToDateError('Please select To Date');
            }
      
            if (filterFromDate && filterToDate) {                             
              GetByIdLogTable(filterData,payloadId,filterFromDate,filterToDate,report,page_size,page_number,ObjectId,filterStatusData).then((res:any)=>{
                setTableRecords('Loading...')
                if(res.status_code == 200){
                    if(res.body.length > 0){
                        setLogData(res.body)
                        setRowCount(res.totalCount)
                    }else{
                        setLogData(res.body)
                        setRowCount(res.totalCount)
                        setTableRecords('No Records Found...')
                    }
                }else{
                    setLogData([])
                    setTableRecords('No Records Found...')
                }
              })
            }
          } else {
            GetByIdLogTable(filterData,payloadId,filterFromDate,filterToDate,report,page_size,page_number,ObjectId,filterStatusData).then((res:any)=>{
                if(res.status_code == 200){
                    if(res.body.length > 0){
                        setLogData(res.body)
                        setRowCount(res.totalCount)
                    }else{
                        setLogData(res.body)
                        setRowCount(res.totalCount)
                        setTableRecords('No Records Found...')
                    }
                }else{
                    setLogData([])
                    setTableRecords('No Records Found...')
                }
            })
          }
    };
    const handleGetbyIDLogTable=(payloadId:any,ObjectId:any)=>{
        let filterData = filterMonth
        let report = ''
        let page_size = paginationModel.pageSize
        let page_number =paginationModel.page
        GetByIdLogTable(filterData,payloadId,filterFromDate,filterToDate,report,page_size,page_number,ObjectId,filterStatusData).then((res:any)=>{
            if(res.body.length > 0){
                setPayloadData(res.body[0])
            }else{
                setPayloadData([])
            }
        })
    }
    const logDataValue ={
        'setPayloadDetails':setPayloadDetails,
        'handleGetbyIDLogTable':handleGetbyIDLogTable,
    }
    const logColumns = LogTable(logDataValue)

    const fetchData =() =>{
        let page_size = paginationModel.pageSize
        let page_number =paginationModel.page        
        GetLogTable(filterMonth,page_size,page_number,filterFromDate,filterToDate,filterStatusData).then((res:any)=>{
            if(res.status_code == 200){
                if(res.body.length > 0){
                    setLogData(res.body)
                    setRowCount(res.totalCount)
                }else{
                    setLogData(res.body)
                    setRowCount(res.totalCount)
                    setTableRecords('No Records Found...')
                }
            }else  if(res.status_code == 500){
                setLogData([])
                setTableRecords('No Records Found...')
            }else{
                setLogData([])
                setTableRecords('No Records Found...')
            }
        })
    }

    useEffect(()=>{
        fetchData()
    },[paginationModel])
   const handleListShow = () =>{
    setListShow(true)
   }
   const handleDownload = async () =>{
    let filterData = filterMonth
    let report = 'report'
    let payloadId = ''
    let page_size = paginationModel.pageSize
    let page_number =paginationModel.page
    let ObjectId = ""
    // let status =""
    let result = await handleDownloadLog(filterData,payloadId,ObjectId,filterFromDate,filterToDate,report,page_size,page_number,filterStatusData)    
   }
    const handleReload = () => {
        setPayloadDetails('')
        setFilterToDate(null)
        setFilterFromDate(null);
        setFilterStatusData('all')
        const fiveDays ='fiveDays'
        const status ='all'
        const filterFromDate = '';
        const filterToDate = '';
        let page_size = paginationModel.pageSize
        let page_number =paginationModel.page
        setFilterMonth(fiveDays);
        setFilterDatePicker('')
        GetLogTable(fiveDays,page_size,page_number,filterFromDate,filterToDate,status).then((res:any)=>{
            if(res.status_code == 200){
                if(res.body.length > 0){
                    setLogData(res.body)
                    setRowCount(res.totalCount)
                }else{
                    setLogData(res.body)
                    setRowCount(res.totalCount)
                    setTableRecords('No Records Found...')
                }
            }else  if(res.status_code == 500){
                setLogData([])
                setTableRecords('No Records Found...')
            }else{
                setLogData([])
                setTableRecords('No Records Found...')
            }
        })
      }
      const handleCopyClick = () => {
        // Get the text you want to copy
        const textToCopy = payloadData.request_body;

        // Create a textarea element to hold the text temporarily
        const textArea = document.createElement('textarea');
        textArea.value = textToCopy;

        // Append the textarea to the document
        document.body.appendChild(textArea);

        // Select the text inside the textarea
        textArea.select();
        textArea.setSelectionRange(0, 99999); // For mobile devices

        // Copy the selected text
        document.execCommand('copy');

        // Remove the textarea from the document
        document.body.removeChild(textArea);

        // You can add feedback or perform other actions after copying
        if(textToCopy.length > 0){
            setIconText(true)
            setTimeout(() => {
                setIconText(false)
            }, 2000);
        }else{
            setIconText(false)
        }
    };
	
    return (
        <div>
            <Paper className='custom-dashboard-paper' style={{padding:'1rem'}}>
                <Grid container justifyContent={'space-between'}>
                    {/* <Grid item xs={10} sm={10} md={10} lg={10} xl={10} pl={2} >
                        <h1 className='custom-logs-heading-title'>Logs</h1>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} pl={2} pt={1} textAlign={'end'}>
                        <Icon icon="bxs:download" color="rgb(161, 172, 179)" width="25" style={{cursor:'pointer'}} height="25" onClick={handleDownload}/>
                    </Grid>
                    
                </Grid>
                <Grid container columnGap={2}>
                    <Grid item xs={5} sm={3} md={3} lg={2} xl={2}>
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <InputLabel id="demo-select-small-label" >Filter</InputLabel>
                            <Select labelId="demo-select-small-label" id="demo-select-small"  value={filterMonth} onChange={handleChange} label="Filter" size='small' >
                                {Array.isArray(filterSelectData) && filterSelectData.map((month: any) => (
                                    <MenuItem value={month.value}>{month.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={5} sm={3} md={3} lg={2} xl={2}>
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <InputLabel id="demo-select-small-label" >status</InputLabel>
                            <Select labelId="demo-select-small-label" id="demo-select-small" value={filterStatusData} onChange={handleStatusChange} label="Filter" size='small' >
                                {Array.isArray(filterSelectStatus) && filterSelectStatus.map((status: any) => (
                                    <MenuItem value={status.value}>{status.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {filterDatePicker == 'status' &&
                                    <Grid item xs={6} sm={4} md={4} lg={6} xl={6}alignSelf={'center'} >
                                        <Icon icon="ion:reload-circle" className='custom-fulfillment-table-icon' height='20px' color="#5755ff" width='20px' onClick={handleReload} />
                                    </Grid> 
                               }
                    {filterDatePicker == 'custom' &&
                        <>
                            <Grid item xs={5} sm={3} md={3} lg={3} xl={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} size='small'>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker label="From Date" className='dashboard-filter-datepicker' value={filterFromDate} minDate={initialStartDate}
                                         shouldDisableYear={(date) => {
                                            return date && date.isBefore(initialStartDate, 'year');
                                          }}
                                            slotProps={{
                                                textField: {
                                                    helperText:(<span style={{ color: 'red' }}>{fromDateError}</span>),
                                                },
                                            }}
                                         onChange={handleFilterFromDate} />
                                </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                        <Grid item xs={5} sm={3} md={3} lg={3} xl={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} size='small'>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker label="To Date" className='dashboard-filter-datepicker' value={filterToDate} minDate={minDate} maxDate={maxDate} shouldDisableMonth={(date) => {
                                        return date && date.isBefore(minDate, 'month');
                                    }}
                                        shouldDisableYear={(date) => {
                                            return date && date.isBefore(minDate, 'year');
                                        }}
                                        slotProps={{
                                            textField: {
                                                helperText: (<span style={{ color: 'red' }}>{toDateError}</span>),
                                            },
                                        }}
                                        onChange={handleFilterToDate} />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={5} sm={2} md={2} lg={3} xl={2} alignSelf={'center'}>
                            <Grid container alignItems={'center'}>
                                <Grid item xs={6} sm={8} md={8} lg={5} xl={4} textAlign={'center'}>
                                    <Button variant="contained" className='custom-order-btn' onClick={handeFilter}>Filter</Button> &nbsp;
                                </Grid>
                                {(filterDatePicker == 'custom' || filterDatePicker == 'auto' ) &&
                                    <Grid item xs={6} sm={4} md={4} lg={6} xl={6} >
                                        <Icon icon="ion:reload-circle" className='custom-fulfillment-table-icon' height='20px' color="#5755ff" width='20px' onClick={handleReload} />
                                    </Grid>
                                }
                                
                            </Grid>
                        </Grid>
                        </>
                    }
                </Grid>
                <Grid container>
                    <Grid item xs={12}sm={12} md={12} lg={12} xl={12} pt={1}>
                        <Box sx={{ width: '100%' }} >
                            <DataGrid className='custom-logs-data-grid'
                                rowHeight={50}
                                rows={logData}
                                columns={logColumns.columns}
                                paginationMode="server"
                                rowCount={rowCount}
                                paginationModel={paginationModel}
                                pageSizeOptions={[5,10, 20, 50]}
                                onPaginationModelChange={handlePaginationModelChange}
                                disableColumnMenu
                                localeText={{
                                    noRowsLabel: `${tableRecords}`,
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
            {payloadDetails == 'showDetails' &&
                <Paper className='custom-dashboard-paper'>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10} textAlign={'start'} >
                            <h1 className='custom-logs-heading-title'>Payload cXML</h1>
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10} textAlign={'start'}>
                            {/* <Grid container>
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <h1 className='custom-logs-heading'>Payload Id :<span className='custom-logs-subject'>{payloadData.payloadId ? payloadData.payloadId.split('@')[0] : null}</span></h1>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <h1 className='custom-logs-heading'>Invoice Id :<span className='custom-logs-subject'>{payloadData.invoice_id}</span></h1>
                                </Grid>
                                
                            </Grid>
                            <Grid container>
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <h1 className='custom-logs-heading'>Date :<span className='custom-logs-subject'>{payloadData.date}</span></h1>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <h1 className='custom-logs-heading'>Time :<span className='custom-logs-subject'>{payloadData.time}</span></h1>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent={'start'} py={2}>
                                <Grid item xs={1} sm={1} md={1} lg={1} xl={1} textAlign={'start'} >
                                    <h1 className='custom-logs-heading'>CXML</h1>
                                </Grid>
                                <Grid item xs={3} sm={3} md={3} lg={3} xl={3} pt={1}>
                                    <Icon icon="mdi:eye" color="#1976d2" width="25" height="25" onClick={handleListShow} />
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <h1 className='custom-logs-heading'>Comments :<span className='custom-logs-subject'>{payloadData.comments?payloadData.comments:''}</span></h1>
                                </Grid>
                            </Grid> */}
                            {/* {listShow && */}
                                <Grid container justifyContent={'start'} py={2}>
                                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8} >
                                        <List disablePadding sx={{
                                            width: '100%', bgcolor: 'background.paper', position: 'relative', overflow: 'auto', maxHeight: 150, '& ul': { padding: 0 },
                                            border: "1px solid #E5E7EA", borderRadius: "6px", background: "#FFFF"
                                        }}>
                                            <ListItem disablePadding>
                                                <ListItemButton className='newClient'>
                                                    <ListItemText className='programClientName' primary={payloadData.request_body} />
                                                </ListItemButton>
                                            </ListItem>
                                        </List>
                                    </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} >
                                    <Icon icon="mingcute:copy-fill" color="#1976d2"height="25" width="25" style={{cursor:'pointer'}} onClick={handleCopyClick} />
                                    {iconText && 
                                    <Icon icon="typcn:tick" color="#1976d2" />
                                    }
                                </Grid>
                                {/* <Grid item xs={1} sm={1} md={1} lg={1} xl={1} >
                                    {iconText && 
                                    <Icon icon="typcn:tick" color="#1976d2" />
                                    }
                                </Grid> */}
                                </Grid>
                          
                            {/* } */}
                        </Grid>
                    </Grid>
                </Paper>
            }
        </div>
    )
}