import axios from 'axios';
import { saveAuthToken} from './auth.utils';
import Swal from 'sweetalert2';
import axiosInstance from './axios_instance';


const apiUrl = process.env.REACT_APP_API_URL;
export class AuthService {
  public async login(data: any) {
    try {
      // Make API request to authenticate user and receive JWT token
      const response = await axiosInstance.post(apiUrl + 'api/login/',data);
      const token = response.data.access;
      localStorage.setItem('userId', response.data.userid)
      localStorage.setItem('refresh', response.data.refresh)
      localStorage.setItem('is_superuser', response.data.is_superuser)
      localStorage.setItem('username', response.data.username)
      saveAuthToken(token);
      return response;
    } catch (error:any) {
      let errorMessage
      console.log(error)
      // Handle the error here
      if (error.response && error.response.data && error.response.data.detail) {
        errorMessage = error.response.data.detail;
      } else if(error.response && error.response.data){
        errorMessage = error.response.data.message;
      }
      else if(error.response.status == 500){
        errorMessage = 'Internal Server Error'
      }else{
          console.log("An error occurred. Please try again.");
      }
      throw new Error (errorMessage);
    }
  }


  public async logout() {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userId');

    let data = {
      'refresh' : localStorage.getItem('refresh')
    }
    await axiosInstance.post(apiUrl + 'api/logout/',data).then((res:any)=>{
      localStorage.clear();
      console.log("Successfully logged out")
    }).catch((error)=>{
      if (error.response.status == 401) {
        Swal.fire('Token Expired', "Login", 'warning').then(() => { window.location.reload(); });
      }
    });
    }
    
    public isAuthenticated(): boolean {
      const token = localStorage.getItem('authToken');
      if (token) {
        // Decode the token and check its expiration time
        const decodedToken = decodeJwtToken(token)
        const currentTime = Date.now() / 1000;
  
        if (decodedToken && typeof decodedToken === 'object' && decodedToken.exp) {
          const expirationTime = decodedToken.exp as number;
          if (expirationTime > currentTime) {
            return true;
          }
        }
      } else {
        return false;
      }
      return false;
    }
  }
  
  function decodeJwtToken(token: string): { [key: string]: any } | null {
    const parts = token.split('.');
  
    if (parts.length !== 3) {
      return null; // Invalid JWT token
    }
  
    const encodedPayload = parts[1];
    const decodedPayload = atob(encodedPayload);
    const jsonPayload = decodeURIComponent(Array.prototype.map.call(decodedPayload, (c: string) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
  
    try {
      const decodedToken = JSON.parse(jsonPayload);
      return decodedToken;
    } catch (error) {
      return null; // Failed to parse the JSON payload
    }
  }