import React from 'react';
import './order_acks_information.css';
import { Button, Grid, Stack, TextField } from '@mui/material';

interface ExtrinsicDetails {
    name: string;
    businessUnit:string;
    legalEntity:string;
    catalog:string;
    costCenter:string;
}

export default function OrderAcksInformation(props:any) {
    const [extrinsic, setExtrinsic] = React.useState<ExtrinsicDetails>();

    React.useEffect(() => {
        if(props.salesExtrinsicDetails){
            setExtrinsic(props.salesExtrinsicDetails)
        }
      },[props.salesExtrinsicDetails])

    return (
        <div className='custom-order-Information'>
            <Grid container className='custom-Information-container' >
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2}>
                        <Button className='custom-Information-Header'>Extrinsic Information</Button>
                    </Stack>
                </Grid>
            </Grid>
            <Grid container className='custom-Information-TextField-container' >
                <Grid item xs={4} pr={3} textAlign={'end'} py={1}>
                    <TextField fullWidth size='small' id="Catalog" name="Catalog" value={extrinsic?.catalog || ''} label="Catalog Code" className='custom-Information-textField' variant="outlined" disabled />
                </Grid>
                <Grid item xs={4} pr={3} py={1}>
                    <TextField fullWidth size='small' id="Legal" name="Legal" value={extrinsic?.legalEntity || ''} label="Legal Entity" className='custom-Information-textField' variant="outlined" disabled/>
                </Grid>                
            </Grid>
            <Grid container className='custom-Information-TextField-container' >
                <Grid item xs={4} pr={3} textAlign={'end'} py={1}>
                    <TextField fullWidth size='small' id="costCenter" name="costCenter" value={extrinsic?.costCenter || ''} label="Cost Center" className='custom-Information-textField' variant="outlined" disabled />
                </Grid>
                <Grid item xs={4} pr={3} py={1}>
                    <TextField fullWidth size='small' id="businessUnit" name="businessUnit" value={extrinsic?.businessUnit || ''} label="Business Unit" className='custom-Information-textField' variant="outlined" disabled/>
                </Grid>
            </Grid>
        </div>
    )
}
