import AddCustomer from "../add_customer/add_customer.component";
import AddComment from "../add_customer/basic_details/add_comment/add_comment.component";
import OrderTimeline from "../add_customer/basic_details/order_timeline/order_timeline.component";
import './customer_details.css'

export default function CustomerDetails(){
    return(
        <div>
            <div><AddCustomer/></div>
            <div><AddComment/></div>
            <div><OrderTimeline/></div>
        </div>
    )
}