import React, { useState, useRef } from 'react'
import { Grid, InputAdornment, TextField, FormControl, Select, MenuItem, InputLabel, Paper, Button, Modal, Switch, styled, SwitchProps, IconButton, Menu, Backdrop } from '@mui/material';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import './fulfilment_filters.css';
import NewOrder from '../new_order/new_order.component';
import { TableColumnSettingsComponent } from '../../table_settings/table_settings.component';
import { creatAutoStatus, getAutoProcess, proceedOrders, deleteOrder, downloadOrderExcel, downloadOrderPdf } from './fulfillment_api';
import { getCustomerName } from '../new_order/new_order_api';
import { Icon } from '@iconify/react';
import Swal from 'sweetalert2';
import ReactToPrint from 'react-to-print';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';


interface CustomerData {
    id: number;
    customerName: string;
}

export default function FulfilmentFilters(props: any) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openTable, setOpenTable] = React.useState(false);
    const handleOpenTable = () => setOpenTable(true);
    const handleCloseTable = () => setOpenTable(false);
    const [customerList, setCustomerList] = React.useState<CustomerData[]>();
    const [buttonId, setButtonId] = React.useState([]);
    const [isAutoProcessEnabled, setIsAutoProcessEnabled] = React.useState<boolean>();
    const [selectedFromDate, setSelectedFromDate] = useState(null);
    const [selectedToDate, setSelectedToDate] = useState(null);
    const [selectedSearch, setSelectedSearch] = useState<any>(null);
    const textFieldRef = useRef<HTMLInputElement>(null);
    const [reload, setReload] = React.useState(false)
    const [buttonLabel, setButtonLabel] = React.useState([
        { id: 0, name: '#' },
        { id: 1, name: 'Order ID' },
        { id: 2, name: 'Status' },
        { id: 3, name: 'Taylor Order ID' },
        { id: 4, name: 'Taylor Invoice ID' },
        { id: 5, name: 'Invoice ID' },
        { id: 6, name: 'Date' },
        { id: 7, name: 'Vendor' },
        { id: 8, name: 'Customer' },
        { id: 9, name: 'Ship-to Address Lines' },
        { id: 10, name: 'Ship-to State' },
        { id: 11, name: 'Ship-to Zip' },
        { id: 12, name: 'Ship-to Country' },
        { id: 13, name: 'Subtotal ' },
        { id: 14, name: 'Tax' },
        { id: 15, name: 'Total Amount' },
        { id: 16, name: 'Last Updated' }
    ])
    const [data, setData] = React.useState<any[]>([])
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleExcel = () => {
        setAnchorEl(null);
    };
    const handleCloseTableModal = () => {
        setOpenTable(false);
    }
    React.useEffect(() => {
        setButtonId(props.buttonId);
        const fetchData = async () => {
            try {
                // Get Api for auto process
                await getAutoProcess().then(autoProcess => {
                    if (typeof autoProcess === 'boolean') {
                        localStorage.setItem('autoProcess', autoProcess);
                        setIsAutoProcessEnabled(autoProcess);
                    }
                });
                let customerName = await getCustomerName();
                setCustomerList(customerName)
            } catch (error) {
                console.error('Error occurred:', error);
            }
        };
        fetchData();
    }, [props.buttonId]);
    const handleButtonId = (buttonId: any) => {
        props.handleButtonId(buttonId)
    }
    const handleAutoProcess = async (event: any, isChecked: any) => {
        setIsAutoProcessEnabled(isChecked);
        let response = await creatAutoStatus(isChecked)
    }
    const handleFromDateChange = (date: any) => {
        setReload(true)
        props.setData([])
        setSelectedFromDate(date);
        const formattedDate = dayjs(date).format('YYYY-MM-DD');
        // props.handleFilterData(formattedDate, 'from');
        props.handleFilterData(formattedDate, 'from', formattedDate, selectedToDate);
    };
    const handleToDateChange = (date: any) => {
        setReload(true)
        props.setData([])
        setSelectedToDate(date);
        const formattedDate = dayjs(date).format('YYYY-MM-DD');
        // props.handleFilterData(formattedDate, 'to');
        props.handleFilterData(formattedDate, 'to', selectedFromDate, formattedDate);

    };
    const handleReload = () => {
        setReload(false)
        setSelectedFromDate(null); 
        setSelectedToDate(null); 
        setSelectedSearch('')   
        if (textFieldRef.current) {
            textFieldRef.current.value = ''; // Clear the value of the TextField using the ref (if it exists)
        } 
        props.setData([]) 
        props.handleFilterData('null', 'from');  
        props.handleFilterData('null', 'to');  
        props.handleFilterData('null', 'search');
    };
    const handleCustomerChange = (event: any) => {
        setReload(true)
        props.setData([])
        props.handleFilterData(event.target.value);
    };
    const handleSearch = (event: any) => {
        const searchQuery = event.target.value;
        if (event.target.value == '' && selectedToDate == null && selectedFromDate == null){
            setReload(false);
        }else{
            setReload(true)
        }
        
        setSelectedSearch(event.target.value);
        props.setData([])
        // props.handleFilterData(event.target.value, 'search');
        props.handleFilterData(searchQuery, 'search', selectedFromDate, selectedToDate);
    };
    // Get Api for Download Excel
    const handleDownloadExcel = async () => {
        console.log(props.tableColumns)
        const data = props.tableColumns
        const namesOnly = data.map((item: { name: any; }) => item.name);
        let result = await downloadOrderExcel(props.selectedRowData, props.selectedFromDate, props.selectedToDate, props.selectedCustomer, props.selectedSearch, props.tabValue, namesOnly, props.sort);
        setAnchorEl(null);
    }
    // Get Api for Download Pdf
    const handleDownloadPdf = async () => {
        let result = await downloadOrderPdf(props.selectedRowData);
        setAnchorEl(null);
    }
    // Get Api for Proceed
    const handleProceed = async () => {
        var data = props.selectedRowData
        await proceedOrders(data).then((response) => {
            // setLoading(false)
            Swal.fire( {title:'Selected Orders are Proceeding',text:response,allowOutsideClick:false}).then(() => { window.location.reload(); });
        });
    }
    // Get Api for Delete
    const handleOrderDelete = async () => {
        Swal.fire({
            title: 'Delete Order',
            text: 'Are you sure you want to delete this order?',
            // icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            allowOutsideClick:false
        }).then(async result => {
            if (result.isConfirmed) {
                await deleteOrder(props.selectedRowData).then((response) => {
                    Swal.fire({title:'Deleted!', text:response,allowOutsideClick:false}).then(() => { window.location.reload(); });
                });
            }
        });
    }

    // const handlePrint = () => {
    //     const printContents = document.getElementById("divcontents");
    //     if (printContents) {
    //       const originalContents = document.body.innerHTML;

    //       document.body.innerHTML = printContents.innerHTML;
    //       window.print();

    //       document.body.innerHTML = originalContents;
    //     } else {
    //       console.error("Element not found.");
    //     }
    //   };

    // const componentRef = useRef();

    return (
        <div>
            <Grid container>
                <Grid item xs={6}>
                    {/* <h1 className='custom-fulfillment-header'>Fulfillment</h1> */}
                </Grid>
                <Grid item xs={6} textAlign={'right'} px={2} py={2}>
                    <Grid container justifyContent={'end'}>
                    {localStorage.is_superuser === 'true' && (
                        <Grid item style={{ paddingRight: '3px' }}>
                            <Grid container >
                                <Grid item >
                                    <p className='custom-fulfillment-AutoProcess'>Auto Order Process</p>
                                </Grid>
                                <Grid item >
                                    <IOSSwitch onChange={handleAutoProcess} checked={isAutoProcessEnabled} />
                                </Grid>
                            </Grid>
                        </Grid>
                     )}
                        <Grid item style={{ paddingRight: '3px' }}>
                            <ReactToPrint
                                trigger={() => <Icon icon="ion:print" color="#a1acb3" width="24" height="24" className='custom-icon' />}
                                content={() => props.selectedComponentRef}
                            />
                        </Grid>
                        <Grid item style={{ paddingRight: '3px' }}>
                            {/* <FileDownloadRoundedIcon onClick={handleDownload} className='custom-icon' /> */}
                            {auth && (
                                <div>
                                    <IconButton style={{ padding: '0px'}}
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleMenu}
                                        color="inherit"
                                        className='custom-download-icon-button'
                                    >
                                        <Icon icon="ic:file-download" color="#a1acb3" width="24" height="24" className='custom-icon' />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorEl)}
                                        onClose={handleExcel}
                                    >
                                        <MenuItem onClick={handleDownloadExcel}>Export as xlsx</MenuItem>
                                        {/* <MenuItem onClick={handleDownloadPdf}>Export as PDF</MenuItem> */}
                                    </Menu>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container px={2} pb={1} className='custom-fullfillment-filter-row'>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                    <Grid container spacing={1} className='custom-fullfillment-filter-grid'>
                        <Grid item  >
                            <div className='custom-fulfillment-icon' onClick={handleOpenTable}>
                                <Icon icon="majesticons:table" color="#464f60" className='custom-fulfillment-table-icon' height='20px' width='20px' onClick={handleOpenTable} />
                            </div>
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={3}>
                            <FormControl fullWidth>
                                <TextField className='custom-text-field' onChange={handleSearch}value={selectedSearch} inputRef={textFieldRef}  placeholder='Search'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon icon="carbon:search" height='15px' width='15px' color="#868fa0" className='search-icon' />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={2} xs={3} md={3} sm={3}  >
                            <FormControl fullWidth>
                                <Select className='custom-select-field' defaultValue={1} onChange={handleCustomerChange}>
                                    {Array.isArray(customerList) && customerList.map((customer: any) => (
                                        <MenuItem key={customer.id} value={customer.id}>{customer.customerName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker onChange={handleFromDateChange} value={selectedFromDate} className='custom-date-picker' />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker onChange={handleToDateChange} value={selectedToDate} minDate ={selectedFromDate} className='custom-date-picker' />
                            </LocalizationProvider>
                            
                        </Grid>
                        {(reload)?
                        <Grid  style={{margin:'10px 0px 0px 8px'}}>
                        <Icon icon="ion:reload-circle"  className='custom-fulfillment-table-icon' height='20px' color="#5755ff" width='20px' onClick={handleReload} />
                       </Grid>
                        :''}
                        
                    </Grid>
                </Grid>
                <Grid item lg={3} md={4} sm={3} xs={3}>
                    <Grid container spacing={2} justifyContent={'right'}>
                        <Grid item lg={3} md={3} sm={3} xs={3} textAlign={'center'}>
                            <Grid container justifyContent={'end'} >
                                <Grid item xs={2} textAlign={'center'} className='custom-fullfillment-tabicon'>
                                    <Grid container  >
                                        <Grid item style={{ paddingTop: '4px' }} pr={1}>
                                            {(props.deleteDisable) ? <Icon icon="ic:baseline-delete" onClick={handleOrderDelete} style={{cursor:'pointer'}} color="red" width="20" height="20" /> : ''}
                                        </Grid>
                                        <Grid item>
                                            {(props.deleteDisable) ? 
                                            <div>
                                                {/* {
                                            (loading) ?
                                                  <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} style={{ color: 'white', backgroundColor: '#5755FF',padding:'1px 9px',textTransform:'initial' }} variant="contained" > Loading </LoadingButton>
                                                : */}
                                            <Button variant="contained" className='custom-fullfilmentProceed-btn' onClick={handleProceed}>Proceed</Button> 
                                              {/* }            */}
                                            </div>
                                            : ''}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={6} md={6} sm={8} xs={8} textAlign={'center'}>
                            <Button variant="contained" className='custom-order-btn' onClick={handleOpen}>
                                <Icon icon="material-symbols:add" height='17px' width='17px' color="white" />&nbsp; New Order</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Modal open={open} onClose={handleClose} BackdropComponent={Backdrop}
            BackdropProps={{ style: { pointerEvents: 'none' },  }}>
                <div>
                    <NewOrder handleClose={handleClose} />
                </div>
            </Modal>

            <Modal open={openTable} onClose={handleCloseTable}>
                <div>
                    <TableColumnSettingsComponent buttonLabel={buttonLabel} handleCloseTableModal={handleCloseTableModal} handleButtonId={handleButtonId} type={'fufillment'} buttonId={buttonId} dateFormat={true} />
                </div>
            </Modal>
        </div>
    )
}


    const IOSSwitch = styled((props: SwitchProps) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 36,
        height: 19,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 15,
            height: 15,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));
