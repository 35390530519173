import React from "react";
import MUIDataTable from "mui-datatables";
import { Icon } from '@iconify/react';
import { Link, Paper } from "@mui/material";
import './customer.css'


export default function CustomerListDetails(props: any) {
  const allColumns = [
    {
      name: "#", options: {
        setCellProps: () => ({ style: { width: '50px' } }),
        filter: true,
        sort: true,
      }
    },
    {
      name: "CUSTOMER Name", options: {
        customBodyRender: (params: any) => {
          return <a href="/register" className="custom-link-order">{params}</a>
        },
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { width: '150px' } })
      }
    },
    {
      name: "CUSTOMER ID", options: {
        setCellProps: () => ({ style: { width: '130px' } }),
        filter: true,
        sort: true,
      }
    },
    {
      name: "CUSTOMER PROGRAM", options: {
        setCellProps: () => ({ style: { width: '180px' } }),
        filter: true,
        sort: true,
      }
    },
    {
      name: "LOCATION", options: {
        setCellProps: () => ({ style: { width: '100px' } }),
        filter: true,
        sort: true,
      }
    },
    {
      name: "STATUS", options: {
        customBodyRender: (params: any) => {
          const value = params?.toString()?.toLowerCase();
          const color = value === 'active' ? '#14804A' : 'red';
          const backgroundColor = value === 'active' ? '#E1FCEF' : '#FFEDEF';
          const width = value === 'active' ? '70px' : '85px';
          return <Paper style={{ color, backgroundColor, width }} className='custom-status-paper'><span><Icon icon="mdi:square-rounded" className='custom-status-icon' /></span> {params}</Paper>;
        },
        setCellProps: () => ({ style: { width: '140px' } }),
        filter: true,
        sort: true,
      }
    },
    {
      name: "POC", options: {
        setCellProps: () => ({ style: { width: '80px' } }),
        filter: true,
        sort: true,
      }
    },
    {
      name: "EMAIL ID", options: {
        setCellProps: () => ({ style: { width: '160px' } }),
        filter: true,
        sort: true,
      }
    }
  ];
  const data = [
    [1, 'Taylor', '098765432345678', 'Program 1', 'US', 'Active', 'Antony', 'antony@gmail.com'],
    [2, 'Taylor', '098765432345678', 'Program 1', 'US', 'Active', 'Antony', 'antony@gmail.com'],
    [3, 'Taylor', '098765432345678', 'Program 1', 'US', 'Active', 'Antony', 'antony@gmail.com'],
    [4, 'Taylor', '098765432345678', 'Program 1', 'US', 'Active', 'Antony', 'antony@gmail.com'],
    [5, 'Taylor', '098765432345678', 'Program 1', 'US', 'Inactive', 'Antony', 'antony@gmail.com'],
    [6, 'Taylor', '098765432345678', 'Program 1', 'US', 'Inactive', 'Antony', 'antony@gmail.com'],
    [7, 'Taylor', '098765432345678', 'Program 1', 'US', 'Inactive', 'Antony', 'antony@gmail.com'],
    [8, 'Taylor', '098765432345678', 'Program 1', 'US', 'Inactive', 'Antony', 'antony@gmail.com'],
    [9, 'Taylor', '098765432345678', 'Program 1', 'US', 'Inactive', 'Antony', 'antony@gmail.com']
  ];

  const [visibleColumns, setVisibleColumns] = React.useState<number[]>([]);

  React.useEffect(() => {
    if (props.buttonId == null) {
      setVisibleColumns([0, 1, 2, 3, 4, 5, 6, 7]);
    } else {
      setVisibleColumns(props.buttonId);
    }
  }, [props.buttonId]);

  const columns = allColumns.filter((column, index) => visibleColumns.includes(index));
  const filteredData = data.map(row => row.filter((_, index) => visibleColumns.includes(index)));

  const options = {
    filter: true,
    onFilterChange: (changedColumn: any, filterList: any) => {
    },
    rowsPerPage: 5,
    expandableRows: false,
    page: 0,
    responsive: "scroll",
    rowsPerPageOptions: [5, 10, 25, 50, 100]

  };

  return (
    <div className="custom-customer-table">
      <MUIDataTable
        title={"Order Details"}
        data={filteredData}
        columns={columns}
        options={options as any}
      />
    </div>
  );
};

