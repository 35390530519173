import axios from "axios";
import { ApiService } from "../../../service/api.service";
import Swal from 'sweetalert2';
import { convertToObject } from "typescript";
import axiosInstance from "../../../service/axios_instance";

const apiService = new ApiService();
const apiUrl = process.env.REACT_APP_API_URL;

// Api for add item
export const createItem = async (data: any) => {
  let result;
  await apiService.add('upload/items/', data).then((response: any) => {
    if (response.status_code == 200) {
      result = response.message
      result = {
        'status': response.status_code,
        'message': response.message
      }
    }
  })
    .catch(error => {
      console.error('Error occurred:', error);
      if (error.response.data.status_code == 409) {
        result = {
          status: error.response.data.status_code,
          message: error.response.data.message,
        };
      }
      if (error.response?.status_code == 500) {
        result = {
          status: error.response.data.status_code,
          message: error.response.data.message,
        };
      }
      if (error.response.status == 500) {
        result = {
          status: error.response.status,
          message: error.response.statusText,
        };
      }
      if (error.response.status == 401) {
        //apiService.tokenExpired();
      }
    });
  return result;
}

// Api for getting all the item type
export const getItemType = async () => {
  let result;
  await apiService.getAll('upload/itemtype/').then((response: any) => {
    if (response.status_code == 200) {
      result = response.data
    }
  })
    .catch(error => {
      console.error('Error occurred:', error);
      if (error.response.status == 401) {
        //apiService.tokenExpired();
      }
    });
  return result;
}

// Api for getting all the item unit
export const getItemUnit = async () => {
  let result;
  await apiService.getAll('upload/itemunit/').then((response: any) => {
    if (response.status_code == 200) {
      result = response.data
    }
  })
    .catch(error => {
      console.error('Error occurred:', error);
      if (error.response.status == 401) {
        //apiService.tokenExpired();
      }
    });
  return result;
}

// Api for getting all the supplier item
export const getItemSupplier = async () => {
  let result;
  await apiService.getAll('api/supplierEntity/').then((response: any) => {
    if (response.status_code == 200) {
      result = response.data
    }
  })
    .catch(error => {
      console.error('Error occurred:', error);
      if (error.response.status == 401) {
        //apiService.tokenExpired();
      }
    });
  return result;
}

// Api for getting the items by id
export const getItemById = async (id: any, type: any) => {
  let result;
  let url;
  if (type == 'resubmission') {
    url = `upload/items/?itemName=${id}`
  } else {
    url = `upload/items/?id=${id}`
  }
  await apiService.getAll(url).then((response: any) => {
    if (response.status_code == 200) {
      result = {
        "itemName": response.data.itemName,
        "itemId": response.data.itemId,
        "commodityCode": response.data.commodityCode,
        "description": response.data.description,
        "itemTypeId": response.data.itemTypeDetails.itemTypeId,
        "supplier": response.data.supplierDetails.supplierId,
        "uomId": response.data.itemUnitDetails.itemUnitId,
        "unitPrice": response.data.unitPrice,
        "SKU": response.data.SKU
      }
    }
  })
    .catch(error => {
      console.error('Error occurred:', error);
      if (error.response.status == 401) {
        //apiService.tokenExpired();
      }
    });
  return result;
}

// Api for updating the item
export const updateItem = async (id: any, data: any) => {
  let result;
  await apiService.update(`upload/items/?id=${id}`, data).then((response: any) => {
    if (response.status_code == 200) {
      result = response.message
    }
  })
    .catch(error => {
      console.error('Error occurred:', error);
      result = error.response.data.message
      if (error.response.status == 401) {
        //apiService.tokenExpired();
      }
    });
  return result;
}

// Api for add the item unit
export const createItemUnit = async (data: any) => {
  let result;
  await apiService.add('upload/itemunit/', data).then((response: any) => {
    if (response.status_code == 200) {
      result = {
        'status': response.status_code,
        'message': response.message
      }
    }
  })
    .catch(error => {
      console.error('Error occurred:', error);
      result = {
        'status': error.response.data.status_code,
        'message': error.response.data.message
      }
      // result = error.response.data.message
      if (error.response.status == 401) {
        //apiService.tokenExpired();
      }
    });
  return result;
}

// export const deleteItem = async (data:any) => {
//   let result;
//   console.log('data',data);
//   await apiService.delete(`${apiUrl}upload/bulkDeleteItems/`, {
//     data: {
//         orderId: orderId
//     }
//   }).then((response:any) => {
//    if(response.status_code == 200){
//       result = response.message
//    }
//   })
//   .catch(error => {
//     console.error('Error occurred:', error);
//     if(error.response.status == 401){
//       Swal.fire('Token Expired',"Login", 'warning').then(() => {window.location.reload();});
//   }
//   });
// return result;
// }

// Api for delete the items in bulk
export const deleteItem = async (data: any) => {
  let result;
  await axiosInstance.delete(`${apiUrl}upload/bulkDeleteItems/`, {
    data: {
      itemId: data
    }
  }).then((response: any) => {
    if (response.status == 200) {
      result = response.data.message
    }
  })
    .catch(error => {
      console.error('Error occurred:', error);
      if (error.response.status == 401) {
        //apiService.tokenExpired();
      }
    });

  return result;
}

// Api for download the item report by csv file
export const downloadItemCsv = async (id: any,columnsName:any) => {
  try {
    const dataQueryParam = encodeURIComponent(columnsName.join(','));
    const response = axiosInstance.get(`${apiUrl}upload/itemReport/?orderId=${id}&columnsName=${dataQueryParam}`, { responseType: 'arraybuffer' });
    const url = window.URL.createObjectURL(new Blob([(await response).data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'item_report.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // window.location.reload()
  } catch (error:any) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data) {
        const arrayBuffer = await new Response(error.response.data).arrayBuffer();
        const textDecoder = new TextDecoder('utf-8');
        const decodedString = textDecoder.decode(arrayBuffer);
        const json = JSON.parse(decodedString);
          if(json.status_code == 400){                
          Swal.fire({text:json.message,allowOutsideClick:false})
          }
      } else {
        console.error('Error downloading the file:', error.response?.status);
      }
    } else {
      if(error.response.status == 401){
        // apiService.tokenExpired()
      }
    }
  }
}

// Api for download the item report by pdf file
export const downloadItemPdf = async (id: any) => {
  try {
    const response = axiosInstance.get(`${apiUrl}upload/itemReport/?orderId=${id}`, { responseType: 'arraybuffer' });
    const url = window.URL.createObjectURL(new Blob([(await response).data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'item_report.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error: any) {
    console.error('Error occurred:', error);
    if (error.response.status == 401) {
      //apiService.tokenExpired();
    }
  }
}

//upload item file
export const itemsFileUpload = async (file: any) => {
  let result;
  try {
    let response = await apiService.add(`upload/upload_xlsx_file/`, file).then(res => {
      result = {
        'status': res.status_code,
        'message': res.message
      }
    })
  }
  catch (error: any) {
    console.error('Error occurred:', error);
    result =
    {
      'status': error.response.data.status_code,
      'message': error.response.data.message
    }
    if (error.response.status == 401) {
      //apiService.tokenExpired();
    }
  }
  return result;
}