import { Paper, Box } from "@mui/material";
import './customer.css';
import CustomerFilters from "../../components/customer/customer.component";
import CustomerListDetails from "../../components/tables/customer/customer.component";

export default function Customer(props: any) {
    return (
        <Box>
            <Paper className="custom-paper-outline">
                <div><CustomerFilters /></div>
                <div><CustomerListDetails /></div>
            </Paper>
        </Box>
    )
}