import { ApiService } from "../../../../service/api.service";

const apiService = new ApiService();
// Api for comments save
export const saveComment = async (data: any, id: any,orderType:any) => {
    let result;
    let details = {
        "userId": id,
        "orderId": id,
        "type": orderType,
        "time": new Date().toLocaleTimeString(),
        "date": new Date().toLocaleDateString(),
        "commentInfo": data
    }
    await apiService.add('api/comments/', details).then((response: any) => {
        if (response.status_code == 200) {
            result = response.status_code
        }
    })
        .catch(error => {
            console.error('Error occurred:', error);
        });
    return result;
}
// Api for Get comments
export const getComment= async (id:any,orderType:any)=>{
    let result;

    await apiService.getAll(`api/comments/?type=${orderType}&orderId=${id}`).then((response:any)=>{
        if (response.status_code == 200) {
            result = response.data;   
        }
    })
    .catch(error => {
        console.error('Error occurred:', error);
    });
    return result;
}