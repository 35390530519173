import { ApiService } from "../../service/api.service";

const apiService = new ApiService();
// ************ Api for dashboard count **************
export const forgotPassword = async (data: any, type:any) => {
    let result;
    let url:any;
    if (type=='email'){
        url = 'api/password-reset/'
    }else if(type == 'otp'){
        url = 'api/validate-otp/'
    }else{
        url = 'api/update-password/'
    }
    await apiService.add(url, data).then((response: any) => {
        if(response.status_code == 200){
            result ={
                'status' : 200,
                'message' : response.message
            } 
        }
    })
        .catch(error => {
            console.error('Error occurred:', error);
            if(error.response.status == 400){
                result ={
                    'status' : 400,
                    'message' : error.response.data.message
                } 
            }else{
                result ={
                    'status' : 500,
                    'message' : 'Internal Server Error'
                } 
            }
        });
    return result;
}