import './payment_term.css'
import { Button, Grid, Paper, Stack, TextField } from '@mui/material'
import { Icon } from '@iconify/react'
import { Formik, Form, Field, FieldArray } from 'formik'
import React, { forwardRef, useImperativeHandle } from 'react'
import { AnyPtrRecord } from 'dns'

interface ChildComponentProps {
  handleEditFields: () => void; // Define the 'handleEditFields' property with the correct type
  isFieldsEnabled: boolean; // Update the type if necessary
  paymentDetail: any;
}

export interface ChildComponentRef {
  paymentTermSubmit: () => void;
}

const PaymentTerm = forwardRef<ChildComponentRef, ChildComponentProps>((props, ref) => {
  const formRef = React.useRef<any>(null);

  const initialValues = {
    paymentTerms: [{ netTerm: '', penalty: '', discount: '', percentage1: '', percentage2: '' }],
  };

  useImperativeHandle(ref, () => ({
    paymentTermSubmit() {
      if (formRef.current) {
        return formRef.current.values;
      }
    },
  }));


  React.useEffect(() => {
    if (props.paymentDetail) {
      formRef.current?.setFieldValue('paymentTerms[0].netTerm', props.paymentDetail.netTerm);
      // formRef.current?.setFieldValue('penalty', props.paymentDetail.penalty);
      // formRef.current?.setFieldValue('discount', props.paymentDetail.discount);
      // formRef.current?.setFieldValue('percentage1', props.paymentDetail.percentage1);
      // formRef.current?.setFieldValue('percentage2', props.paymentDetail.percentage2);
    }
  }, [props.paymentDetail])

  const handleSubmit = (values: any) => {

  };

  return (
    <div>
      <Paper className="custom-PaymentTerm-paper">
        <Grid container className="custom-PaymentTerm-container">
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <h1 className="custom-PaymentTerm-header">Payment Term</h1>
            </Stack>
          </Grid>
        </Grid>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} innerRef={formRef}>
          {({ values }) => (
            <Form>
              <FieldArray name="paymentTerms">
                {({ push, remove }) => (
                  <Grid container className="custom-PaymentTerm-container" justifyContent="center" py={2}>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      {values.paymentTerms.map((_: any, index: any) => (
                        <Grid key={index} py={1} container justifyContent="space-evenly">
                          <Grid item lg={3} md={3} sm={3} xs={2}>
                            <Grid container>
                              <Grid item lg={8} md={8} sm={8} xs={9}>
                                <p className="custom-PaymentTerm-bill">Net term (in days)</p>
                              </Grid>
                              <Grid item lg={2} md={2} sm={2} xs={2}>
                                <Field as={TextField} className="custom-PaymentTerm-textfield" name={`paymentTerms[${index}].netTerm`} id={`paymentTerms[${index}].netTerm`} size="small" disabled={!props.isFieldsEnabled} />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item lg={4} md={4} sm={4} xs={3}>
                            <Grid container justifyContent={'center'}>
                              <Grid item lg={9} md={9} sm={9} xs={9} >
                                <p className='custom-PaymentTerm-bill'>Discount or penalty term (days)</p>
                              </Grid>
                              <Grid item lg={2} md={2} sm={2} xs={2}>
                                <Field as={TextField} className="custom-PaymentTerm-textfield" name={`paymentTerms[${index}].penalty`} id={`paymentTerms[${index}].penalty`} size="small" disabled />
                              </Grid>
                            </Grid>
                            <Grid container justifyContent={'center'} pt={1}>
                              <Grid item lg={9} md={9} sm={9} xs={9} >
                                <p className='custom-PaymentTerm-bill'>Discount or penalty term (days)</p>
                              </Grid>
                              <Grid item lg={2} md={2} sm={2} xs={2}>
                                <Field as={TextField} className="custom-PaymentTerm-textfield" name={`paymentTerms[${index}].discount`} id={`paymentTerms[${index}].discount`} size="small" disabled />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item lg={4} md={4} sm={3} xs={3}>
                            <Grid container >
                              <Grid item lg={5} md={5} sm={5} xs={5} textAlign={'right'} >
                                <p className='custom-PaymentTerm-bill'>Percentage (%)</p>
                              </Grid>
                              <Grid item lg={2} md={2} sm={2} xs={2}>
                                <Field as={TextField} className="custom-PaymentTerm-textfield" name={`paymentTerms[${index}].percentage1`} id={`paymentTerms[${index}].percentage1`} size="small" disabled />
                              </Grid>
                            </Grid>
                            <Grid container pt={1}>
                              <Grid item lg={5} md={5} sm={5} xs={5} textAlign={'end'} >
                                <p className='custom-PaymentTerm-bill'>Percentage (%)</p>
                              </Grid>
                              <Grid item lg={2} md={2} sm={2} xs={2} >
                                <Field as={TextField} className="custom-PaymentTerm-textfield" name={`paymentTerms[${index}].percentage2`} id={`paymentTerms[${index}].percentage2`} size="small" disabled />
                              </Grid>
                              {(index != 0) ?
                                <Grid item lg={2} md={4} sm={4} xs={5} textAlign={'end'}>
                                  <Icon icon="ep:remove-filled" style={{ paddingTop: '2px' }} height={20} width={20} color="#E01313" onClick={() => remove(index)} />
                                </Grid> : ''}
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                    {/* <Grid item lg={2} md={2} sm={2} xs={2}>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12} >
                      <Grid container >
                        <Grid item lg={6} md={6} sm={6} xs={6} textAlign={"start"}>
                          <Button className="custom-PaymentTerm-save-btn" onClick={() => push({ netTerm: '', penalty: '', discount: '', percentage1: '', percentage2: '' })} disabled={!props.isFieldsEnabled}>
                            Add
                          </Button>
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"start"}>
                          <Button className="custom-PaymentTerm-cancel-btn" disabled={!props.isFieldsEnabled}
                            onClick={() => {
                              const paymentTerms = formRef.current?.values.paymentTerms || [];
                              paymentTerms.forEach((_: any, index: any) => remove(index));
                            }}>
                            Remove</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid> */}
                  </Grid>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  );
});

export default PaymentTerm;
