import { Paper, Box } from "@mui/material";
import './fulfillment.css';
import FulfilmentFilters from "../../components/fullfillment/fulfillment_filters/fulfilment_filters.component";
import FulfillmentTab from "../../components/fullfillment/fulfillment_tab/fulfillment_tab.component";
import React, { useState } from "react";
import dayjs, { Dayjs } from 'dayjs';

export default function Fulfillment(props: any) {
    const initialButtonId = [0, 1, 2, 3, 4, 5, 6, 15, 16]
    const storedButtonIds = localStorage.getItem('selectedButtonIds');
    const initialButtonIds = storedButtonIds ? JSON.parse(storedButtonIds) : initialButtonId;
    const [selectedFromDate, setSelectedFromDate] = React.useState();
    const [selectedToDate, setSelectedToDate] = React.useState();
    const [selectedSearch, setSelectedSearch] = React.useState();
    const [selectedCustomer, setSelectedCustomer] = React.useState(1);
    const [deleteDisable, setDeleteDisable] = React.useState(false);
    const [orderId, setOrderId] = React.useState()
    const [tabValue, setTabValue] = React.useState()
    const [data, setData] = React.useState<any[] | null>(null)
    const [buttonId, setButtonId] = React.useState<any[]>(initialButtonIds);
    const [tableColumns, setTableColumns] = React.useState<any[]>()
    const [selectedRowData, setSelectedRowData] = useState<number[]>([]);
    const [selectedComponentRef, setSelectedComponentRef] = useState<HTMLDivElement | null>(null);
    const [sort, setSort] = React.useState<any>();
    const componentRef = React.useRef<HTMLDivElement>(null);
    const handleButtonId = (id: any) => {
        setButtonId(id);
    };
    const handleFilterData = (data: any, type: any, fromDate: any, toDate: any) => {
        if (type == 'from'){
            if(selectedRowData.length != 0){
                setSelectedRowData([])
            }
            setSelectedFromDate(data)
        }
        else if (type == 'to'){
            if(selectedRowData.length != 0){
                setSelectedRowData([])
            }
            setSelectedToDate(data)
        }
        else if (type == 'search'){
            if(selectedRowData.length != 0){
                setSelectedRowData([])
            }
            setSelectedSearch(data)
        }         
        else{
            setSelectedCustomer(data)
        }

        if (!Array.isArray(data)) {
            // console.error('Data is not an array:', data);
            return;
        }

        // Filter the data based on selectedFromDate, selectedToDate, and selectedSearch
        const filteredData = data.filter((item: any) => {
            const itemDate = dayjs(item.date).format('YYYY-MM-DD');
            const fromDateValid = !selectedFromDate || dayjs(itemDate).isAfter(dayjs(selectedFromDate), 'day');
            const toDateValid = !selectedToDate || dayjs(itemDate).isBefore(dayjs(selectedToDate).add(1, 'day'), 'day');
            const searchValid = !selectedSearch || item.title.includes(selectedSearch); // You can adjust this condition based on your search requirements
            return fromDateValid && toDateValid && searchValid;
            });
            setSelectedRowData(filteredData);            
    }

    const handleDeleteIcon = (visible: any, length: any) => {
        if (length == 0)
            setDeleteDisable(false);
        else
            setDeleteDisable(visible);
    }
    
    return (
        <Box>
            <Paper className="custom-paper-outline">
            <div><FulfilmentFilters handleButtonId={handleButtonId} setData={setData} handleFilterData={handleFilterData} deleteDisable={deleteDisable} buttonId={buttonId} selectedRowData={selectedRowData} selectedComponentRef={selectedComponentRef} selectedFromDate={selectedFromDate} selectedToDate={selectedToDate} selectedSearch={selectedSearch} selectedCustomer={selectedCustomer} tabValue={tabValue} tableColumns={tableColumns} sort={sort}/></div>
            <div><FulfillmentTab selectedFromDate={selectedFromDate} setData={setData} data={data} selectedToDate={selectedToDate} selectedSearch={selectedSearch} selectedCustomer={selectedCustomer} buttonId={buttonId} setSelectedRowData={setSelectedRowData} handleDeleteIcon={handleDeleteIcon} setSelectedComponentRef={setSelectedComponentRef} setTabValue={setTabValue} setTableColumns={setTableColumns} setSort={setSort} sort={sort}/></div>
            </Paper>
        </Box>
    )
}