import { Paper, Box } from "@mui/material";
import './item.css'
import ItemListComponent from "../../components/items/item_list/item_list.component";
import TableDetails from "../../components/tables/item_list/item_list.component";
import { allColumns, getAllItem } from './item_column'
import React from "react";
import { idID } from "@mui/material/locale";

export default function Item() {
    const [itemList, setItemList] = React.useState<[]>();
    const [table, setTable] = React.useState(false);
    const [buttonId, setButtonId] = React.useState<any[]>([]);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [itemTableColumns, setItemTableColumns] = React.useState<any[]>()
    const [visibleColumns, setVisibleColumns] = React.useState<number[]>([]);

    const [deleteDisable, setDeleteDisable] = React.useState(false);
    const [buttonLabel, setButtonLabel] = React.useState([
        { id: 0, name: '#' },
        { id: 1, name: 'Item Id' },
        { id: 2, name: 'Item Name' },
        { id: 3, name: 'SKU' },
        { id: 4, name: 'Commodity Code' },
        { id: 5, name: 'Item Type' },
        { id: 6, name: 'Item Unit' },
        { id: 7, name: 'Unit Price' },
        { id: 8, name: 'Item Description' },
        { id: 9, name: 'Supplier' }
    ])
    const handleButtonId = (id: any) => {
        setButtonId(id);
        setVisibleColumns(id);
        const dataLst = allColumns.filter((_: any, index: number) =>
            id.includes(index)
        );
        setItemTableColumns(dataLst)
    };

    // Get Api For Item
    React.useEffect(() => {
        if (localStorage.getItem('selectedButtonItem') == null) {
            setButtonId([0, 1, 2,3,4, 5, 6, 7, 8,9])
            setVisibleColumns([0, 1, 2, 5, 6, 7, 8]);
        }
        const fetchData = async () => {
            try {
                let itemList = await getAllItem(searchQuery);
                setItemList(itemList)
                if (itemList) {
                    setTable(true)
                }
            } catch (error) {
                console.error('Error occurred:', error);
            }
        };
        fetchData();
        
    }, [searchQuery]);
    const handleDeleteIcon = (visible: any, length: any) => {
        if (length == 0)
            setDeleteDisable(false);
        else
            setDeleteDisable(visible);
    }
    const [selectedRowData, setSelectedRowData] = React.useState<number[]>([]);

    return (
        <Box>
            <Paper className="custom-paper-outline">
                <div><ItemListComponent buttonId={buttonId} buttonLabel={buttonLabel} serachQuery={setSearchQuery} selectedRowData={selectedRowData} deleteDisable={deleteDisable} handleButtonId={handleButtonId} itemTableColumns={itemTableColumns} /></div>
                {(table) ? <div><TableDetails columns={allColumns} buttonId={buttonId} itemList={itemList} searchQuery={searchQuery} setSelectedRowData={setSelectedRowData} handleDeleteIcon={handleDeleteIcon} setItemTableColumns={setItemTableColumns} dateFormat={false} /></div> : ''}
            </Paper>
        </Box>
    )
}