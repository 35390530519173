import * as React from 'react';
import { styled, useTheme, Theme, CSSObject, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link, Outlet, useLocation } from "react-router-dom";
import './sidebar.css';
import Collapse from '@mui/material/Collapse'
import { Alert, Button, FormControl, Grid, InputAdornment, Menu, MenuItem, OutlinedInput, TextField, Tooltip } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { Icon } from '@iconify/react';
import { AuthService } from '../../service/auth.service';
import Modal from '@mui/material/Modal';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { ApiService } from '../../service/api.service';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Dashboard from '../dashboard/dashboard';
const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #FFFFF',
    boxShadow: 24,
    borderRadius: 4,
    p: 3,
};
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);
const getSidebarStateFromLocalStorage = () => {
    const storedState = localStorage.getItem('sidebarState');
    return storedState ? JSON.parse(storedState) : false; // Default to open if no state is found
};

export default function MiniDrawer() {
    const authService = new AuthService();
    const apiService = new ApiService();
    const theme = useTheme();
    const [open, setOpen] = React.useState(getSidebarStateFromLocalStorage());
    const [openMenu, setOpenMenu] = React.useState(false);
    const [openMenuItem, setOpenMenuItem] = React.useState(false);
    const location = useLocation();
    const [selectedItem, setSelectedItem] = React.useState('');
    const [currentsecretkey, setcurrentsecretkey] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [alert, setAlert] = React.useState<any>();
    const [responseMsg, setResponseMsg] = React.useState();
    const [loading, setLoading] = React.useState<boolean>(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    const logoutRoute = '/files/OMP_User_Guide_Version_1.0-Caracal_Corp.pdf'
    React.useEffect(() => {
        // Api for secret key
        // let is_superuser = localStorage.getItem('is_superuser')
        // if (is_superuser) {
        // var myHeaders = new Headers();
        // myHeaders.append("Authorization", "Bearer " + localStorage.getItem('authToken'));
        // fetch(`${apiUrl}api/getsecretkey/`, {
        // method: 'GET',
        // headers: myHeaders,
        // })
        // .then((response) => response.json())
        // .then((data) => {
        // if (data) {
        // // setcurrentsecretkey(data.secret_key);
        // }
        // })
        // .catch((error) => {
        // console.error('Error fetching data:', error);
        // });
        // }
        localStorage.setItem('sidebarState', JSON.stringify(open));
        if (location.pathname == '/dashboard') {
            setHeading('Dashboard')
        } else if (location.pathname === '/fulfillment') {
            setHeading('Fulfillment')
        } else if (location.pathname === '/item') {
            setHeading('Item')
        } else if (location.pathname === '/addItem') {
            setHeading('Item')
        }else if (location.pathname === '/log/') {
            setHeading('Logs')
        }

        setSelectedItem(location.pathname);
    
    }, [location, open]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const [collapseOpen, setCollapseOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState('');
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [heading, setHeading] = React.useState('');

    const handleListItemClick = (index: number) => {

        if (index == 3) {
            handleClick()
        }
        if (index == 4) {
            handleClickItem()
        }
        if (index == 1) {
            localStorage.removeItem('stage')
        }
        if (index != 4){
            setOpenMenuItem(false)
        }
        setSelectedIndex(index);

        setSelectedValue('/item'); // Set the selected value here
        setCollapseOpen(true);
    };

    function handleClick() {
        setOpenMenu(!openMenu)
    }

    function handleClickItem() {
        setOpenMenuItem(!openMenuItem)
    }

    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    }));

    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: 'inherit',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%'
        },
    }));
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAuth(event.target.checked);
    };

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const handlePdfViewer = () => {
        window.open(logoutRoute)
    }
    const [modalopen, setModalOpen] = React.useState(false);
    const handleModalClose = () => {
        setModalOpen(false);

    }
    const handleGenerateSignup = () => {
        setModalOpen(true)
    };
    const [submitted, setSubmitted] = React.useState(false);
    const ALERT_DURATION = 1000;
    const alertClose = () => {
        setAlert('')
    }

    const initialValues = {
        email: '',
    }

    let validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email format').required('Email is required'),
    });

    const handeleSecretkey = async (values: any, { setSubmitting }: FormikHelpers<any>) => {
        // var myHeaders = new Headers();
        // myHeaders.append("Authorization", "Bearer " + localStorage.getItem('authToken'));
        // fetch(`${apiUrl}api/generatesecretkey/`, {
        // method: 'POST',
        // headers: myHeaders,
        // })
        // .then((response) => response.json())
        // .then((data) => {
        // if (data) {
        // setcurrentsecretkey(data.secret_key);
        // }
        // })
        // .catch((error) => {
        // console.error('Error fetching data:', error);
        // });
        setLoading(true);
        let result;
        const apiService = new ApiService();
        await apiService.add('api/generatesecretkey/', values).then((response: any) => {
            if (response.status_code == 200) {
                setLoading(false);
                setResponseMsg(response.message)
                setAlert('true')
                setTimeout(() => {
                    setAlert('');
                    handleModalClose()
                }, ALERT_DURATION);
            }
        })
            .catch(error => {
                console.error('Error occurred:', error);
                if (error.response.status == 400) {
                    setLoading(false);
                    setResponseMsg(error.response.data.message)
                    setAlert('false')
                    setTimeout(() => {
                        setAlert('');
                        handleModalClose()
                    }, ALERT_DURATION);
                } else {
                    setLoading(false);
                    setResponseMsg(error.response.data.message)
                    setAlert('false')
                    setTimeout(() => {
                        setAlert('');
                        handleModalClose()
                    }, ALERT_DURATION);
                }
            });
        setSubmitting(false)
        console.log(result)
    }

    let username = localStorage.getItem('username')
   
    const logout = async () => {
        try {
            // Api for Logout
            await authService.logout().then(() => {
                localStorage.removeItem('authToken')
                window.location.href = '/login'
            })
        } catch (error) {
            console.error('Error occurred:', error);
        }
    };
    const handleLogs = () => {
        window.location.href = '/log'
    }
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} className='custom-app-bar'>
                <Grid container>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item pl={2} textAlign={'center'}>
                                <IconButton className='custom-download-icon-button'
                                    // color="inherit"
                                    // aria-label="open drawer"
                                    // onClick={handleDrawerOpen}
                                    edge="start"
                                    sx={{
                                        // marginRight: 5,
                                        ...(open && { display: 'none' }),
                                    }}
                                >
                                    <img src="../sidebar/caracal-logo.png" height='30px' width="30px" alt="logo" />
                                </IconButton>

                            </Grid>
                            <Grid item >
                                <Toolbar className='custom-tool-bar' style={{ paddingLeft: '5px', paddingRight: '3px' }}>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={handleDrawerOpen}
                                        edge="start"
                                        sx={{
                                            // marginRight: 5,
                                            ...(open && { display: 'none' }),
                                        }}
                                        className='custom-download-icon-button'
                                    >
                                        {/* <MenuIcon /> */}
                                        <ChevronRightIcon style={{ backgroundColor: '#e3e3e3', borderRadius: '50%' }} />
                                    </IconButton>
                                </Toolbar>
                            </Grid>
                            <Grid item>
                                <h1 style={{ fontSize: '20px', fontWeight: '600', margin: '0px', paddingTop: '10px' }}>{heading}</h1>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} className='custom-appbar-padding'>
                        <Grid container justifyContent={'flex-end'}>
                            <Grid item >
                                <p className='custom-sidebar-username'>{username}</p>
                            </Grid>
                            {/* <Grid item xs={4}>
<Search className='custom-text'>
<SearchIconWrapper>
<SearchIcon />
</SearchIconWrapper>
<StyledInputBase
placeholder="Search…"
/>
</Search >
</Grid> */}
                            {/* <Grid item xs={1} className='custom-notification-padding'>
<Badge color='error' variant='dot' overlap='circular' style={{ cursor:'pointer' }} badgeContent=" " className='notification-dot'>
<NotificationsIcon className='notification-icon' />
</Badge>
</Grid> */}
                            <Grid item xs={2} md={2} lg={1} className='custom-profile-padding' textAlign={'end'} >
                                {auth && (
                                    <div>
                                        <IconButton className='custom-sidebar-profile-menu-icon'
                                            size="small"
                                            aria-label="account of current user"
                                            aria-controls="menu-appbar"
                                            aria-haspopup="true"
                                            onClick={handleMenu}
                                            color="inherit"
                                        >
                                            {/* <Icon icon="ph:user-circle" color="#a1acb3" width="24" height="24" className='Profile-icon' /> */}
                                            <AccountCircleIcon width="24" height="24" className='Profile-icon' />
                                        </IconButton>
                                        <Menu className='custom-sidebar-profile-menu'
                                            id="menu-appbar"
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                        >
                                            {/* <MenuItem onClick={handleClose}>
<Grid container>
<Grid item xs={1}>
<Icon icon="iconamoon:profile-fill" height={20} width={20} color="#a1acb3" />
</Grid >
<Grid item xs={11} textAlign={'center'}>
<p style={{margin:'0'}}>Profile</p>
</Grid>
</Grid>
</MenuItem>
<MenuItem onClick={handleClose} style={{ cursor:'pointer', display:'none' }}>
<Grid container>
<Grid item xs={1}>
<Icon icon="material-symbols:settings" height={20} width={20} color="#a1acb3" />
</Grid >
<Grid item xs={11} textAlign={'center'}>
<p style={{margin:'0'}}>Settings</p>
</Grid>
</Grid>
</MenuItem> */}
                                            {localStorage.is_superuser === 'true' && (
                                                <MenuItem onClick={handleGenerateSignup} className="hover-menu-item">
                                                    <Grid container justifyContent={'space-between'}>
                                                        <Grid item xs={1}>
                                                            <Icon  icon="ph:key" height={20} width={20} color="#a1acb3" />
                                                        </Grid >

                                                        <Grid item xs={9} textAlign={'start'}>
                                                            <p style={{ margin: '0', overflowWrap: 'break-word', fontSize: '12px' }}>Generate SecretKey</p>
                                                        </Grid>
                                                    </Grid>
                                                </MenuItem>
                                            )}
                                            <MenuItem onClick={handlePdfViewer} className="hover-menu-item">
                                                <Grid container justifyContent={'space-between'}>
                                                    <Grid item xs={1} >
                                                        <Icon icon="mdi:user" height={20} width={20} color="#a1acb3" />
                                                    </Grid >
                                                    <Grid item xs={9} textAlign={'start'}  >
                                                        <p style={{ margin: '0', fontSize: '12px' }}>User Guide</p>
                                                    </Grid>
                                                </Grid>

                                            </MenuItem>
                                            {localStorage.is_superuser === 'true' && (
                                                <MenuItem  className="hover-menu-item" onClick={handleLogs}>
                                                    <Grid container  justifyContent={'space-between'}>
                                                        <Grid item xs={1}>
                                                            <Icon  icon="icon-park-outline:upload-logs" onClick={handleLogs} height={20} width={20} color="#a1acb3" />
                                                        </Grid >
                                                        <Grid item xs={9} textAlign={'start'} >
                                                            <p  style={{ margin: '0', fontSize: '12px' }} onClick={handleLogs}>Logs</p>
                                                        </Grid>
                                                    </Grid>
                                                </MenuItem>
                                              )}
                                            <MenuItem onClick={handleClose} className="hover-menu-item">
                                                <Grid container onClick={logout} justifyContent={'space-between'}>
                                                    <Grid item xs={1}>
                                                        <Icon  icon="material-symbols:logout" height={20} width={20} color="#a1acb3" />
                                                    </Grid >
                                                    <Grid item xs={9} textAlign={'start'} >
                                                        <p  style={{ margin: '0', fontSize: '12px' }}>Logout</p>
                                                    </Grid>
                                                </Grid>
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader style={{ justifyContent: 'space-between' }}>
                    <img src='../sidebar/sidebar_caracal.png' style={{ margin: '0px 0px 0px 27px' }} className='custom-sidebar-img' />
                    <IconButton onClick={handleDrawerClose} className='custom-download-icon-button'>
                        {theme.direction === 'rtl' ? <ChevronRightIcon style={{ backgroundColor: '#e3e3e3', borderRadius: '50%' }} /> : <ChevronLeftIcon style={{ backgroundColor: '#e3e3e3', borderRadius: '50%' }} />}
                    </IconButton>
                </DrawerHeader>
                <List component="nav" className='custom-list'>
                    <ListItem sx={{ pb: 0 }}>
                        <ListItemButton onClick={(event) => handleListItemClick(0)} className={selectedItem === '/dashboard' ? 'custom-list-btn-selected' : 'custom-list-btn'}
                            component={Link}
                            to="/dashboard"
                            selected={selectedItem === '/dashboard'}
                        >
                            <ListItemIcon>
                                <Tooltip placement="right" title="Dashboard" arrow>
                                    <Icon icon="material-symbols:dashboard-customize-outline-rounded" color={selectedItem === '/dashboard' ? '#5755FF' : ''} width="20" height="20" className='list-icon' />
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" className={selectedItem === '/dashboard' ? 'sidebar-item-selected' : 'sidebar-item'} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem sx={{ pb: 0 }} >
                        {/* <Link to="fulfillment"> */}
                        <ListItemButton onClick={(event) => handleListItemClick(1)} className={selectedItem === '/fulfillment' || selectedItem === '/orderAcks' ? 'custom-list-btn-selected' : 'custom-list-btn'}
                            component={Link}
                            to="/fulfillment"
                            selected={selectedItem === '/fulfillment' || selectedItem === '/orderAcks'}
                        >
                            <ListItemIcon>
                                <Tooltip placement="right" title="Fulfillment" arrow>
                                    <Icon icon="iconamoon:delivery-fast" height='20px' width='20px' color={selectedItem === '/fulfillment' || selectedItem === '/orderAcks' || location.pathname ==='/fulfillment/' ? '#5755FF' : ''} className='list-icon' />
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText primary="Fulfillment" className={selectedItem === '/fulfillment' || selectedItem === '/orderAcks' || location.pathname ==='/fulfillment/' ? 'sidebar-item-selected' : 'sidebar-item'} />
                        </ListItemButton>
                        {/* </Link> */}
                    </ListItem>
                    {/* <ListItem sx={{ pb: 0 }}>
<ListItemButton onClick={(event) => handleListItemClick(event, 2)} className={selectedIndex === 2 ? 'custom-list-btn-selected' : 'custom-list-btn'}>
<ListItemIcon>
<DashboardCustomizeIcon className='list-icon' />
</ListItemIcon>
<ListItemText primary="Returns" className={selectedIndex === 2 ? 'sidebar-item-selected' : 'sidebar-item'} />
</ListItemButton>
</ListItem> */}

                    {/* <ListItem sx={{ pb: 0 }}>
<ListItemButton onClick={(event) => handleListItemClick(event, 3)} className={selectedIndex === 3 ? 'custom-list-btn-selected' : 'custom-list-btn'}>
<ListItemIcon >
<DashboardCustomizeIcon className='list-icon' />
</ListItemIcon>
<ListItemText primary="Manage" className={selectedIndex === 3 ? 'sidebar-item-selected' : 'sidebar-item'} />
</ListItemButton>
</ListItem> */}

                    {/* <Collapse in={openMenu} timeout="auto" unmountOnExit>
<List>
<ListItem sx={{ pb: 0 }}>
<ListItemButton className='custom-list-btn'>
<ListItemText inset primary="Customer Management" className='sidebar-item' />
</ListItemButton>
</ListItem>
<ListItem sx={{ pb: 0 }}>
<ListItemButton className='custom-list-btn'>
<ListItemText inset primary="Vendor Management" className='sidebar-item' />
</ListItemButton>
</ListItem> */}
                    {/* <ListItem sx={{ pb: 0 }}>
<ListItemButton className='custom-list-btn'>
<ListItemText inset primary="Program Management" className='sidebar-item' />
</ListItemButton>
</ListItem>
</List>
</Collapse> */}
                    <ListItem sx={{ pb: 0 }}>
                        <ListItemButton onClick={(event) => handleListItemClick(4)} className={selectedItem === '/item' || selectedItem === '/addItem' ? 'custom-list-btn-selected' : 'custom-list-btn'}
                            selected={selectedItem === '/item'} component={Link} to={`/item`}
                        >
                            <ListItemIcon >
                                <Tooltip placement="right" title="Inventory" arrow>
                                    <Icon icon="material-symbols:warehouse-outline-rounded" width="20" height="20" className='list-icon' />
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText primary="Inventory" className='sidebar-item' />
                            {(openMenuItem) || (selectedItem === '/addItem') || (selectedItem === '/item') ?
                                <ListItemIcon style={{ justifyContent: 'end' }}>
                                    <Icon icon="mdi:arrow-down-drop" width="20" height="20" className='list-icon' />
                                </ListItemIcon>
                                :
                                <ListItemIcon style={{ justifyContent: 'end' }}>
                                    <Icon icon="mdi:arrow-down-drop" rotate={3} width="20" height="20" className='list-icon' />
                                </ListItemIcon>
                            }
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={(openMenuItem) || (selectedItem === '/addItem') || (selectedItem === '/item')} timeout="auto" unmountOnExit>
                        <List>
                            <ListItem sx={{ pb: 0 }}>
                                <ListItemButton className='custom-list-btn' component={Link} to="/item">
                                    <ListItemIcon >
                                        <Tooltip placement="right" title="Items" arrow>
                                            <Icon icon="carbon:delivery-parcel" width="20" height="20" color={selectedItem === '/item' || selectedItem === '/addItem' ? '#5755FF' : ''} />
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText style={{ paddingLeft: '0px' }} inset primary="Items" className={selectedItem === '/item' || selectedItem === '/addItem' ? 'sidebar-item-selected' : 'sidebar-item'} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Collapse>
                    {/* <ListItem sx={{ pb: 0 }}>
<ListItemButton onClick={(event) => handleListItemClick(event, 5)} className={selectedIndex === 5 ? 'custom-list-btn-selected' : 'custom-list-btn'}>
<ListItemIcon>
<DashboardCustomizeIcon className='list-icon' />
</ListItemIcon>
<ListItemText primary="Payment" className={selectedIndex === 5 ? 'sidebar-item-selected' : 'sidebar-item'} />
</ListItemButton>
</ListItem> */}
                    {/* <ListItem sx={{ pb: 0 }}>
<ListItemButton onClick={(event) => handleListItemClick(event, 6)} className={selectedIndex === 6 ? 'custom-list-btn-selected' : 'custom-list-btn'}>
<ListItemIcon>
<DashboardCustomizeIcon className='list-icon' />
</ListItemIcon>
<ListItemText primary="Announcement" className={selectedIndex === 6 ? 'sidebar-item-selected' : 'sidebar-item'} />
</ListItemButton>
</ListItem> */}
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1 }} className='custom-box'>
                <DrawerHeader />
                <Outlet />
            </Box>
            <Modal
                open={modalopen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} >
                    {(alert == 'true') ? <Alert onClose={alertClose}>{responseMsg}</Alert> : (alert == 'false') ? <Alert onClose={alertClose} severity='error'>{responseMsg}</Alert> : ''}
                    <Formik initialValues={initialValues} validateOnChange={true} validateOnBlur={false} validationSchema={validationSchema} onSubmit={handeleSecretkey}>
                        {({ errors, touched }) => (
                            <Form>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <p className='custom-generatekey-head'> Secret Key</p>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} py={1}>

                                        <FormControl size='small' fullWidth variant="outlined" >
                                            <Field as={TextField} type="email" size='medium' className='custom-login-textfield' fullWidth label="Enter Email" id='email' name="email" variant="outlined" sx={{ mb: 2 }}
                                                error={(touched.email && !!errors.email) || (submitted && !!errors.email)}
                                                helperText={touched.email && (errors.email || (submitted && errors.email))}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Icon icon="dashicons:email" color="#171c26" width="20" height="20" />
                                                        </InputAdornment>
                                                    )
                                                }} />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container justifyContent={'end'} pt={1}>
                                    <Grid item lg={3} md={3} sm={3} xs={3} textAlign={"start"}>
                                        {(loading) ?
                                            <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} className="custom-PaymentTerm-save-btn" variant="contained" > Loading </LoadingButton>
                                            :
                                            <Button type="submit" className="custom-PaymentTerm-save-btn" >
                                                Send Key
                                            </Button>
                                        }

                                    </Grid>
                                    <Grid item lg={3} md={3} sm={3} xs={3} textAlign={"start"}>
                                        <Button className="custom-PaymentTerm-cancel-btn" onClick={handleModalClose}>Cancel</Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Modal>
        </Box>
    );
}
