import { ApiService } from "../../../service/api.service";
import Swal from 'sweetalert2';

const apiService = new ApiService();
//Api for order Creation
export const createOrder = async (data: any, supplier: any) => {
  var parseXML = require('xml-parse-from-string');
  let xmlStr;
  try {
    var parseXML = require('xml-parse-from-string')
    var xmlDoc = parseXML(data)
    if (xmlDoc && xmlDoc.getElementsByTagName) {
      var tag = xmlDoc.getElementsByTagName('cXML')[0];

      if (tag) {
        tag.setAttribute('suppliername', supplier);

        const serializer = new XMLSerializer();
        xmlStr = serializer.serializeToString(xmlDoc);
      } else {
        console.log('The "cXML" element was not found in the XML document.');
      }
    } else {
      console.log('Invalid XML document.');
    }
  } catch (error) {
    console.error('Error occurred while parsing XML:', error);
  }
  let result;
//Api for upload cxml
  await apiService.add('orders/uploadcxml/', xmlStr).then((response: any) => {
    if (response.statusCode == 200) {
      let message = "Cxml successfully uploaded"
      result = {
        'status': response.statusCode,
        'message': message
      }
    }
  })
    .catch(error => {
      let status = error.response.status
      let supplierId: any = ''
      if (status == 400) {
        let data = error.response.data
        console.log(data)
        if (data.body) {
          result = {
            'status': error.response.status,
            'message': data.body

          }
        } else {
          for (let i = 0; i < data.supplierID.length; i++) {
            if (supplierId !== '') {
              supplierId += ',';
            }
            supplierId += data.supplierID[i];
          }
          result = {
            'status': error.response.status,
            'message': data.message + ' ' + supplierId
          }
        }
      }
      if (error.response.status == 401) {
        //apiService.tokenExpired();
      }
    });
  return result;
}
// get Api for Customer Name
export const getCustomerName = async () => {
  let result;
  await apiService.getAll('api/customer/?type=basicDetails').then((response: any) => {
    if (response.status_code == 200) {
      result = response.data
    }
  })
    .catch(error => {
      console.error('Error occurred:', error);
      if (error.response.status == 401) {
        //apiService.tokenExpired();
      }
    });
  return result;
}
// get Api for Supplier Name
export const getSupplierName = async () => {
  let result;
  let id = localStorage.getItem('userId');
  await apiService.getAll(`api/supplierData/?userId=${id}`).then((response: any) => {
    if (response.status_code == 200) {
      result = response.supplierData
    }
  })
    .catch(error => {
      console.error('Error occurred:', error);
      if (error.response.status == 401) {
        //apiService.tokenExpired();
      }
    });
  return result;
}