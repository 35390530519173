import React from "react";
import MUIDataTable from "mui-datatables";
import './item_list.css';

export default function TableDetails(props: any) {
  const allColumns = props.columns;
  const data = props.itemList;
  const search = props.searchQuery

  const [visibleColumns, setVisibleColumns] = React.useState<number[]>([]);
  const [currentPage,setCurrentPage] = React.useState(0);

  const columns = allColumns.filter((_: any, index: number) =>
  visibleColumns.includes(index)
); 

  React.useEffect(() => {
    const storedButtonIds = localStorage.getItem('selectedButtonItem');
    const initialButtonIds = storedButtonIds ? JSON.parse(storedButtonIds) : props.buttonId;
    setVisibleColumns(initialButtonIds)

    console.log(initialButtonIds)
    const data = allColumns.filter((_: any, index: number) =>
    initialButtonIds.includes(index)
  ); 

  props.setItemTableColumns(data)

  }, [props.buttonId]);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
  };
  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage); // Update the currentPage state variable
  };
  const options = {
    filter: true,
    onChangeRowsPerPage: handleChangeRowsPerPage,
    onRowSelectionChange: (currentRowsSelected: any, allRowsSelected: any, rowsSelected: any) => {
      props.handleDeleteIcon(true, rowsSelected.length)
      if (allRowsSelected) {
        let selectedRows: any = []
        const selectedRowData = allRowsSelected.map((index: any) => {
          const id = index.dataIndex;
          selectedRows.push(data[id].itemId)
        });
        props.setSelectedRowData(selectedRows)
      }
    },
    onFilterChange: (changedColumn: any, filterList: any) => {
    },
    rowsPerPage: rowsPerPage,
    expandableRows: false,
    page: currentPage,
    onChangePage: handleChangePage,
    showResponsive: true,
    responsive: "standard", 
    rowsPerPageOptions: [5, 10, 25, 50, 100],
  };
  return (
    <div className="custom-item-table">
      <div className="custom-item-table-container">
        {/* {filteredData && filteredData.length > 0 ? ( */}
        {/* <TableContainer className='custom-table'> */}
        <MUIDataTable
          title={"Order Details"}
          data={data}
          columns={columns}
          options={options as any}
        />
        {/* </TableContainer> */}
        {/* ) : ( */}
        {/* <div>No data available.</div> */}
        {/* )} */}
      </div>
    </div>
  );
}
