
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import MiniDrawer from './components/sidebar/sidebar.component';
import Fulfillment from './pages/fulfillment/fulfillment.page';
import Customer from './pages/customer/customer.page';
import CustomerDetails from './components/customer/customer_details/customer_details.component';
import OrderAcks from './components/fullfillment/order_acks/order_acks.component';
import Item from './pages/items/items.page';
import AddItem from './components/items/add_item/add_item.component';
import { AuthService } from './service/auth.service';
import LoginForm from './pages/login/login.Page';
import ReSubmission from './components/fullfillment/resubmission/resubmission.component';
import Invoice from './components/fullfillment/order_acks/invoice/invoice.component';
import RegisterForm from './pages/registerPage/register.page';
import Dashboard from './components/dashboard/dashboard';
import Footer from './components/footer/footer';
import IdleTimer from './components/idle_timer/idle_timer_component';
import ForgotPassword from './components/forgot_password/forgot_password';
import Swal from 'sweetalert2';
import { Authentication } from './service/auth.utils';
import Log from './components/logs/log';


function App() {
  const authService = new AuthService()
  const tokenAuthentication = new Authentication()
  const isAuthenticated = tokenAuthentication.isAuthenticated();

  const handleIdle = async () => {
    Swal.fire({
      title: 'Session Expiring Soon',
      text: 'Your session will expire soon. Click Cancel to continue your session.',
      timer: 120000,
      timerProgressBar: true,
      showConfirmButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: 'Cancel',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      } else {
        authService.logout().then(() => {
          localStorage.clear();
          window.location.reload();
        });
      }
    });

  };
  return (
    <div className="App">
      
      <BrowserRouter>
        {(isAuthenticated) ?
          <>
            <IdleTimer  timeout={480000} onIdle={handleIdle} />
            <Routes>
              <Route path='/' element={<MiniDrawer />}>
                <Route path='/fulfillment' element={<Fulfillment />}></Route>
                <Route path='/customer' element={<Customer />}></Route>
                <Route path='/addCustomer' element={<CustomerDetails />}></Route>
                <Route path='/orderAcks' element={<OrderAcks />}></Route>
                <Route path='/item' element={<Item />}></Route>
                <Route path='/addItem' element={<AddItem />}></Route>
                <Route path='/resubmission' element={<ReSubmission />}></Route>
                <Route path='/dashboard' element={<Dashboard />}></Route>
                <Route path="/log" element={<Log/>}></Route>
                <Route path="/*" element={<Navigate to="/dashboard" />} />
              </Route>
              <Route path="/invoice" element={<Invoice />} > </Route>
            </Routes>
          </>

          :
          <Routes>
            <Route path='/login' element={<LoginForm />}></Route>
            <Route path='/register' element={<RegisterForm />}></Route>
            <Route path='/forgotPassword' element={<ForgotPassword />}></Route>
            <Route path="/*" element={<Navigate to="/login" />} />
          </Routes>
        }
      </BrowserRouter>
    </div>
  );
}

export default App;