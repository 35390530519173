import { requirePropFactory } from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import jwt_decode from "jwt-decode";
import { ApiService } from './api.service';

const baseUrl = process.env.REACT_APP_API_URL
const apiService = new ApiService();

let authToken: string | null = localStorage.getItem('authToken') ? localStorage.getItem('authToken') : null

const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: { Authorization: `Bearer ${authToken}` }
});

axiosInstance.interceptors.request.use(async req => {

    if (!authToken) {
        authToken = localStorage.getItem('authToken') ? localStorage.getItem('authToken') : null
        req.headers.Authorization = `Bearer ${authToken}`
    }

    if (authToken !== null) {
        const user: any = jwt_decode(authToken);

        const currentTimestamp = dayjs();
        const expirationTimestamp = dayjs.unix(user.exp);
        const timeBeforeExpiration = 2 * 60 * 1000;

        const isExpired = expirationTimestamp.diff(currentTimestamp) < timeBeforeExpiration;

        if (!isExpired) return req;

        await axios.post(`${baseUrl}api/login/refresh/`, {
            refresh: localStorage.getItem('refresh')
        }).then(response => {
            localStorage.setItem('authToken', response.data.access)
            localStorage.setItem('refresh', response.data.refresh)
            req.headers.Authorization = `Bearer ${response.data.access}`
            return req
        }).catch(async error => {
            if (error.response.status == 401) {
                await new Promise(f => setTimeout(f, 10000));
                await axios.post(`${baseUrl}api/login/refresh/`, {
                    refresh: localStorage.getItem('refresh')
                }).then(response => {
                    localStorage.setItem('authToken', response.data.access)
                    localStorage.setItem('refresh', response.data.refresh)
                    req.headers.Authorization = `Bearer ${response.data.access}`
                    return req
                }).catch(error => {
                    if (error.response.status == 401) {
                        apiService.tokenExpired()
                    }
                });
            }
        });
    }

    return req
})

export default axiosInstance