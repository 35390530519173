import './dashboard.css';
import React from 'react'
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Button, Chip, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { FilterDashboardInfo, GetInfo } from './dashboard.api';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DashboardTable from './dashboard_table';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';

// import DashboardTable from './dashboard_table';
// import DashboardPieChart from './dashboard_piechart';
// import DashboardBarchat from './dashboard_barchart';
// import Piechart from './piechart';

interface DashboardInfo {
    newOrderCount: number;
    invoicedOrderCount: number;
    resubmittedOrderCount: number;
    sentToAribaOrderCount: number;
    rejectedOrderCount: number;
    acknowledgedAndTaxCalcOrderCount: number;
    allOrderCount: number;
}
export default function Dashboard() {
    const [dashboardInfo, setDashboardInfo] = React.useState<DashboardInfo | null>();
    const [filterMonth, setFilterMonth] = React.useState('');
    const [filterFromDate, setFilterFromDate] = React.useState<any>(null);
    const [filterToDate, setFilterToDate] = React.useState<any>(null);
    const [filterDatePicker, setFilterDatePicker] = React.useState('');
    const [fromDateError, setFromDateError] = React.useState('');
    const [toDateError, setToDateError] = React.useState(''); 
    
    const filterSelectData =[
        { id:1,name:'Current Month',value:'currentMonth'},
        { id:2,name:'Last Month',value:'lastMonth'},
        { id:3,name:' Last 3 Months',value:'last3Months'},
        { id:4,name:'custom',value:'custom'},
    ]
    const handleChange = (event: any) => {
        setFilterToDate(null);
        setFilterFromDate(null);
        const filterData = event.target.value;
        setFilterMonth(filterData);
        if(filterData == 'custom'){
            setFilterDatePicker('custom')
        }else{
            setFilterDatePicker('auto')
        }
        if(filterData !== 'custom'){
            const filterDatas = {
                'filteredData': filterData,
                'fromDate': '',
                'toDate': '',
            }
            FilterDashboardInfo(filterDatas).then((res:any) => {
                if(res.status_code == 200){
                    setDashboardInfo(res.dashboardCount);
                }
            })
        }
    };
    const handleFilterFromDate = (date: any) => {
        const filterFromDate =  dayjs(date).format('YYYY-MM-DD');
        setFilterFromDate(filterFromDate);
        setFromDateError('');

    };
    const handleFilterToDate = (date: any) => {
        const filterToDate =  dayjs(date).format('YYYY-MM-DD');
        setFilterToDate(filterToDate);
        setToDateError('');

    };
    
    const handeFilter = async () => {
        const filterData = {
            'filteredData': filterMonth,
            'fromDate':filterFromDate,
            'toDate':filterToDate,
        }
        if (filterMonth === 'custom') {
            if (!filterFromDate) {
              setFromDateError('Please select From Date');
            }
            if (!filterToDate) {
              setToDateError('Please select To Date');
            }
            if (filterFromDate && filterToDate) {             
                await FilterDashboardInfo(filterData).then((res:any) => {
                    if(res.status_code == 200){
                        setDashboardInfo(res.dashboardCount);
                    }
                })
            }
          } 
       
    };
    const fetchData = async () => {
        try {          
            setFilterMonth('currentMonth')      
            let dashboardInfo = await GetInfo()
            setDashboardInfo(dashboardInfo);
        } catch (error) {
            console.error('Error occurred:', error);
        }
    };
    React.useEffect(() => {
            
        fetchData();
    }, [])
    const handleReload = () => {
        setFilterToDate(null)
        setFilterFromDate(null);
        setFilterMonth('');
        setFilterDatePicker('')
        fetchData()
      }
    const navigate = useNavigate();
    const handleNewOrder = () => {
        navigate('/fulfillment/?tab=1');
    };
    const handleConfirmed = () => {
        navigate('/fulfillment/?tab=2');
    };
    const handleResubmitted = () => {
        navigate('/fulfillment/?tab=4');
    };
    const handleInvoice = () => {
        navigate('/fulfillment/?tab=6');
    };
    const handleRejected = () => {
        navigate('/fulfillment/?tab=3');
    };

    return (
        <div>
            <Paper className='custom-dashboard-paper'>
                {/* <Grid container>
                    <Grid item pl={2}>
                        <h1 className='custom-dashboard-heading'>Dashboard</h1>
                    </Grid>
                </Grid> */}
                <Grid container columnGap={2}>
                    <Grid item xs={3} sm={3} md={3} lg={2} xl={2} pl={2}>
                        <FormControl fullWidth sx={{m:1}}>
                            <InputLabel id="demo-select-small-label">Filter</InputLabel>
                            <Select labelId="demo-select-small-label" id="demo-select-small" value={filterMonth} onChange={handleChange} label="Filter" size= 'small' >
                                {Array.isArray(filterSelectData) && filterSelectData.map((month:any)=>(
                                    <MenuItem value={month.value}>{month.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {filterDatePicker == 'custom' &&
                        <>
                            <Grid item xs={3} sm={3} md={3} lg={2} xl={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} size='small' >
                                    <DemoContainer components={['DatePicker']} >
                                        <DatePicker label="From Date" className='dashboard-filter-datepicker'  value={filterFromDate}  slotProps={{
                                                textField: {
                                                    helperText:(<span style={{ color: 'red' }}>{fromDateError}</span>),
                                                },
                                            }} onChange={handleFilterFromDate} />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={2} xl={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} size='small'>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker label="To Date" className='dashboard-filter-datepicker' slotProps={{
                                                textField: {
                                                    helperText:(<span style={{ color: 'red' }}>{toDateError}</span>),
                                                },
                                            }}  value={filterToDate} onChange={handleFilterToDate}/>
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2} alignSelf={'center'}>
                        <Grid container alignItems={'center'}>
                            <Grid item xs={6} sm={6} md={6} lg={5} xl={4} textAlign={'center'}>
                                <Button variant="contained" className='custom-order-btn' onClick={handeFilter}>Filter</Button> &nbsp;
                            </Grid>
                            {(filterDatePicker == 'custom' || filterDatePicker == 'auto') &&
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                                    <Icon icon="ion:reload-circle" className='custom-fulfillment-table-icon' height='20px' color="#5755ff" width='20px' onClick={handleReload} />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                        </>
                    }
                   
                </Grid>
                <Grid container className='custom-dashboard-container' style={{ columnGap: '2rem', justifyContent: 'space-evenly' }}>
                    <Grid item className="custom-dashboard-item" xs={8} md={2} sm={3} lg={2} xl={2} py={1} textAlign={'center'}>
                        <Card className='custom-dashboard-card' style={{ backgroundColor: '#f4e8ff', border: '1px solid #f4e8ff', boxShadow: 'none',cursor:'pointer' }} onClick={handleNewOrder}>
                            <CardContent className='custom-dashboard-card-content'>
                                {/* <Chip  label={dashboardInfo?.newOrderCount.toString()} className='custom-dashboard-chiplabel' style={{cursor:'pointer',background:"#626C7A",color:'#FFFFFF',height:'33px',width:'33px'}}  /> */}
                                <Grid container justifyContent={'space-between'} className='custom-dashboard-inner-container' >
                                    <Grid item xs={9} md={9} sm={9} lg={9} >
                                        <Typography variant="h5" component="div" className='custom-dashboard-cardText'>
                                            New Order
                                        </Typography>
                                        <p className="custom-dashboard-card-count">{dashboardInfo?.newOrderCount.toString()} </p>
                                    </Grid>
                                    <Grid item xs={2} md={3} sm={3} lg={3} style={{ textAlign: 'end', alignSelf: 'center' }}>
                                        <Button className='custom-dashboard-icon-button' style={{ backgroundColor: '#bf83ff' }}>
                                            <Icon icon="mdi:order-bool-descending" height="25px" width="25px" color="white" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item className="custom-dashboard-item" xs={8} md={2} sm={3} lg={2} xl={2} py={1} textAlign={'center'}>
                        <Card className='custom-dashboard-card' style={{ backgroundColor: '#dafcf1', border: '1px solid #dafcf1', boxShadow: 'none',cursor:'pointer' }} onClick={handleConfirmed}>
                            <CardContent className='custom-dashboard-card-content'>
                                {/* <Chip className='custom-dashboard-chiplabel' label={dashboardInfo?.acknowledgedAndTaxCalcOrderCount.toString()} style={{cursor:'pointer',background:"#626C7A",color:'#FFFFFF',height:'33px',width:'33px'}}  /> */}
                                <Grid container justifyContent={'space-between'} className='custom-dashboard-inner-container' >
                                    <Grid item xs={9} md={9} sm={9} lg={9}>
                                        <Typography variant="h5" component="div" className='custom-dashboard-cardText'>
                                            Confirmed 
                                        </Typography>
                                        <p className="custom-dashboard-card-count">{dashboardInfo?.acknowledgedAndTaxCalcOrderCount.toString()} </p>
                                    </Grid>
                                    <Grid item xs={2} md={3} sm={3} lg={3} style={{ textAlign: 'end', alignSelf: 'center' }}>
                                        <Button className='custom-dashboard-icon-button' style={{ backgroundColor: '#06df98' }}>
                                            <Icon icon="material-symbols:order-approve-outline" height="25px" width="25px" color="white" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item className="custom-dashboard-item" xs={8} md={2} sm={3} lg={2} xl={2} py={1} textAlign={'center'}>
                        <Card className='custom-dashboard-card' style={{ backgroundColor: '#ffe2e6', border: '1px solid #ffe2e6', boxShadow: 'none',cursor:'pointer' }} onClick={handleRejected}>
                            <CardContent className='custom-dashboard-card-content'>
                                {/* <Chip className='custom-dashboard-chiplabel' label={dashboardInfo?.rejectedOrderCount.toString()} style={{cursor:'pointer',background:"#626C7A",color:'#FFFFFF',height:'33px',width:'33px'}} /> */}
                                <Grid container justifyContent={'space-between'} className='custom-dashboard-inner-container' >
                                    <Grid item xs={9} md={9} sm={9} lg={9}>
                                        <Typography variant="h5" component="div" className='custom-dashboard-cardText'>
                                            Rejected
                                        </Typography>
                                        <p className="custom-dashboard-card-count">{dashboardInfo?.rejectedOrderCount.toString()} </p>
                                    </Grid>
                                    <Grid item xs={2} md={3} sm={3} lg={3} style={{ textAlign: 'end', alignSelf: 'center' }}>
                                        <Button className='custom-dashboard-icon-button' style={{ backgroundColor: '#f9597d' }}>
                                            <Icon icon="tabler:refresh" height="25px" width="25px" color="white" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item className="custom-dashboard-item" xs={8} md={2} sm={3} lg={2} xl={2} py={1} textAlign={'center'}>
                        <Card className='custom-dashboard-card' style={{ backgroundColor: '#fff4de', border: '1px solid #fff4de', boxShadow: 'none',cursor:'pointer' }} onClick={handleResubmitted}>
                            <CardContent className='custom-dashboard-card-content'>
                                {/* <Chip className='custom-dashboard-chiplabel' label={dashboardInfo?.resubmittedOrderCount.toString()} style={{cursor:'pointer',background:"#626C7A",color:'#FFFFFF',height:'33px',width:'33px'}}  /> */}
                                <Grid container justifyContent={'space-between'} className='custom-dashboard-inner-container' >
                                    <Grid item xs={9} md={9} sm={9} lg={9}>
                                        <Typography variant="h5" component="div" className='custom-dashboard-cardText'>
                                            Resubmitted
                                        </Typography>
                                        <p className="custom-dashboard-card-count">{dashboardInfo?.resubmittedOrderCount.toString()} </p>
                                    </Grid>
                                    <Grid item xs={2} md={3} sm={3} lg={3} style={{ textAlign: 'end', alignSelf: 'center' }}>
                                        <Button className='custom-dashboard-icon-button' style={{ backgroundColor: '#fab62f' }}>
                                            <Icon icon="fluent:text-change-reject-24-regular" height="25px" width="25px" color="white" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item className="custom-dashboard-item" xs={8} md={2} sm={3} lg={2} xl={2} py={1} textAlign={'center'}>
                        <Card className='custom-dashboard-card' style={{ backgroundColor: '#dcfce7', border: '1px solid #dcfce7', boxShadow: 'none',cursor:'pointer' }} onClick={handleInvoice}>
                            <CardContent className='custom-dashboard-card-content' >
                                {/* <Chip className='custom-dashboard-chiplabel' label={dashboardInfo?.invoicedOrderCount.toString()} style={{cursor:'pointer',background:"#626C7A",color:'#FFFFFF',height:'33px',width:'33px'}}  /> */}
                                <Grid container justifyContent={'space-between'} className='custom-dashboard-inner-container' >
                                    <Grid item xs={9} md={9} sm={9} lg={9}>
                                        <Typography variant="h5" component="div" className='custom-dashboard-cardText'>
                                            Invoiced
                                        </Typography>
                                        <p className="custom-dashboard-card-count" >{dashboardInfo?.invoicedOrderCount.toString()} </p>
                                    </Grid>
                                    <Grid item xs={2} md={3} sm={3} lg={3} style={{ textAlign: 'end', alignSelf: 'center' }}>
                                        <Button className='custom-dashboard-icon-button' style={{ backgroundColor: '#3cd756' }}>
                                            <Icon icon="teenyicons:tick-circle-outline" height="25px" width="25px" color="white" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>              
            </Paper>
            <Paper className='custom-dashboard-paper'>
                <Grid container>
                    <Grid item xs={12} px={2}>
                    <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography style={{fontSize:'20px',fontWeight:'600'}} className='orderProcessLog'>Auto Order Process Log</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <DashboardTable />
                    </AccordionDetails>
                </Accordion>
                    </Grid>
                </Grid>            
            </Paper>
            {/* <Paper className='custom-dashboard-paper-2'>
            <Grid container >
                <Grid item xs={5} md={5} sm={5} lg={5} xl={5} >
                    <Paper className='custom-dashboard-paper-2'>
                        <DashboardTable />
                    </Paper>
                </Grid>
                <Grid item xs={3}md={3.5} sm={3} lg={3.5} xl={3} >
                    <Paper className='custom-dashboard-paper-2'>
                        <DashboardPieChart />
                    </Paper>
                </Grid>
                <Grid item xs={3.5} md={3.5} sm={3.5} lg={3.5} xl={3.5} >
                    <Paper className='custom-dashboard-paper-2'>
                        <DashboardBarchat />
                    </Paper>
                </Grid>
            </Grid>
            </Paper> */}
        </div>
    )
}

