import React, { forwardRef, useImperativeHandle } from 'react'
import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField } from '@mui/material'
import './resubmission_tax.css'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Formik, Form, Field, ErrorMessage, FormikProps } from "formik";
import { getTaxcategory } from '../resubmission_api';

interface ChildComponentProps {
    handleEditFields: () => void; // Define the 'handleEditFields' property with the correct type
    isFieldsEnabled: boolean; // Update the type if necessary
    resubmissionTaxDetail: any;
}

export interface ChildComponentRef {
    reSubmissionTaxSubmit: () => void;
}

const ReSubmissionTax = forwardRef<ChildComponentRef, ChildComponentProps>((props: any, ref: any) => {
    const formRef = React.useRef<FormikProps<any>>(null);
    const [taxData, setTaxData] = React.useState<any>();

    const initialValues = {
        tax: "",
        taxLocation: "",
        taxableAmount: "",
        taxCategory: "",
        rate: "",
        taxAmount: "",
        description: ""
    };
    const handleChangeRadio = (e: any) => {
        let tax = e.target.value;
        formRef.current?.setFieldValue('tax', tax);
    }

    useImperativeHandle(ref, () => ({
        reSubmissionTaxSubmit() {
            if (formRef.current) {
                return formRef.current.values;
            }
        }
    }));
    React.useEffect(() => {
        let taxDetails:any
        const fetch_data = async () => {
            try {
                await getTaxcategory().then((response: any) => {
                    taxDetails = response
                    setTaxData(response)
                    if (props.resubmissionTaxDetail) {
                        let taxCategory = props.resubmissionTaxDetail.taxCategory
                        let salesTaxCategory = taxDetails.find((item: any) => item.taxCategoryName === taxCategory);
                        formRef.current?.setFieldValue('taxLocation', props.resubmissionTaxDetail.taxLocation);
                        formRef.current?.setFieldValue('taxCategory', salesTaxCategory.taxCategoryName);
                        formRef.current?.setFieldValue('taxAmount', props.resubmissionTaxDetail.taxAmount);
                        formRef.current?.setFieldValue('taxableAmount', props.resubmissionTaxDetail.taxableAmount);
                        formRef.current?.setFieldValue('rate', props.resubmissionTaxDetail.rate);
                        // formRef.current?.setFieldValue('description', salesTaxCategory.description);
                    }
                })
            }
            catch (error) {
                console.error('Error occurred:', error);
            }
        }
        fetch_data();
    }, [props.resubmissionTaxDetail])

    const handleTaxDescription = (id: any) => {
        let salesTaxCategory = taxData.find((item: any) => item.id === id);
        if (salesTaxCategory.description == null)
            formRef.current?.setFieldValue('description', '');
        else
            formRef.current?.setFieldValue('description', salesTaxCategory.description);
    }
    interface CustomSelectProps {
        field: any; // replace 'any' with the actual type
        form: any; // replace 'any' with the actual type
        taxData: any[]; // replace 'any' with the actual type of a tax item
        label: string;
        // include any other props that you're passing to the CustomSelect component
    }

    const CustomSelect: React.FC<CustomSelectProps> = ({ field, form, taxData, label, ...props }) => {
        return (
            <FormControl variant="outlined" fullWidth>
                <InputLabel id={field.name}>{label}</InputLabel>
                <Select {...field} {...props} labelId={field.name} label={label}>
                    {Array.isArray(taxData) && taxData.map((tax: any) => (
                        <MenuItem
                            key={tax.id}
                            value={tax.taxCategoryName}
                            onClick={() => { handleTaxDescription(tax.id) }}
                        >
                            {tax.taxCategoryName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };
    return (
        <div>
            <Paper className='custom-ReSubmissionTax-paper'>
                <Grid container className='custom-ReSubmissionTax-container'>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>
                            <h1 className='custom-ReSubmissionTax-header'>Header Level Tax</h1>
                        </Stack>
                    </Grid>
                </Grid>
                <Formik initialValues={initialValues} innerRef={formRef} onSubmit={() => { }}>
                    <Form>
                        {/* <Grid container className='custom-ReSubmissionTax-container'>
                                <Grid item xs={12} pl={2}>
                                    <FormControl disabled={!props.isFieldsEnabled} >
                                        <RadioGroup sx={{ '& .MuiSvgIcon-root': { fontSize: 18, }, }}
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="tax" 
                                            onChange={handleChangeRadio} > 
                                            <FormControlLabel value="1" checked={true} control={<Radio  />} label="Header level tax" />
                                            <FormControlLabel value="2" disabled control={<Radio />} label="Line level tax" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid> */}
                        <Grid container justifyContent={'center'}>
                            <Grid item lg={4} md={4} sm={4} xs={4} pt={2} pr={2} textAlign={'end'}>
                                <Field as={TextField} fullWidth size="small" id="taxLocation" name="taxLocation" label="Location" className='custom-ReSubmissionTax-textField' variant="outlined" disabled={!props.isFieldsEnabled} />
                            </Grid>
                            <Grid item lg={4} md={4} sm={4} xs={4} pt={2} textAlign={'start'}>
                                <Field as={TextField} fullWidth size="small" id="taxableAmount" name="taxableAmount" label="Taxable amount" className='custom-ReSubmissionTax-textField' variant="outlined" disabled={!props.isFieldsEnabled} />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent={'center'}>
                            <Grid item lg={4} md={4} sm={4} xs={4} pt={2} pr={2} textAlign={'end'} className='custom-ReSubmissionTax-select-grid'>
                                {/* <FormControl fullWidth className='custom-ReSubmissionTax-select-formcontrol'>
                                        <InputLabel className='custom-ReSubmissionTax-textField' id="Taxcategory">Tax category</InputLabel>
                                        <Field as={Select} fullWidth size="small" className='custom-ReSubmissionTax-select' id="taxCategory" name="taxCategory" labelId="Taxcategory" label="Tax category" variant="outlined" 
                                         disabled={!props.isFieldsEnabled}>
                                        {Array.isArray(taxData) && taxData.map((tax: any) => (
                                                <MenuItem key={tax.id} value={tax.taxCategoryName}  onClick={() => handleTaxDescription(tax.id)}>{tax.taxCategoryName}</MenuItem>
                                        ))}
                                        </Field>
                                    </FormControl> */}
                                {/* <Field component={CustomSelect} fullWidth size="small" className='custom-ReSubmissionTax-select' id="taxCategory" name="taxCategory" labelId="Taxcategory" label="Tax Category" variant="outlined" taxData={taxData} disabled={!props.isFieldsEnabled}/> */}
                                <Field component={CustomSelect} fullWidth size="small" className='custom-ReSubmissionTax-select' id="taxCategory" name="taxCategory" labelId="Taxcategory" label="Tax Category" variant="outlined" taxData={taxData} disabled={!props.isFieldsEnabled} />
                            </Grid>
                            <Grid item lg={4} md={4} sm={4} xs={4} pt={2} textAlign={'start'}>
                                <Field as={TextField} fullWidth size="small" id="rate" name="rate" label="Rate(%)" className='custom-ReSubmissionTax-textField' variant="outlined" disabled={!props.isFieldsEnabled} />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent={'center'}>
                            <Grid item lg={4} md={4} sm={4} xs={4} pt={2} pr={2} textAlign={'end'}>
                                <Field as={TextField} fullWidth size="small" id="description" name="description" label="Description" className='custom-ReSubmissionTax-textField' variant="outlined" disabled={!props.isFieldsEnabled} />
                            </Grid>
                            <Grid item lg={4} md={4} sm={4} xs={4} pt={2} textAlign={'start'}>
                                <Field as={TextField} fullWidth size="small" id="taxAmount" name="taxAmount" label="Tax amount" className='custom-ReSubmissionTax-textField' variant="outlined" disabled={!props.isFieldsEnabled} />
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </Paper>
        </div>
    )
});

export default ReSubmissionTax