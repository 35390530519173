import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import './table_settings.css'

interface ButtonLabel {
    id: number;
    name: string;
}

export function TableColumnSettingsComponent(props: any) {
    const buttonLabels: ButtonLabel[] = props.buttonLabel
    let storedButtonIds:any;
    if (props.type == 'item'){
         storedButtonIds = localStorage.getItem('selectedButtonItem');
    }else{
         storedButtonIds = localStorage.getItem('selectedButtonIds');
    }    
    const initialButtonIds = storedButtonIds ? JSON.parse(storedButtonIds) : props.buttonId;
    const [selectedButtonIds, setSelectedButtonIds] = useState<number[]>(initialButtonIds);
    const condition = buttonLabels.length > 10;
    const firstSlice = buttonLabels.slice(0, condition ? Math.ceil(buttonLabels.length / 2) : buttonLabels.length);
    const secondSlice = buttonLabels.slice(condition ? Math.ceil(buttonLabels.length / 2) : buttonLabels.length);
    React.useEffect(() => {
        if(props.type == 'item'){
            localStorage.setItem('selectedButtonItem', JSON.stringify(selectedButtonIds));
        }else{
            localStorage.setItem('selectedButtonIds', JSON.stringify(selectedButtonIds));
        }
    }, [props.buttonId]);

    const handleButtonClick = (buttonId: number) => {
        if (selectedButtonIds.includes(buttonId)) {
            setSelectedButtonIds(selectedButtonIds.filter((id) => id !== buttonId));
        } else {
            setSelectedButtonIds([...selectedButtonIds, buttonId]);
        }
    };

    const handleSendButtonId = () => {
        props.handleCloseTableModal();
        props.handleButtonId(selectedButtonIds)
        if(props.type == 'item'){
            localStorage.setItem('selectedButtonItem', JSON.stringify(selectedButtonIds));
        }else{
            localStorage.setItem('selectedButtonIds', JSON.stringify(selectedButtonIds));
        }
    }

    const handleResetButtonId = () => {
        if (props.type == 'item') {
            let button = [0, 1,2,3,4, 5, 6, 7, 8,9]
            localStorage.setItem('selectedButtonItem', JSON.stringify(button));
            props.handleButtonId(button)
        } else {
            let button = [0, 1, 2, 3, 4, 5, 6, 15, 16]
            localStorage.setItem('selectedButtonIds', JSON.stringify(button));
            props.handleButtonId(button)
        }
        props.handleCloseTableModal();
    }

    return (
        <Box className='custom-modal'>
            <Grid container>
                <Grid item xs={12}>
                    <h1 className='custom-table-header'>Table Settings</h1>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} pb={1}>
                    <Typography className='custom-table-column'>Table Column</Typography>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item lg={12} md={12} px={2}>
                    {firstSlice.map((label) => (
                        <Button
                            key={label.id}
                            size='small'
                            variant='contained'
                            className={selectedButtonIds.includes(label.id) ? 'custom-table-btn-selected' : 'custom-table-btn'}
                            onClick={() => handleButtonClick(label.id)}
                        >
                            {label.name}
                        </Button>
                    ))}
                </Grid>
                <Grid item lg={12} md={12} px={2} pt={2}>
                    {secondSlice.map((label) => (
                        <Button
                            key={label.id}
                            size='small'
                            variant='contained'
                            className={selectedButtonIds.includes(label.id) ? 'custom-table-btn-selected' : 'custom-table-btn'}
                            onClick={() => handleButtonClick(label.id)}
                        >
                            {label.name}
                        </Button>
                    ))}
                </Grid>
            </Grid>

            {/* <Grid container mt={3}>
                <Grid item xs={12} pb={1}>
                    <Typography className='custom-table-column'>Column Order</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} px={2}>
                    <div className='custom-table-paper'>
                        {buttonLabels.map((label) => (
                            <Button
                                key={label.id}
                                size='small'
                                className='custom-table-btn'
                                variant='contained'
                            >
                                <Icon icon="akar-icons:three-line-vertical" className='custom-btn-icon' />
                                {label.name}
                            </Button>
                        ))}
                    </div>
                </Grid>
            </Grid> */}
            {/* {(props.dateFormat) ?
                <div>
                    <Grid container mt={3}>
                        <Grid item xs={12} pb={1}>
                            <Typography className='custom-table-column'>Date Format</Typography>
                        </Grid>
                    </Grid> */}
            {/* <Grid container spacing={2}>
                        <Grid item lg={5} md={6} sm={8} xs={10} >
                            <div className='custom-date-contain'>
                                <Grid container  >
                                    <Grid item lg={4} md={4} sm={4} xs={4}>                               
                                            <Button fullWidth className='custom-send-btn'>DD/MM/YYYY</Button>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={4} xs={4}>
                                            <Button fullWidth className='custom-send-btn'>MM/DD/YYYY</Button>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={4} xs={4}>
                                            <Button fullWidth className='custom-send-btn'>YYYY/MM/DD</Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>                 
                    </Grid>
                </div>
                : ''} */}
            <Grid container pt={2} px={2}>
                <Grid item xs={12}>
                    {/* <FormControlLabel className='custom-tablesettings-checkbox' control={<Checkbox defaultChecked />} label="Sticky header" /> */}
                </Grid>
            </Grid>
            <Divider></Divider>
            <Grid container py={2} textAlign={'right'}>
                <Grid item xs={12} mr={2}>
                    <Button variant='contained' className='custom-btn-reset' onClick={handleResetButtonId}>Reset</Button>
                    <Button variant='contained' className='custom-btn-apply' onClick={handleSendButtonId}>Apply</Button>
                </Grid>
            </Grid>
        </Box>
    );
}
