
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import OrderAcksDetails from '../order_acks_details/order_acks_details.component';
import OrderAckHistory from '../order_acks_history/order_acks_history.component';
import Grid from '@mui/material/Grid';
import { Icon } from '@iconify/react';
import './order-acks-tab.css'
import ReSubmission from '../../resubmission/resubmission.component';
import { useLocation } from 'react-router-dom';
import { Alert } from '@mui/material';
import { getResubmissionError } from '../order_acks_details/order_details_api';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function   OrderAcksTab(props: any) {
  const [value, setValue] = React.useState(0);
  const [stage, setStage] = React.useState();
  const [orderId, setOrderId] = React.useState<any>();
  const [status, setStatus] = React.useState<any>()
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [tabIcon, setTabIcon] = React.useState(false)
  const location = useLocation();
  const [resubmissionError, setResubmissionError] = React.useState()
  const [resubmissionAlert, setResubmissionAlert] = React.useState(false)
  React.useEffect(() => {
    
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    const status = searchParams.get("status");
    setStatus(status)
    setOrderId(id ? id : undefined);
    // setStage(props.stage);
   
    if (status == 'Rejected' || props.stage=='3') {
      setTabIcon(true)
    }
    else {
      setTabIcon(false)
    }
    let response = getResubmissionError(id).then((res) => {
      setResubmissionError(res)
    })
    if (status == 'Rejected' || status == 'PayloadID Exist') {
      setResubmissionAlert(true)
    }
    else {
      setResubmissionAlert(false)
    }
  }, [props.stage])

  const [isFieldsEnabled, setFieldsEnabled] = React.useState(false);

  const handleEditFields = () => {
    setFieldsEnabled(!isFieldsEnabled);
  };

  const handlePrint = () => {
    window.location.href = `/invoice?id=${orderId}`;
    console.log('fffffffffffff')
  };

  const handleOrderHistory = () => {
    props.setComments(false)
  }
  const handleOrderetails = () => {
    props.setComments(true)
  }

  return (
    <Box sx={{ width: '100%' }} px={2}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Grid container justifyContent={'end'} className='custom-orderacks-tabicon'>
        {resubmissionAlert &&
          <Grid item xs={11} py={2}>
            <Grid container justifyContent={'end'}>
              <Grid item xs={11}>
                  <Alert severity='error' style={{ fontSize: '12px' }}>{resubmissionError}</Alert>
              </Grid>
            </Grid>
          </Grid>
                }
          <Grid item xs={1} py={1} style={{alignSelf:'center'}} textAlign={'center'}>
            {tabIcon &&
              <Icon icon="mdi:edit-circle" color={isFieldsEnabled ? 'blue' : '#a1acb3'} style={{ cursor: 'pointer' }} width="22" height="22" onClick={handleEditFields} />
            }
            &nbsp;  &nbsp;
            {(status == 'Approved' || status == 'Invoiced') ?
              <Icon icon="mdi:printer-preview" color="#a1acb3" onClick={handlePrint} style={{ cursor: 'pointer' }} width="22" height="22" />
              : ''}
            {/* <Icon icon="ic:file-download" color="#a1acb3" width="22" height="22" /> */}
          </Grid>

        </Grid>

        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='custom-orderacks-tab'>
          <Tab label="Order Details" {...a11yProps(0)} onClick={handleOrderetails} />
          <Tab label="Order History" {...a11yProps(1)} onClick={handleOrderHistory} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box >
          {/* {(status != 'Rejected'? <OrderAcksDetails stage={stage} /> : '')}
          {(status == 'Rejected'? <ReSubmission handleEditFields={handleEditFields} isFieldsEnabled={isFieldsEnabled} /> : '')}     */}
          {(props.stage == undefined ?
            <>
              {(status !== 'Rejected' && status !== 'Resubmitted') && <OrderAcksDetails stage={props.stage} />}
              {(status === 'Rejected' || status === 'Resubmitted') && <ReSubmission handleEditFields={handleEditFields} isFieldsEnabled={isFieldsEnabled} />}
            </>
            :
            <>
              {(props.stage != 3) && <OrderAcksDetails stage={props.stage} />}
              {(props.stage == 3) && <ReSubmission handleEditFields={handleEditFields} isFieldsEnabled={isFieldsEnabled} />}
            </>
          )}
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box>
          <OrderAckHistory />
        </Box>
      </TabPanel>
    </Box>
  );
}