import React, { forwardRef, useImperativeHandle } from 'react'
import { Backdrop, Button, FormControl, Grid, Modal, Paper, Stack, } from '@mui/material'
import './shipping.css'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Icon } from '@iconify/react';
import ShippingEditAddressModal from './shipping_editAddress_modal';
import { Formik, Form, Field, ErrorMessage, FormikProps } from "formik";
import * as Yup from "yup";

interface ChildComponentProps {
  handleEditFields: () => void; // Define the 'handleEditFields' property with the correct type
  isFieldsEnabled: boolean; // Update the type if necessary
  shippingDetail: any;
}

export interface ChildComponentRef {
  resubmissionShippingSubmit: () => void;
}
const ResubmissionShipping = forwardRef<ChildComponentRef, ChildComponentProps>((props: any, ref: any) => {
  const formRef = React.useRef<FormikProps<any>>(null);
  const [editAddress, setEditAddress] = React.useState<any>()
  const initialValues = {
    levelShipping: "",
  };

  useImperativeHandle(ref, () => ({
    resubmissionShippingSubmit() {
      if (editAddress != undefined) {
        let shippingDetail = {
          "ShipTo": {
            "streets": editAddress.shippingToAddress1 + ' ' + editAddress.shippingToAddress2,
            "city": editAddress.shippingToCity,
            "country": editAddress.shippingToCountry,
            "state": editAddress.shippingToState,
            "postal_code": editAddress.shippingToZip
          }
        }
        return shippingDetail;
      } else {
        let shippingDetail = {
          "ShipTo": {
            "streets": props.shippingDetail.editAddress.shipTo.address,
            "city": props.shippingDetail.editAddress.shipTo.city,
            "state": props.shippingDetail.editAddress.shipTo.state,
            "country": props.shippingDetail.editAddress.shipTo.country,
            "postal_code": props.shippingDetail.editAddress.shipTo.zip
          }
        }
        return shippingDetail;
      }
    }
  }));
  const handleChangeRadio = (e: any) => {
    let levelShipping = e.target.value;
    formRef.current?.setFieldValue('levelShipping', levelShipping);
  }
  // console.log('shippingDetail',props.shippingDetail);
  // React.useEffect(()=>{
  //   if (props.shippingDetail) {
  //   formRef.current?.setFieldValue('location', props.shippingDetail.taxLocation);
  //   formRef.current?.setFieldValue('taxCategory', props.shippingDetail.taxCategory);
  //   formRef.current?.setFieldValue('taxAmount', props.shippingDetail.taxAmount);
  //   formRef.current?.setFieldValue('taxableAmount', props.shippingDetail.taxableAmount);
  //   formRef.current?.setFieldValue('rate', props.shippingDetail.rate);
  //   formRef.current?.setFieldValue('description', props.shippingDetail.description);
  //   }
  // },[props.shippingDetail])

  const [openUnit, setOpenUnit] = React.useState(false);
  const handleOpenShipingAddress = () => setOpenUnit(true);
  const handleCloseUnit = () => setOpenUnit(false);
  const handleCloseUnitModal = () => {
    setOpenUnit(false);
  }

  const handleEditAddress = (values: any) => {
    setEditAddress(values)
    props.shippingDetail.shipToName = values.shippingToName;
    props.shippingDetail.shipToAddress = values.shippingToAddress1 + ' ' + values.shippingToAddress2 + ' ' + values.shippingToCity + ' ' + values.shippingToState + ' ' + values.shippingToCountry + ' ' + values.shippingToZip;

    props.shippingDetail.editAddress.shipTo.name = values.shippingToName;
    props.shippingDetail.editAddress.shipTo.address = values.shippingToAddress1;
    props.shippingDetail.editAddress.shipTo.address1 = values.shippingToAddress2;
    props.shippingDetail.editAddress.shipTo.city = values.shippingToCity;
    props.shippingDetail.editAddress.shipTo.state = values.shippingToState;
    props.shippingDetail.editAddress.shipTo.country = values.shippingToCountry;
    props.shippingDetail.editAddress.shipTo.zip = values.shippingToZip;
  }
  return (
    <div>
      <Paper className='custom-ResubmissionShipping-paper'>
        <Grid container className='custom-ResubmissionShipping-container'>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <h1 className='custom-ResubmissionShipping-header'>Header Level Shipping</h1>
            </Stack>
          </Grid>
        </Grid>
        <Formik initialValues={initialValues} innerRef={formRef} onSubmit={() => { }}>
          <Form>
            <Grid container className='custom-ResubmissionShipping-container'>
              {/* <Grid item lg={6} md={6} sm={6} xs={6} pl={2}>
            <FormControl className='custom-ResubmissionShipping-checkbox' disabled={!props.isFieldsEnabled}>
              <RadioGroup sx={{ '& .MuiSvgIcon-root': { fontSize: 16, }, }}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="levelShipping"  onChange={handleChangeRadio}>
                <FormControlLabel  value="1" checked={true} control={<Radio />} label="Header level shipping" />
                <FormControlLabel value="2" disabled control={<Radio />} label="Line level shipping" />
              </RadioGroup>
            </FormControl>
          </Grid> */}
              {(!props.isFieldsEnabled) ?
                ''
                :
                <Grid item lg={12} md={12} sm={12} xs={12} textAlign={'end'} pr={2}>
                  <p className='custom-ResubmissionShipping-viewAddress' onClick={handleOpenShipingAddress}><span> <Icon icon="el:pencil-alt" style={{ paddingTop: '2px', cursor: 'pointer' }} height={14} width={14} color="#5755ff" />  &nbsp;</span> Edit Address</p>
                </Grid>
              }
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4} textAlign={'center'} py={1}>
              <Grid container >
                <Grid item lg={6} md={6} sm={6} xs={6} >
                  <Grid container justifyContent={'end'}>
                    <Grid item lg={2} md={2} sm={2} xs={2} textAlign={'end'}>
                      <p className='custom-ResubmissionShipping-bill-head'> Ship From :</p>
                    </Grid>
                    <Grid item lg={6} md={7} sm={7} xs={7} textAlign={'start'} pl={1}>
                      <p className='custom-ResubmissionShipping-bill-head'> {props.shippingDetail?.shipFromName} </p>
                      <p className='custom-ResubmissionShipping-bill'>{props.shippingDetail?.shipFromAddress}</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Grid container >
                    <Grid item lg={2} md={2} sm={2} xs={2}>
                      <p className='custom-ResubmissionShipping-bill-head'> Ship To :</p>
                    </Grid>
                    <Grid item lg={6} md={7} sm={7} xs={7} textAlign={'start'} pl={1}>
                      <p className='custom-ResubmissionShipping-bill-head'> {props.shippingDetail?.shipToName} </p>
                      <p className='custom-ResubmissionShipping-bill'>{props.shippingDetail?.shipToAddress}</p>

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Paper>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        open={openUnit} onClose={handleCloseUnit}>
        <div >
          <ShippingEditAddressModal shippingDetail={props.shippingDetail} handleCloseUnitModal={handleCloseUnitModal} handleEditAddress={handleEditAddress} />
        </div>
      </Modal>

    </div>
  )
});
export default ResubmissionShipping;

