import { ApiService } from "../../../../service/api.service";
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import axiosInstance from "../../../../service/axios_instance";

const apiService = new ApiService();
// Get Api for Sales Order Details
export const getSalesOrderDetails = async (id: any) => {
  let salesOrderDetails;
  const userId = localStorage.getItem('userId')

  // await apiService.getAll('orders/getUpdatedInvoiceCxmlPayload/' + id + '/').then((response: any) => {
  //   if (response.status_code == 200) {
  //     let result = response.data.cXML
  //     let orderDetails = response.data.SalesInvoice;
  //     let lineItem = result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem
  //     let totalAmount = 0;
  //     let lineItemDetails = [];

  //     for (let i = 0; i < lineItem.length; i++) {
  //       let details = {
  //         "lineNumber": lineItem[i].InvoiceDetailItemReference['@lineNumber'],
  //         "sku": response.data.CaracalItems[i].sku,
  //         "commodityCode": response.data.CaracalItems[i].commodityCode,
  //         "itemName": lineItem[i].InvoiceDetailItemReference.ItemID.SupplierPartID,
  //         "quantity": lineItem[i]['@quantity'] + '/' + lineItem[i]['UnitOfMeasure'],
  //         "unitPrice": '$ ' + lineItem[i].UnitPrice.Money['#text'] + ' ' + lineItem[i].UnitPrice.Money['@currency'],
  //         "subtotalAmount": '$ ' + lineItem[i].SubtotalAmount.Money['#text'] + ' ' + lineItem[i].SubtotalAmount.Money['@currency']
  //       }
  //       lineItemDetails.push(details);
  //       let subAmount = parseFloat(Number(lineItem[i].SubtotalAmount.Money['#text']).toFixed(2))
  //       totalAmount = totalAmount + subAmount
  //     }

  //     let invoiceDate = result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader['@invoiceDate']          
  //     const datetime = new Date(invoiceDate);
  //     const formattedInvoiceDateTime = format(new Date(datetime), 'EEEE dd MMMM yyyy');

  //     let orderDate = orderDetails.ProcessedTime
  //     const orderDateTime = new Date(orderDate);
  //     const formattedOrderDateTime = format(new Date(orderDateTime), 'EEEE dd MMMM yyyy');

  //     salesOrderDetails = {
  //       "orderDetails": {
  //         "orderId": orderDetails.OrderId,
  //         "TaylorOrderId": orderDetails.TaylorOrderId,
  //         "orderDate": formattedOrderDateTime,
  //         "customerName": orderDetails.CustomerName,
  //         "purchaseOrder": orderDetails.RoutingStatus,
  //         "invoiceId" :  result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader['@invoiceID'],
  //         "invoiceDate" :  formattedInvoiceDateTime,
  //         "dueAmount" : result.Request.InvoiceDetailRequest.InvoiceDetailSummary.DueAmount.Money['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.DueAmount.Money['@currency']
  //       },
  //       "shipping": {
  //         "shipToName" : result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].Name['#text'],
  //         "shipToAddress" : result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].PostalAddress['City'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].PostalAddress['Street'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailOrder.InvoiceDetailItem[0].InvoiceDetailLineShipping.InvoiceDetailShipping.Contact[1].PostalAddress.Country['#text'],
  //         "billToName": result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[1].Contact.Name['#text'],
  //         "billToAddress":result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[1].Contact.PostalAddress['City'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[1].Contact.PostalAddress.Street[0] + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[1].Contact.PostalAddress.Street[1] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailRequestHeader.InvoicePartner[1].Contact.PostalAddress.Country['#text'],
  //       },
  //       "lineItems": {
  //         "itemDetails": lineItemDetails,
  //         "totalAmount": '$ ' + totalAmount + ' ' + lineItem[0].SubtotalAmount.Money['@currency']
  //       },
  //       "taxSummary": {
  //         "amountDetails": {
  //           "subTotalAmount": '$ ' + totalAmount + ' ' + lineItem[0].SubtotalAmount.Money['@currency'],
  //           "grossAmount": '$ ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.GrossAmount.Money['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.GrossAmount.Money['@currency'],
  //           "netAmount": '$ ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.NetAmount.Money['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.NetAmount.Money['@currency'],
  //           "dueAmount": '$ ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.DueAmount.Money['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.DueAmount.Money['@currency']
  //         },
  //         "taxDetails": {
  //           "category": result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.TaxDetail['@category'],
  //           "details": result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.Description['#text'],
  //           "baseAmt": '$ ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.TaxDetail.TaxAmount.Money['#text'] + ' ' + result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.TaxDetail.TaxAmount.Money['@currency'],
  //           "rate": result.Request.InvoiceDetailRequest.InvoiceDetailSummary.Tax.TaxDetail['@percentageRate'] + ' %'
  //         }
  //       }

  //     }
  //   }
  // })
  await apiService.getAll(`orders/invoicePdfMailData/?orderid=${id}&userid=${userId}`).then((response: any) => {
    // console.log('userid,user', response);
    salesOrderDetails = response.invoiceHtml;
  })
    .catch(error => {
      console.error('Error occurred:', error);
      if(error.response.status == 400){
        Swal.fire({title:'Error',text:'There is No PDF,Please Click Sent Invoice Button',allowOutsideClick:false})
      }else if(error.response.status == 404){
        console.log(error);
         Swal.fire({title:'Error',text:'There is No PDF,Please Click Sent Invoice Button',allowOutsideClick:false})
      }
      if (error.response.status == 401) {
        //apiService.tokenExpired();
      }
    });
  return salesOrderDetails;
}

// Send Mail
export const sendPdf = async (id: any)=>{
  let status;
  const userId = localStorage.getItem('userId')
  await apiService.getAll(`orders/invoicePdfMailData/?orderid=${id}&userid=${userId}&type=mail`).then((response: any) => {
    status = response
  })
    .catch(error => {
      console.error('Error occurred:', error);
      status = error.response.data
      if (error.response.status == 401) {
        //apiService.tokenExpired();
      }
    });

    return status;
}

// Download Invoice Pdf
export const downloadPdf = async (id: any)=>{
  let status;
  const userId = localStorage.getItem('userId')
  try {
    const response = await axiosInstance.get(`orders/invoicePdfMailData/?orderid=${id}&userid=${userId}&type=download`, {
      responseType: 'blob', 
    });

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Invoice_${id}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

  } catch (error) {
    console.error(error);
  }
  // await apiService.getAll(`orders/invoicePdfMailData/?orderid=${id}&userid=${userId}&type=download`).then((response: any) => {
  //   status = response.status_code
  // })
  //   .catch(error => {
  //     console.error('Error occurred:', error);
  //     if (error.response.status == 401) {
  //       //apiService.tokenExpired();
  //     }
  //   });

  //   return status;
}

// Get Api for Sales Order Details
export const sendMailInvoice = async (data: any, orderId: any) => {
  let result;
  const pdfBlob = dataURLtoBlob(data);
  const formData = new FormData();
  formData.append('pdfFile', pdfBlob);
  await apiService.add(`ariba/invoicedEmail/?customerName=${localStorage.getItem('customerName')}&orderId=${orderId}`, formData).then((res: any) => {
    // Swal.fire("Invoice mail sent successfully");
    result = res.statusCode
  }).catch(error => {
    console.error('Error occurred:', error);
    if (error.response.status == 401) {
      //apiService.tokenExpired();
    }
  });
  return result
}

function dataURLtoBlob(dataURL: any) {
  const byteString = atob(dataURL.split(',')[1]);
  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}
