
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { Icon } from '@iconify/react';
import { useLocation } from 'react-router-dom'
import './order_acks_timeline.css'
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 16,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
      'linear-gradient( 136deg, #00B929 0%, #00B929 50%, #00B929)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
      'linear-gradient( 136deg, #00B929 0%, #00B929 50%, #00B929)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#C2CAD9',
  zIndex: 1,
  color:  '#fff',
  width: 35,
  height: 35,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
    'linear-gradient( 136deg, #00B929 0%, #00B929 50%, #00B929)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    // border:'2px solid grey'
  }),
  ...(ownerState.completed && {
    backgroundImage:
    'linear-gradient( 136deg, #00B929 0%, #00B929 50%, #00B929)'
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className} = props;
  const [stage, setStage] = React.useState();
  const [selectedLabel, setSelectedLabel] = React.useState(null);    

  const handleOrder = (stage:any) => {
    setStage(stage);
    setSelectedLabel(stage);

  }

   let activeStep=localStorage.getItem('activeStep')
  
  const icons: { [index: string]: React.ReactElement } = {
    1: <Icon icon="solar:cart-check-bold" style={{cursor:'pointer'}} width="20" height="20" color="white"  />,
    2: <Icon icon="heroicons-solid:receipt-tax"  style={{cursor:'pointer'}} width="20" height="20" color="white"/>,
    3: <Icon icon="iconamoon:delivery-fast-fill"  style={{cursor:'pointer'}} width="20" height="20" color="white"/>,
    4: <Icon icon="akar-icons:arrow-repeat"  style={{cursor:'pointer'}} width="20" height="20" color="white"/>,
    5: <Icon icon="iconamoon:invoice-fill"  style={{cursor:'pointer'}} width="20" height="20" color="white"/>,
  };

  const icon = icons[props.icon as keyof typeof icons] 

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active}} className={`${
      activeStep === props.icon ? 'custom-active-stepper' : ''
    } ${className}`} >
      {icon}
    </ColorlibStepIconRoot>
  );
}

const steps = [
  {"id":0, "typeId" : 1, "label":'Order Ack'},
  {"id":1, "typeId" : 2,"label":'Tax Calculation'},
  {"id":2, "typeId" : 3,"label":'Shipment'},
  {"id":3, "typeId" : 4,"label":'Resubmission'},
  {"id":4, "typeId" : 5, "label":'Invoice'},
]

export default function OrderAcksTimeline(props:any) {
  const location = useLocation();
    const [activeStep, setActiveStep] = React.useState<any>();
    const [selectedLabel, setSelectedLabel] = React.useState<any>();    
    React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
      const status = searchParams.get("status");
      if (status == 'New') {
        setActiveStep(0)
        setSelectedLabel(0)
      } else if (status == 'Ack & Tax Calculated' || status == 'Sent to Ariba' ||  status == 'Sent' ||  status == 'PayloadID Exist' || status == 'Processing') {
        setActiveStep(1)
        setSelectedLabel(1)
      } else if (status == 'shipped'|| status == 'Sent to Ariba' ||  status == 'Sent') {
        setActiveStep(2)
        setSelectedLabel(2)
      } else if (status == 'Resubmitted' || status == 'Rejected') {
        setActiveStep(3)
        setSelectedLabel(3)
      } else if (status == 'Invoiced' || status == 'Approved') {
        setActiveStep(4)
        setSelectedLabel(4)
      }
      // if(props.SetOrderProcess){
      //   setActiveStep(props.SetOrderProcess);
      // }
    }, [props.SetOrderProcess]);
    const handleOrder = (stage: any) => {   
      if (activeStep !== undefined && activeStep >= stage) {
        props.handleStageOrder(stage);
        setSelectedLabel(stage)
      }
    }
    return (
      <div >
      <Stack sx={{ width: '100%' }} spacing={4}  className='custom-order-acks-timeline' >
        <Stepper  alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />} >
        {steps.map((label) => {
            if (label.id === 3 && activeStep !== 3) {
              return null;
            }
            return (
              <Step key={label.id} style={{color:'red'}}>
                <StepLabel StepIconComponent={ColorlibStepIcon} icon={label.typeId} onClick={() => handleOrder(label.id)} className={selectedLabel === label.id ? 'custom-active-text' : ''}>
                  {label.label}
                </StepLabel>
              </Step>
            );
          })}
      </Stepper>
    </Stack>
    </div>
  );
}