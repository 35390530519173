import React, { forwardRef, useImperativeHandle } from 'react'
import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField } from '@mui/material'
import './order_information.css'
import { Icon } from '@iconify/react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Formik, Form, Field, ErrorMessage, FormikProps } from "formik";
import * as Yup from "yup";

interface ChildComponentProps {
  handleEditFields: () => void; // Define the 'handleEditFields' property with the correct type
  isFieldsEnabled: boolean; // Update the type if necessary
  orderInformationDetail: any;
}

export interface ChildComponentRef {
  orderSubmit: () => void;
}
const OrderInformation = forwardRef<ChildComponentRef, ChildComponentProps>((props: any, ref: any) => {
  const formRef = React.useRef<FormikProps<any>>(null);
  const initialValues = {
    customerOrder: "",
    contractNumber: "",
    salesOrder: "",
    // salesOrderDate: "",
  };

  useImperativeHandle(ref, () => ({
    orderSubmit() {
      if (formRef.current) {
        return formRef.current.values;
      }
    }
  }));
  React.useEffect(() => {
    if (props.orderInformationDetail) {
      console.log(props.orderInformationDetail)
      formRef.current?.setFieldValue('contractNumber', props.orderInformationDetail.contractNumber);
      formRef.current?.setFieldValue('customerOrder', props.orderInformationDetail.customerOrder);
      formRef.current?.setFieldValue('salesOrder', props.orderInformationDetail.salesOrder);
      formRef.current?.setFieldValue('salesOrderDate', props.orderInformationDetail.salesOrderDate);
    }
  }, [props.orderInformationDetail])
  const handleDateFormat = (date: any) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    formRef.current?.setFieldValue('salesOrderDate', formattedDate);
  }

  return (
    <div>
      <Paper className='custom-orderInformation-paper'>
        <Formik initialValues={initialValues} innerRef={formRef} onSubmit={() => { }}>
          <Form>
            <Grid container className='custom-orderInformation-container'>
              <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                  <h1 className='custom-orderInformation-header'>Order Information</h1>
                </Stack>
              </Grid>
            </Grid>
            <Grid container pt={1} justifyContent={'center'}>
              {/* <Grid item lg={3} md={6} sm={4} py={1} pr={2} textAlign={'end'}>
                  <Field as={TextField} size='small' id="customerOrder" name="customerOrder" label="Customer order" className='custom-orderInformation-textField' variant="outlined" disabled={!props.isFieldsEnabled}/>
                </Grid> */}
              <Grid item lg={3} md={4} sm={4} py={1} textAlign={'center'}>
                <Field as={TextField} size='small' id="contractNumber" name="contractNumber" label="Contract Number" className='custom-orderInformation-textField' variant="outlined" disabled={!props.isFieldsEnabled} />
              </Grid>
              <Grid item lg={3} md={4} sm={4} py={1} pr={2} textAlign={'center'}>
                <Field as={TextField} size='small' id="salesOrder" name="salesOrder" label="Sales order" className='custom-orderInformation-textField' variant="outlined" disabled={!props.isFieldsEnabled} />
              </Grid>
              <Grid item lg={3} md={4} sm={4} py={1} textAlign={'center'}>
                <LocalizationProvider dateAdapter={AdapterDayjs} id="salesOrderDate" name="salesOrderDate">
                  <DatePicker className='custom-orderInformation-dateField' label="Sales Order Date" disabled={!props.isFieldsEnabled} onChange={handleDateFormat} />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid container justifyContent={'center'}>

            </Grid>
          </Form>
        </Formik>
      </Paper>
    </div>
  )
})

export default OrderInformation

