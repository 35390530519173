import React from 'react'
import OrderAcksTab from './order_acks_tab/order_acks_tab.component'
import OrderAcksTimeline from './order_acks_timeline/order_acks_timeline.component'
import { useLocation } from "react-router-dom";
import './order_acks.css'
import { Grid, Paper } from '@mui/material'
import OrderAcksComment from './order_acks_comment/order_acks_comment.component'
export default function OrderAcks(props: any) {
  const [orderProcess, SetOrderProcess] = React.useState<any>()
  const [stage, setStage] = React.useState<any>()
  const [comments, setComments] = React.useState<boolean>(true)
  // React.useEffect(() => {
  //   let steps;
  //   const checkLocalStorage = () => {
  //     const localStorageValue = localStorage.getItem('stage');
  //     if (localStorageValue) {
  //       steps = parseInt(localStorageValue)
  //       SetOrderProcess(steps)
  //       setStage(localStorageValue)
  //     } else {
  //       setTimeout(checkLocalStorage, 1000);
  //     }
  //   };
  //   checkLocalStorage();
  // }, [])

  const handleStageOrder = (stage: any) => {
    setStage(stage)
  }
  return (
    <>
      <div className='custom-orderDetails-main'>
        <Paper>
          <OrderAcksTimeline SetOrderProcess={orderProcess} handleStageOrder={handleStageOrder} />
          <OrderAcksTab orderProcess={orderProcess} stage={stage} setComments={setComments}/>
        </Paper>
        {(comments) ?
          <Grid container>
            <Grid item xs={12} >
              <OrderAcksComment stage={stage} />
            </Grid>
            <Grid item xs={12} >
            </Grid>
          </Grid>
          :
          ''
        }
      </div>
    </>
  )
}

