import { ApiService } from "../../service/api.service";
import Swal from 'sweetalert2';

const apiService = new ApiService();
// ************ Api for dashboard count **************
export const GetInfo = async () => {
  let result;
  await apiService.getAll('orders/dashboard/?filteredData=currentMonth&fromDate=&toDate=').then((response: any) => {
    if (response.status_code == 200) {
      result = response.dashboardCount;
    }
  })
    .catch(error => {
      console.error('Error occurred:', error);
      if (error.response.status == 401) {
        //apiService.tokenExpired();
      }
    });
  return result;
}
export const FilterDashboardInfo = async (filterData:any) => {  
  let result;
  await apiService.getAll(`orders/dashboard/?filteredData=${filterData.filteredData}&fromDate=${filterData.fromDate}&toDate=${filterData.toDate}`).then((response: any) => {
    if (response.status_code == 200) {
      result = response;
    }
  })
    .catch(error => {
      console.error('Error occurred:', error);
      if (error.response.status == 401) {
        //apiService.tokenExpired();
      }
    });
  return result;
}
export const getAutoProcessAll = async (userName: any, status: any, selectedFromDate: any, selectedToDate: any, paginationModel:any) => {
  let result;
  await apiService.getAll(`api/autoStatusChange/?auto_type=all&fromDate=${selectedFromDate}&toDate=${selectedToDate}&superUser=${userName}&status=${status}&page_size=${paginationModel.pageSize}&page_number=${(paginationModel.page)+1}`).then((response: any) => {
    if (response.status_code == 200) {
      result = response
    }
  }).catch(error => {
    console.error('Error occurred :', error.response.status);
    if (error.response.status == 401) {
      //apiService.tokenExpired();
    }
  });
  return result;
}

// Api for getting all the user list
export const getUserList = async () => {
  let result;
  await apiService.getAll('api/getSuperUser/').then((response: any) => {
    if (response.status_code == 200) {
      result = response.data
    }
  })
    .catch(error => {
      console.error('Error occurred:', error);
      if (error.response.status == 401) {
        //apiService.tokenExpired();
      }
    });
  return result;
}

