import axios, { AxiosRequestConfig }  from "axios";
import { ApiService } from "../../../service/api.service";
import Swal from 'sweetalert2';
import axiosInstance from "../../../service/axios_instance";


const apiService = new ApiService();
const apiUrl = process.env.REACT_APP_API_URL;
// ************ create Api for  Auto Status  **************

export const creatAutoStatus = async (autoProcess: any) => {
    let result;
    let data = {
        'autoStatus': autoProcess
    }
    await apiService.add('api/autoStatusChange/', data).then((response: any) => {
        if (response.status_code == 200) {
            result = response.message
        }
    })
        .catch(error => {
            console.error('Error occurred:', error);
            if(error.response.status == 401){
              //apiService.tokenExpired();
            }
        });
    return result;
}
// ************ get Api for  ItemType  **************
export const getItemType = async () => {
    let result;
    await apiService.getAll('upload/itemtype/').then((response: any) => {
        if (response.status_code == 200) {
            result = response.data
        }
    })
        .catch(error => {
            console.error('Error occurred:', error);
            if(error.response.status == 401){
              //apiService.tokenExpired();
            }
        });
    return result;
}
// ************ Api for get Auto Process  **************
export const getAutoProcess = async () => {
    let result;
    await apiService.getAll('api/autoStatusChange/').then((response: any) => {
        if (response.status_code == 200) {
            result = response.userStatus.auto_status
        }
    })
        .catch(error => {
            console.error('Error occurred :', error.response.status);
            if(error.response.status == 401){
              //apiService.tokenExpired();
            }
        });
    return result;
}
// ************ Api for Proceed Orders  **************
export const proceedOrders = async (data: any) => {
    let result;

    await apiService.add('orders/proceedOrders/', data).then((response: any) => {
        // result = response
        if(response.statusCode==200){
            result=response.message
        }
    })
    .catch(error => {
        console.error('Error occurred:', error);
        if(error.response.status == 401){
          //apiService.tokenExpired();
        }
    });
    return result
}
// ************ Api for Download Order Excel  **************


export const downloadOrderExcel = async (orders: Array<any>, fromdate: any, todate: any, customerName:any, search:any, value:any, namesOnly:any, sort:any) => {
    let type;
    
    try {
      if (value == 1){ 
        type = 'New'
      } else if (value == 2){
        type = 'Ack'
      } else if (value == 3){
        type = 'Rejected'
      } else if (value == 4){ 
        type = 'Resubmitted'
      } else if (value == 5){ 
        type = 'Approved'
      } else if (value == 6){ 
        type = 'Invoiced'
      }  else {
        type = 'All'
      }
        const ordersdata: any = orders.map(order => order.orderId);
        // const data = ordersdata;
        if(search != null){
          search= search;
        }else{
          search="";
        }
        if(sort == undefined){
          sort = null
        }
        const data = {
            "ordersdata": ordersdata,
            "fromdate": fromdate,
            "todate": todate,
            "search" : search,
            "customerName" : customerName,
            "type" : type,
            "columnName" : namesOnly,
            "sort":sort
        }

        const config: AxiosRequestConfig = {
            headers: {
            'Content-Type': 'application/json',
            },
            responseType: 'arraybuffer' as const,
        };
    
        const response = await axiosInstance.post(`${apiUrl}orders/report/`, data, config)
        // console.log(response.data)
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'caracal_report.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        // window.location.reload()
    } catch (error:any) {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.data) {
            const arrayBuffer = await new Response(error.response.data).arrayBuffer();
            const textDecoder = new TextDecoder('utf-8');
            const decodedString = textDecoder.decode(arrayBuffer);
            const json = JSON.parse(decodedString);
              if(json.status_code == 400){                
              Swal.fire({title:json.message,allowOutsideClick:false})
              }
          } else {
            console.error('Error downloading the file:', error.response?.status);
          }
        } else {
          if(error.response.status == 401){
            // apiService.tokenExpired()
          }
        }
      }
  };


// export const downloadOrderExcel = async (id:any) => {
//     try {

//         const data = { orderId: id };
//         const config = {
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           responseType: 'arraybuffer',
//         };
        
//         const response = axios.post(`${apiUrl}orders/report/`, data, config);
        



//         // const response = axios.get(`${apiUrl}orders/report/?orderId=${id}`, { responseType: 'arraybuffer' });
//         const url = window.URL.createObjectURL(new Blob([(await response).data]));
//         const link = document.createElement('a');
//         link.href = url;
//         link.setAttribute('download', `${id}_report.xlsx`);
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//       } catch (error:any) {
//         console.error('Error occurred:', error);
//         if(error.response.status == 401){
//             Swal.fire('Token Expired',"Login", 'warning').then(() => {window.location.reload();});
//         }
//       }
// }
// ************ Api for Download Order Pdf  **************
export const downloadOrderPdf = async (id: any) => {
    try {
      const response = await axiosInstance.get(`${apiUrl}orders/orderReportPdf/?orderId=${id}`, { responseType: 'arraybuffer' });
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${id}_report.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error: any) {
      console.error('Error occurred:', error);
      if (error.response.status == 401) {
        //apiService.tokenExpired();
      }
    }
  };
  
// ************ Api for Order Delete  **************
export const deleteOrder = async(orderId : any) => {
    let result;
    await axiosInstance.delete(`${apiUrl}orders/bulkDeleteOrders/`, {
        data: {
            orderId: orderId
        }
      }).then((response:any)=>{
        result = response.message
    }).catch(error => {
        console.error('Error occurred:', error);
        if(error.response.status == 401){
          //apiService.tokenExpired();
        }
    });
    return result;
}
