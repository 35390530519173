import React from 'react'
import Grid from '@mui/material/Grid';
import { Icon } from '@iconify/react';
import './order_acks_details_content.css';
import { Button, TextField } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { addPayloadId } from '../order_acks_details/order_details_api';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

interface OrderDetails {
  orderId: string;
  TaylorOrderId: string;
  orderDate: string;
  customerName: string;
  purchaseOrder: string;
  invoiceId: string;
  invoiceDate: string;
  dueAmount:string;
  payloadId:any;
  TaylorOldInvoiceId:string;
  Comments:string;
}

export default function OrderAcksDetailsContentComponent(props: any) {
  const [orderDetails, setOrderDetails] = React.useState<OrderDetails>()
  const [orderDetailsProcess, setOrderDetailsProcess] = React.useState<any>()
  const [isFieldsEnabled, setFieldsEnabled] = React.useState(true);
  const [isFieldsEnabledButton, setFieldsEnabledButton] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  // const [payloadIdValue, setPayloadIdValue] = React.useState();
  const [payloadIdValue, setPayloadIdValue] = React.useState<string | undefined>(orderDetails?.payloadId || '');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const status = searchParams.get("status");
  const id = searchParams.get("id");
  // const handleEditFields = () => {
  //   setFieldsEnabled(!isFieldsEnabled);
  // };

  const handlePayloadId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPayloadIdValue(event.target.value);
  };
  const handlePayloadSave= async ()=>{
    setLoading(true)
    let response= await addPayloadId(id,payloadIdValue).then((res)=>{
      setLoading(false)
    }).catch((error)=> {
      console.log('Error Occured: ', error);
    });
  }
  React.useEffect(() => {
    if (props.salesOrderDetails) {
      setOrderDetails(props.salesOrderDetails.orderDetails);
      if(props.salesOrderDetails.orderDetails?.Comments){
        setOrderDetailsProcess(props.salesOrderDetails.orderDetails?.Comments.split('Process'))
      }
      setPayloadIdValue(props.salesOrderDetails.orderDetails?.payloadId || '');
    }
    if(status== 'PayloadID Exist'){
      setFieldsEnabledButton(true)
      setFieldsEnabled(true);
    }else{
      setFieldsEnabled(false);
    }
  }, [props.salesOrderDetails,status]);

  return (
    <>
      <Grid container className='custom-OrderDetails-container'>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={3}>
              <h1 className='custom-OrderDetails-orderId'>Order Id</h1>
            </Grid>
            <Grid item xs={2} >
              <h1 className='custom-OrderDetails-orderDetails'>{orderDetails?.orderId}</h1>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <h1 className='custom-OrderDetails-orderId'>Order Process</h1>
            </Grid>
            <Grid item xs={2} >
              <h1 className='custom-OrderDetails-orderDetails'>{orderDetailsProcess?orderDetailsProcess?.[0]:''}</h1>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <h1 className='custom-OrderDetails-orderId'>Taylor Invoice Id</h1>
            </Grid>
            <Grid item xs={2} >
              <h1 className='custom-OrderDetails-orderDetails'>{orderDetails?.TaylorOldInvoiceId}</h1>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <h1 className='custom-OrderDetails-orderId'>Order Date</h1>
            </Grid>
            <Grid item xs={5} >
              <h1 className='custom-OrderDetails-orderDetails'>{orderDetails?.orderDate}</h1>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <h1 className='custom-OrderDetails-orderId'>Customer</h1>
            </Grid>
            <Grid item xs={2} >
              <h1 className='custom-OrderDetails-orderDetails'>{orderDetails?.customerName}</h1>
            </Grid>
          </Grid>

          <Grid container pt={1}>
            <Grid item xs={3}>
              <h1 className='custom-OrderDetails-orderId'>Payload Id</h1>
            </Grid>
            <Grid item xs={3} >
              <TextField fullWidth size='small' id="payloadId" name="payloadId" onChange={handlePayloadId}   value={payloadIdValue || ''} label="Payload Id" className='custom-Information-textField' disabled={!isFieldsEnabled} variant="outlined" />
            </Grid>

            {(loading)?
        <Grid item xs={5} sm={5} lg={3} xl={2} md={4} style={{paddingTop:'5px',textAlign:'center'}}>
        <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} style={{ color: 'white', backgroundColor: '#5755FF',padding:'2px 12px',fontSize: '12px',textTransform:'initial' }} variant="contained" > Loading </LoadingButton>
      </Grid>
        : 
        <Grid item xs={2} style={{paddingTop:'5px',textAlign:'center'}}>
        { isFieldsEnabledButton &&
          <Button type='submit' className='custom-comment-send-btn' onClick={handlePayloadSave}>Save</Button>
           } 
        </Grid>
}



             {/* <Grid item xs={2} style={{paddingTop:'5px',textAlign:'center'}}>
             { isFieldsEnabledButton &&
              <Button type='submit' className='custom-comment-send-btn' onClick={handlePayloadSave}>Save</Button>
               } 
            </Grid> */}
          </Grid>

        </Grid>
        <Grid item xs={6}  >
          <Grid container textAlign={'right'} >
            <Grid item xs={12}>
              <h1 className='custom-OrderDetails-Purchase'>Purchase Order</h1>
            </Grid>
          </Grid>
          <Grid container justifyContent={'center'} >
            <Grid item xs={10} textAlign={'end'}>
              {(status == 'New') ? <Icon icon="ic:baseline-new-releases" color="#00b929" /> : ''}
            </Grid>
            <Grid item xs={1} textAlign={'center'}>
              {(status == 'New') ? <h1 className='custom-OrderDetails-new'>New</h1> : ''}
            </Grid>
          </Grid>
          {(status != 'New') ?
            <>
              <Grid container textAlign={'right'} >
                <Grid item xs={12}>
                  <h1 className='custom-OrderDetails-otherDetails'>Invoice ID: {orderDetails?.invoiceId}</h1>
                </Grid>
              </Grid>
              <Grid container textAlign={'right'} >
                <Grid item xs={12}>
                  <h1 className='custom-OrderDetails-otherDetails'>Invoice Date: {orderDetails?.invoiceDate}</h1>
                </Grid>
              </Grid>
              <Grid container textAlign={'right'} >
                <Grid item xs={12}>
                  <h1 className='custom-OrderDetails-otherDetails'>Amount Due : {orderDetails?.dueAmount}</h1>
                </Grid>
              </Grid>
            </>
            : ''}
        </Grid>
      </Grid>
    </>
  )
}

