import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import { Button, Grid, Tab, TabProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './fulfillment_tab.css'
import { Badge } from '@mui/material';
import OrderListDetails from '../../tables/order/order.component';
import { allColumns, getAllOrder } from './order_table_column'
import { Icon } from '@iconify/react';
import { useLocation } from 'react-router-dom';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function FulfillmentTab(props: any) {
  
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const updatedParams = new URLSearchParams(location.search);
    setValue(newValue);
    if (newValue === 0  || newValue === 1 || newValue === 2 || newValue === 3 || newValue === 4 || newValue === 5 || newValue === 6) {
      updatedParams.set('tab', newValue.toString());
    } else {
      updatedParams.delete('tab');
    }
    const newURL = `${location.pathname}?${updatedParams.toString()}`;
     window.history.pushState({ path: newURL }, '', newURL);
    props.setData(null);
    props.setSelectedRowData([]);
  };
  const [buttonId, setButtonId] = React.useState();
  const [orderList, setOrderList] = React.useState<any[]>([]);
  const [rejectBadge, setRejectBadge] = React.useState<any>();
  const [allBadge, setAllBadge] = React.useState<any>();
  const [taxBadge, setTaxBadge] = React.useState<any>();
  const [newCountBadge, setNewcountBadge] = React.useState<any>();
  const [resubmitBadge, setResubmitBadge] = React.useState<any>();
  const [invoiceBadge, setInvoiceBadge] = React.useState<any>();
  const [approvedBadge, setApprovedBadge] = React.useState<any>();
  const [pageNumber, setPageNumber] = React.useState<any>(0);
  const [pageSize, setPageSize] = React.useState<any>(10);
  const [pageCount, setPageCount] = React.useState<any>();
  const [dataCount, setDataCount] = React.useState<any>();
  const [page, setPage] = React.useState<any>();
  const [interval, setIntervalState] = React.useState<boolean>(false)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get('tab');
  const [value, setValue] = React.useState<any>(Number(paramValue));
  const [tableLoader, setTableLoader] = React.useState(true)


  React.useEffect(() => {   
    setButtonId(props.buttonId);
    props.setTabValue(value);

    const fetchData = async () => {

      try {
        // Get Api for All orders
        let orderList = await getAllOrder(props.selectedFromDate, props.selectedToDate, props.selectedCustomer, props.selectedSearch, pageNumber, pageSize, value, props.sort);

        if (orderList) {
          setAllBadge(orderList['count']['allOrders'])
          setNewcountBadge(orderList['count']['new'])
          setTaxBadge(orderList['count']['ackTax'])
          setRejectBadge(orderList['count']['reject'])
          setResubmitBadge(orderList['count']['resubmit'])
          setInvoiceBadge(orderList['count']['invoice'])
          setApprovedBadge(orderList['count']['approved'])
          setOrderList(orderList['data'])
          setPageCount(orderList['pageCount'])
          setDataCount(orderList['dataCount'])
          setPage(orderList['page'])
          props.setData(orderList['data'])
        }

      } catch (error) {
        console.error('Error occurred:', error);
      }
    };
    fetchData();


    // Schedule data fetch every 6 minutes
    const interval = setInterval(() => {
      fetchData();
      setIntervalState(true);
    }, 6 * 60 * 1000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [props.buttonId, props.selectedFromDate, props.selectedToDate, props.selectedCustomer, props.selectedSearch, value, pageSize, pageNumber, props.sort]);

 

  return (
    <Box sx={{ width: '100%' }} px={2}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='custom-fullfillment-tabs'>
          <Tab className="custom-font-tab" label={<Badge badgeContent={allBadge} className='custom-badge'>All Orders</Badge>} {...a11yProps(0)} />
          <Tab className="custom-font-tab" label={<Badge badgeContent={newCountBadge} className='custom-badge'>New</Badge>} {...a11yProps(1)} />
          <Tab className="custom-font-tab" label={<Badge badgeContent={taxBadge} className='custom-badge'> Ack & Tax</Badge>} {...a11yProps(2)} />
          <Tab className="custom-font-tab" label={<Badge badgeContent={rejectBadge} className='custom-badge'>Rejected</Badge>} {...a11yProps(3)} />
          <Tab className="custom-font-tab" style={{ alignItems: 'start' }} label={<Badge badgeContent={resubmitBadge} className='custom-badge'>Resubmitted</Badge>} {...a11yProps(4)} />
          <Tab className="custom-font-tab" label={<Badge badgeContent={approvedBadge} className='custom-badge'>Approved</Badge>} {...a11yProps(5)} />
          <Tab className="custom-font-tab" label={<Badge badgeContent={invoiceBadge} className='custom-badge'>Invoiced</Badge>} {...a11yProps(6)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <OrderListDetails buttonId={buttonId} status={value} allColumns={allColumns} data={props.data} setSelectedRowData={props.setSelectedRowData} handleDeleteIcon={props.handleDeleteIcon} setSelectedComponentRef={props.setSelectedComponentRef} setPageNumber={setPageNumber} setPageSize={setPageSize} pageCount={pageCount} dataCount={dataCount} page={page} interval={interval} setTableColumns={props.setTableColumns} setSort={props.setSort} tableLoader={tableLoader}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OrderListDetails buttonId={buttonId} status={value} allColumns={allColumns} data={props.data} setSelectedRowData={props.setSelectedRowData} handleDeleteIcon={props.handleDeleteIcon} setSelectedComponentRef={props.setSelectedComponentRef} setPageNumber={setPageNumber} setPageSize={setPageSize} pageCount={pageCount} dataCount={dataCount} page={page} interval={interval} setTableColumns={props.setTableColumns} setSort={props.setSort} tableLoader={tableLoader}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <OrderListDetails buttonId={buttonId} status={value} allColumns={allColumns} data={props.data} setSelectedRowData={props.setSelectedRowData} handleDeleteIcon={props.handleDeleteIcon} setSelectedComponentRef={props.setSelectedComponentRef} setPageNumber={setPageNumber} setPageSize={setPageSize} pageCount={pageCount} dataCount={dataCount} page={page} interval={interval} setTableColumns={props.setTableColumns} setSort={props.setSort} tableLoader={tableLoader}/>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <OrderListDetails buttonId={buttonId} status={value} allColumns={allColumns} data={props.data} setSelectedRowData={props.setSelectedRowData} handleDeleteIcon={props.handleDeleteIcon} setSelectedComponentRef={props.setSelectedComponentRef} setPageNumber={setPageNumber} setPageSize={setPageSize} pageCount={pageCount} dataCount={dataCount} page={page} interval={interval} setTableColumns={props.setTableColumns} setSort={props.setSort} tableLoader={tableLoader}/>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <OrderListDetails buttonId={buttonId} status={value} allColumns={allColumns} data={props.data} setSelectedRowData={props.setSelectedRowData} handleDeleteIcon={props.handleDeleteIcon} setSelectedComponentRef={props.setSelectedComponentRef} setPageNumber={setPageNumber} setPageSize={setPageSize} pageCount={pageCount} dataCount={dataCount} page={page} interval={interval} setTableColumns={props.setTableColumns} setSort={props.setSort} tableLoader={tableLoader}/>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <OrderListDetails buttonId={buttonId} status={value} allColumns={allColumns} data={props.data} setSelectedRowData={props.setSelectedRowData} handleDeleteIcon={props.handleDeleteIcon} setSelectedComponentRef={props.setSelectedComponentRef} setPageNumber={setPageNumber} setPageSize={setPageSize} pageCount={pageCount} dataCount={dataCount} page={page} interval={interval} setTableColumns={props.setTableColumns} setSort={props.setSort} tableLoader={tableLoader}/>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <OrderListDetails buttonId={buttonId} status={value} allColumns={allColumns} data={props.data} setSelectedRowData={props.setSelectedRowData} handleDeleteIcon={props.handleDeleteIcon} setSelectedComponentRef={props.setSelectedComponentRef} setPageNumber={setPageNumber} setPageSize={setPageSize} pageCount={pageCount} dataCount={dataCount} page={page} interval={interval} setTableColumns={props.setTableColumns} setSort={props.setSort} tableLoader={tableLoader}/>
      </TabPanel>
    </Box>
  );
}