import axios from "axios";
import { ApiService } from "../../service/api.service";
import axiosInstance from "../../service/axios_instance";
import Swal from "sweetalert2";

const apiService = new ApiService();

export const GetLogTable = async (filterMonth:any,page_size:any,page_number:any,filterFromDate:any,filterToDate:any,status:any,) => {
    let result;
    let filterData = filterMonth
    let payloadid = ''
    // let filterFromDate = ''
    // let filterToDate = ''
    let report = ''   
    let ObjectId=''
    const statusData = status == 'all'? '' : status;
    await apiService.getAll(`api/logMetricsGetAll/?filteredData=${filterData}&payloadId=${payloadid}&status=${statusData}&ObjectId=${ObjectId}&fromDate=${filterFromDate}&toDate=${filterToDate}&report=${report}&page_size=${page_size}&page_number=${page_number+1}`).then((response: any) => {      
      if (response.status_code == 200) {
        result = response;
      }
    }).catch((error:any) => {
        result ={
          'status_code': error.response.data.status_code,
          'message': error.response.data.message,
        }
        if (error.response.status == 401) {
          //apiService.tokenExpired();
        }
      });
    return result;
  }
  export const GetByIdLogTable = async (filterData:any,payloadId:any,filterFromDate:any,filterToDate:any,report:any,page_size:any,page_number:any,ObjectId:any,status:any) => {
    let result;
    let filterDataValue:any    
    if(filterData.length > 0){
        filterDataValue = filterData;
    }else{
        filterDataValue = 'fiveDays';
    }    
    const fromDate = filterFromDate == null? '' : filterFromDate;
    const toDate = filterToDate == null? '' : filterToDate;
    const statusData = status == 'all'? '' : status;

    await apiService.getAll(`api/logMetricsGetAll/?filteredData=${filterDataValue}&status=${statusData}&payloadId=${payloadId}&ObjectId=${ObjectId}&fromDate=${fromDate}&toDate=${toDate}&report=${report}&page_size=${page_size}&page_number=${page_number + 1}`).then((response: any) => {      
      if (response.status_code == 200) {
        result = response;
      }
    })
      .catch(error => {
        console.error('Error occurred:', error);
        result ={
          'status_code': error.response.data.status_code,
          'message': error.response.data.message,
        }
        if (error.response.status == 401) {
        // apiService.tokenExpired()
        }
      });
    return result;
  }
  export const handleDownloadLog = async (filterData:any,payloadId:any,ObjectId:any,filterFromDate:any,filterToDate:any,report:any,page_size:any,page_number:any,status:any) => {
    let result;
    let downloadStatus;
    if(status == 'all'){
      downloadStatus='';
    }else{
      downloadStatus=status;
    }
   try{
     const response = axiosInstance.get(`api/logMetricsGetAll/?filteredData=${filterData}&payloadId=${payloadId}&ObjectId=${ObjectId}&fromDate=${filterFromDate}&toDate=${filterToDate}&status=${downloadStatus}&report=${report}&page_size=${page_size}&page_number=${page_number + 1}`, { responseType: 'arraybuffer' })
     const url = window.URL.createObjectURL(new Blob([(await response).data]));
     const link = document.createElement('a');
     link.href = url;
     link.setAttribute('download', 'log_report.xlsx');
     document.body.appendChild(link);
     link.click();
     document.body.removeChild(link);
   }catch (error:any) {
    if (axios.isAxiosError(error)) {
     
      if (error.response && error.response.data) {
        const arrayBuffer = await new Response(error.response.data).arrayBuffer();
        const textDecoder = new TextDecoder('utf-8');
        const decodedString = textDecoder.decode(arrayBuffer);
        const json = JSON.parse(decodedString);
        
          if(json.status_code == 400){                
          Swal.fire({text:json.message,allowOutsideClick:false})
          }else if(json.status_code == 500){
            Swal.fire({icon:'warning',text:'No Records found...',allowOutsideClick:false})
          }
      } else {
        console.error('Error downloading the file:', error.response?.status);
      }
    } else {
      if(error.response.status == 401){
        // apiService.tokenExpired()
      }
     
    }
  }
    return result;
  }