import * as React from 'react';
import './dashboard_table.css';
import { Box, Grid } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getAutoProcessAll, getUserList } from './dashboard.api';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { error } from 'console';
import { Icon } from '@iconify/react';
import moment from 'moment';

const convertTimeTo12HourFormat = (time24:any) => {
  const time = moment(time24, 'HH:mm:ss.SSS').format('hh:mm A');
  return time;
};

const columns: GridColDef[] = [
  { field: 'id', headerName: '#', width: 100 },
  {
    field: 'userid_id__username',
    headerName: 'User',
    width: 160,
    editable: false,
    renderCell: (params: GridRenderCellParams) => {
      return <span>{params.value}</span>;
    },
    cellClassName: () => 'custom-width-cell',
    filterable: true,
    sortable: true,

  },
  {
    field: 'taxId',
    headerName: 'Status',
    width: 160,
    editable: false,
    cellClassName: () => 'custom-width-cell',
  },
  {
    field: 'date',
    headerName: 'Date',
    type: 'number',
    width: 130,
    editable: false,
    cellClassName: () => 'custom-width-cell',
  },
  {
    field: 'time',
    headerName: 'Time',
    type: 'number',
    width: 110,
    editable: false,
    renderCell: (params: GridRenderCellParams) => {
      const time12HourFormat = convertTimeTo12HourFormat( params.value);
      return time12HourFormat;
    },
    cellClassName: () => 'custom-width-cell',
  },
];

export default function DashboardTable() {
  const [autoStatus, setAutoStatus] = React.useState<any[]>([]);
  const [userList, setUserList] = React.useState<any[]>([]);
  const [minDate, setMinDate] = React.useState<any>(null);
  const [selectedFromDate, setSelectedFromDate] = React.useState<any>(null);
  const [selectedToDate, setSelectedToDate] = React.useState<any>(null);
  const [userName, setUserName] = React.useState('');
  const [status, setStatus] = React.useState('')
  const [rowCount, setRowCount] = React.useState();
  const [reload, setReload] = React.useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10
  });

  React.useEffect(() => {
    fetchData();
  }, [userName, status, selectedFromDate, selectedToDate, paginationModel])
  
  const fetchData = async () => {
    userListData();
    getStatusData();
  };

  const userListData = async () => {
    await getUserList().then((userList: any) => {
      setUserList(userList)
    });
  };

  const getStatusData = async () => {
    getAutoProcessAll(userName, status, selectedFromDate, selectedToDate, paginationModel).then((res: any) => {
      if (res.body) {
        setAutoStatus(res.body)
        setRowCount(res.totalCount)
      }
    }).catch((error) => { console.log(error); });
  };

  const handleFromDateChange = (date: any) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    setMinDate(date)
    setReload(true)
    setSelectedFromDate(formattedDate);
  };

  const handleToDateChange = (date: any) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    setSelectedToDate(formattedDate);
    setReload(true)
  };

  const handleUserChange = (event: any) => {
    setUserName(event.target.value);
    setReload(true)
  };

  const handleStatusChange = (event: any) => {
    setStatus(event.target.value);
    setReload(true)
  };

  const handlePaginationModelChange = (newPaginationModel:any) => {
    setPaginationModel(newPaginationModel);
  };
  const handleReload = () => {
    setSelectedFromDate(null)
    setSelectedToDate(null);
    setUserName('');
    setStatus('');
    setReload(false)
  }
  return (
    <>
      <Grid container columnGap={2}>
      <Grid item xl={1.5} lg={1.5} xs={3} md={3} sm={3}  >
      {/* shrink={userShrinkLabel} */}
          <FormControl fullWidth className='custom-select-formcontrol'>
            <InputLabel id="demo-simple-select-label">User</InputLabel>
            <Select className='custom-dashboard-select-field' label="User" labelId="demo-simple-select-label" value={userName} onChange={handleUserChange}>
              {Array.isArray(userList) && userList.map((user: any) => (
                <MenuItem key={user.id} value={user.id}>{user.username}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        
        <Grid item xl={1.5} lg={1.5} xs={3} md={3} sm={3}  >
          <FormControl fullWidth className='custom-select-formcontrol'>
            <InputLabel id="demo-simple-select-label" className='select-status-label' >Status</InputLabel>
            <Select className='custom-dashboard-select-field' size='small' label="Status" labelId="demo-simple-select-label"  value={status} onChange={handleStatusChange}>
              <MenuItem value="True">True</MenuItem>
              <MenuItem value="False">False</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={1} textAlign={'center'}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker onChange={handleFromDateChange} value={selectedFromDate}  className='custom-dashboard-date-picker' />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={1} textAlign={'center'}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker onChange={handleToDateChange} value={selectedToDate} minDate={minDate} className='custom-dashboard-date-picker' />
          </LocalizationProvider>
        </Grid>
        {reload && 
         <Grid  xs={1} sm={1} md={1} lg={1} xl={1} pt={0.5}>
         <Icon icon="ion:reload-circle" className='custom-fulfillment-table-icon' height='25px' color="#5755ff" width='25px' onClick={handleReload} />
       </Grid>
        } 
        <Grid item xs={12}  >
          <Box sx={{ width: '100%' }} className='custom-dashboard-table-grid'>
            <DataGrid className='custom-dashboard-data-grid'
              rowHeight={30}
              rows={autoStatus}
              columns={columns}
              paginationMode="server"
              rowCount={rowCount}
              paginationModel={paginationModel}
              pageSizeOptions={[10, 20, 50]}
              onPaginationModelChange={handlePaginationModelChange}
              disableColumnMenu
              localeText={{
                noRowsLabel: 'No Records Found...',
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}