import { ApiService } from "../../service/api.service";
import Swal from 'sweetalert2';

const apiService = new ApiService();

export const allColumns = [
  {
    label: "#", name: '#', options: {
      customBodyRender: (value: any, tableMeta: any) => {
        const serialNumber = tableMeta.rowIndex + 1;
        return <span>{serialNumber}</span>;
      },
      setCellProps: () => ({ style: { width: '50px' } }),
      filter: true,
      sort: true,
    }
  },
  {
    label: "Item Id", name: 'itemId', options: {
      customBodyRender: (params: any, tableMeta: any) => {
        const itemName = tableMeta.rowData[2];
        return <a href={`/addItem?type=update&itemId=${params}&itemName=${itemName}`} className="custom-link-order">{params}</a>
      },
      setCellProps: () => ({ style: { width: '250px' } }),
      filter: true,
      sort: true,
    }
  },
  {
    label: "Item Name", name: 'itemName', options: {
      filter: true,
      sort: true,
      setCellProps: () => ({ style: { width: '150px' } })
    }
  },
  {
    label: "Sku", name: 'SKU', options: {
      setCellProps: () => ({ style: { width: '120px' } }),
      filter: true,
      sort: true,
    }
  },
  {
    label: "Commodity Code", name: 'commodityCode', options: {
      setCellProps: () => ({ style: { width: '170px' } }),
      filter: true,
      sort: true,
    }
  },
  {
    label: "Item Type", name: 'itemTypeName', options: {
      setCellProps: () => ({ style: { width: '120px' } }),
      filter: true,
      sort: true,
    }
  },
  {
    label: "Item Unit", name: 'itemUnitName',
    filter: true,
    sort: true,
    setCellProps: () => ({ style: { width: '100px' } })
  },
  {
    label: "Unit Price", name: 'unitPrice', options: {
      setCellProps: () => ({ style: { width: '120px' } }),
      filter: true,
      sort: true,
    }
  },
  {
    label: "Item Description", name: 'description', options: {
      setCellProps: () => ({ style: { width: '280px' } }),
      filter: true,
      sort: true,
    }
  },
  {
    label: "Supplier", name: 'entityCompanyName', options: {
      setCellProps: () => ({ style: { width: '150px' } }),
      filter: true,
      sort: true,
    }
  }
];

export const getAllItem = async (search: any) => {
  let result;
  await apiService.getAll(`upload/items/?searchQuery=${search}`).then((response: any) => {
    if (response.status_code == 200) {
      result = response.data
    }
  })
    .catch(error => {
      console.error('Error occurred:', error);
      if(error.response.status == 401){
        //apiService.tokenExpired();
      }else if(error.response.status == 500){
        Swal.fire({title:'Error',text:"Internal Server Error",allowOutsideClick:false});
      }
    });
  return result;
}