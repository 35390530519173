import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import './shipments.css'

interface ShipDetails {
  shippingFrom: string;
  shippingTo: string;
  billingTo: string;
  remitTo: string;
}

export default function Shipments(props: any) {
  const [shipmentDetails, SetShipmentDetails] = React.useState<ShipDetails>()
  const [status, SetStatus] = React.useState(localStorage.getItem('activeStep'))
  React.useEffect(() => {
    if (props.salesShippingDetails) {
      SetStatus(localStorage.getItem('activeStep'))
      SetShipmentDetails(props.salesShippingDetails)
    }
  }, [props.salesShippingDetails])

  return (
    <div>
      <Card className='custom-shipment-card'>
        <CardContent>
          <Grid container>
            <Grid item style={{ width: '100%' }} px={2}>
              <TableContainer component={Paper} className='custom-shipping-table-container'>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className='custom-shipping-summary' colSpan={4}>Shipping & Billing</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className='custom-shipment-table-head' align="center">Shipping  From</TableCell>
                      <TableCell className='custom-shipment-table-head' align="center">Shipping  To</TableCell>
                      <TableCell className='custom-shipment-table-head' align="center">Billing To</TableCell>
                      <TableCell className='custom-shipment-table-head' align="center">Remit To</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell className='custom-shipment-table-fields'>{status != '0' ? shipmentDetails?.shippingFrom : ''}</TableCell>
                      <TableCell className='custom-shipment-table-fields'>{shipmentDetails?.shippingTo}</TableCell>
                      <TableCell className='custom-shipment-table-fields'>{shipmentDetails?.billingTo}</TableCell>
                      <TableCell className='custom-shipment-table-fields'>{status != '0' ? shipmentDetails?.remitTo : ''}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}




