import React from 'react'
import InvoiceHeader from './invoice_header/invoice_header.component'
import OrderInformation from './order_information/order_information.component'
import ReSubmissionTax from './resubmission_tax/resubmission_tax.component'
import ResubmissionShipping from './shipping/shipping.component'
import PaymentTerm from './payment/payment_term.component'
import LineItems from './line_items/line_items.component'
import ReSubmissionLineItems from './line_items/line_items.component'
import OrderAcksTimeline from '../order_acks/order_acks_timeline/order_acks_timeline.component'
import OrderAcksDetailsContentComponent from '../order_acks/order_acks_details_content/order_acks_details_content.component'
import { Button, Grid } from '@mui/material'
import ExtrinsicInformation from './extrinsic_information/extrinsic_information.component'
import { useLocation } from 'react-router-dom'
import { getSalesOrderDetails, resubmissionForm } from './resubmission_api'
import { checkAriba } from '../order_acks/order_acks_details/order_details_api'
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';


export default function ReSubmission(props: any) {
  const invoiceRef = React.useRef<{ invoiceSubmit: () => void } | null>(null);
  const orderRef = React.useRef<{ orderSubmit: () => void } | null>(null);
  const extrinsicInformationRef = React.useRef<{ extrinsicInformationSubmit: () => void } | null>(null);
  const reSubmissionTaxRef = React.useRef<{ reSubmissionTaxSubmit: () => void } | null>(null);
  const paymentTermRef = React.useRef<{ paymentTermSubmit: () => void } | null>(null);
  const lineItemsRef = React.useRef<{ lineItemsSubmit: () => void } | null>(null);
  const resubmissionShippingRef = React.useRef<{ resubmissionShippingSubmit: () => void } | null>(null);
  const [orderDetail, setOrderDetail] = React.useState<any>();
  const [invoiceHeaderDetail, setInvoiceHeaderDetail] = React.useState<any>();
  const [orderInformationDetail, setOrderInformationDetail] = React.useState<any>();
  const [extrinsicInfoDetail, setExtrinsicInfoDetail] = React.useState<any>();
  const [resubmissionTaxDetail, setResubmissionTaxDetail] = React.useState<any>();
  const [shippingDetail, setShippingDetail] = React.useState<any>();
  const [paymentDetail, setPaymentDetail] = React.useState<any>();
  const [lineItemDetail, setLineItemDetail] = React.useState<any>();
  const [orderId, setOrderId] = React.useState<any>();
  const [status, setStatus] = React.useState<any>();
  const location = useLocation();
  const [loading, setLoading] = React.useState<boolean>(false);
  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    const statusType = searchParams.get("status");
    setStatus(statusType)
    setOrderId(id);
    const fetchOrderData = async () => {
      try {
        await getSalesOrderDetails(id, 1).then(salesOrder => {
          if (salesOrder) {
            setOrderDetail(salesOrder)
            setInvoiceHeaderDetail(salesOrder['invoiceHeader'])
            setOrderInformationDetail(salesOrder['orderInformation'])
            setExtrinsicInfoDetail(salesOrder['extrinsicInfo'])
            setResubmissionTaxDetail(salesOrder['tax'])
            setShippingDetail(salesOrder['shipping'])
            setPaymentDetail(salesOrder['paymentTerm'])
            setLineItemDetail(salesOrder['lineItems'])
          } else {
            console.log("Sales order not found or missing order details.");
          }
        });
      } catch (error) {
        console.error('Error occurred:', error);
      }
    };
    fetchOrderData();
  }, []);

  const handleCheckSentAriba = async () => {
    setLoading(true);
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    await checkAriba(id).then((response) => {
      if (response) {
        setLoading(false)
      }
    }).catch((error) => {
      console.log('Error Occured: ', error);
    });
  }

  const handleSubmit = async () => {
    setLoading(true);
    let invoiceDetails, orderDetails, extrinsicInformationDetails, reSubmissionTaxDetails, paymentTermDetails, lineItemsDetails, resubmissionShippingDetails, lineDetails;
    if (invoiceRef.current) {
      invoiceDetails = invoiceRef.current.invoiceSubmit();
    }
    if (orderRef.current) {
      orderDetails = orderRef.current.orderSubmit();
    }
    if (extrinsicInformationRef.current) {
      extrinsicInformationDetails = extrinsicInformationRef.current.extrinsicInformationSubmit();
    }
    if (reSubmissionTaxRef.current) {
      reSubmissionTaxDetails = reSubmissionTaxRef.current.reSubmissionTaxSubmit();
    }
    if (paymentTermRef.current) {
      paymentTermDetails = paymentTermRef.current.paymentTermSubmit();
    }
    if (lineItemsRef.current) {
      lineItemsDetails = lineItemsRef.current.lineItemsSubmit();
    }
    if (resubmissionShippingRef.current) {
      resubmissionShippingDetails = resubmissionShippingRef.current.resubmissionShippingSubmit();
    }
    if (lineItemsRef.current) {
      lineDetails = lineItemsRef.current.lineItemsSubmit();
    }

    let details = {
      "orderId": orderId,
      "customerName": localStorage.getItem('customerName'),
      "invoiceDetails": invoiceDetails,
      "orderInformationDetail": orderDetails,
      "extrinsicInfoDetail": extrinsicInformationDetails,
      "resubmissionTaxDetail": reSubmissionTaxDetails,
      "shippingDetail": resubmissionShippingDetails,
      "paymentDetail": paymentTermDetails,
      "lineItemDetail": lineDetails
    }
    await resubmissionForm(details)
  }

  return (
    <div>
      <OrderAcksDetailsContentComponent salesOrderDetails={orderDetail} />
      <InvoiceHeader handleEditFields={props.handleEditFields} isFieldsEnabled={props.isFieldsEnabled} invoiceHeaderDetail={invoiceHeaderDetail} ref={invoiceRef} />
      <OrderInformation handleEditFields={props.handleEditFields} isFieldsEnabled={props.isFieldsEnabled} orderInformationDetail={orderInformationDetail} ref={orderRef} />
      <ExtrinsicInformation handleEditFields={props.handleEditFields} isFieldsEnabled={props.isFieldsEnabled} extrinsicInfoDetail={extrinsicInfoDetail} ref={extrinsicInformationRef} />
      <ReSubmissionTax handleEditFields={props.handleEditFields} isFieldsEnabled={props.isFieldsEnabled} resubmissionTaxDetail={resubmissionTaxDetail} ref={reSubmissionTaxRef} />
      <ResubmissionShipping handleEditFields={props.handleEditFields} isFieldsEnabled={props.isFieldsEnabled} shippingDetail={shippingDetail} ref={resubmissionShippingRef} />
      <PaymentTerm handleEditFields={props.handleEditFields} isFieldsEnabled={props.isFieldsEnabled} paymentDetail={paymentDetail} ref={paymentTermRef} />
      <ReSubmissionLineItems handleEditFields={props.handleEditFields} isFieldsEnabled={props.isFieldsEnabled} lineItemDetail={lineItemDetail} ref={lineItemsRef} />
      <Grid container pb={2} justifyContent={'center'}>
        {(loading) ?
          <Grid item md={12} lg={12} sm={12} xs={12} textAlign={"end"} pr={2}>
            <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} style={{ color: 'white', backgroundColor: '#5755FF', padding: '2px 12px', fontSize: '12px' }} variant="contained" > Loading </LoadingButton>
          </Grid>
          :
          <>
            {(status == 'Rejected') ?
              <Grid item md={12} lg={12} sm={12} xs={12} textAlign={"end"} pr={2}>
                <Button type="submit" className="custom-ReSubmissionLineItems-save-btn" onClick={handleSubmit} disabled={!props.isFieldsEnabled}>Save</Button>
              </Grid>
              :
              <Grid item md={12} lg={12} sm={12} xs={12} textAlign={"end"} pr={2}>
                <Button className="custom-ReSubmissionLineItems-save-btn" onClick={handleCheckSentAriba}>Check Ariba Status</Button>
              </Grid>
            }
          </>
        }
      </Grid>
    </div>
  )

}
