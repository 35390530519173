import './register.page.css'
import { Grid, Paper } from '@mui/material';
import RegisterComponent from '../../components/register/register';
import Footer from '../../components/footer/footer';

export default function RegisterForm() {
    return (
        <>
            <div className='custom-registerPage-backgroundImage'>
                <Grid container spacing={2} className='custom-registerPage-container'>
                    <Grid item xl={7} lg={6} md={6} sm={5} xs={12} className="custom-img-grid" textAlign={'center'}>
                        <img src='../sidebar/caracal-vertical.png' className='custom-register-img' alt='Register' />
                    </Grid>
                    <Grid item xl={5} lg={5} md={6} sm={7} xs={12} className='custom-RegisterComponent-container' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Paper elevation={3} sx={{ maxHeight: 'inherit', width: '90%' }} className='custom-registerPage-paper'>
                            <RegisterComponent />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </>
    )
}