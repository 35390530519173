import { Grid, Paper } from '@mui/material';
import './login.Page.css'
import LoginComponenet from '../../components/login/login';
import Footer from '../../components/footer/footer';

export default function LoginForm() {
    return (
        <>
            <div className='custom-login-backgroundImage'>
                <Grid container spacing={2} className='custom-loginpage-container'>
                    <Grid item xl={7} lg={7} md={6} sm={5} xs={12} className="custom-img-grid" textAlign={'center'}>
                        <img src='../sidebar/caracal-vertical.png' className='custom-caracal-img' alt="login" />
                    </Grid>
                    <Grid item xl={5} lg={5} md={6} sm={7} xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Paper elevation={3} sx={{ maxHeight: 'inherit', width: '70%' }} className='custom-login-paper'>
                            <LoginComponenet />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </>
    )
}