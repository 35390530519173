import { Link } from 'react-router-dom';
import { Box, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Button, Link as MuiLink, SelectChangeEvent, FormHelperText, Grid, Alert, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import { AuthService } from '../../service/auth.service';
import React, { useState } from 'react';
import './login.css';
import { VisibilityOff, Visibility, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Icon } from '@iconify/react';

var CryptoJS = require("crypto-js");
const secret_key = process.env.REACT_APP_SECRET_KEY_URL;
const SECRET_KEY=CryptoJS.enc.Hex.parse(secret_key)
const iv=SECRET_KEY

const validationSchema = Yup.object().shape({
    // loginType: Yup.string().required("Login Type is required"),
    username: Yup.string().required("Username is required").test("no-spaces", "Username cannot contain spaces", value => {
        return !/\s/.test(value); // Check if the username contains spaces
    }),
    password: Yup.string().required("Password  is required"),
});
export default function LoginComponenet() {
    const authService = new AuthService();
    const navigate = useNavigate();
    const initialValues = {
        loginType: "employee",
        username: "",
        password: "",
    };

    const [alert, setAlert] = React.useState(false);
    const [responseMsg, setResponseMsg] = React.useState<string>();

    const alertClose = () => {
        setAlert(false)
    }
    const ALERT_DURATION = 10000;
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (values: any, { setSubmitting }: FormikHelpers<any>) => {
            const trimmedUsername = values.username.trim(); // Remove leading and trailing spaces
            const lowercasedUsername = trimmedUsername.toLowerCase(); // Convert to lowercase
            const usernameWithoutSpaces = lowercasedUsername.replace(/\s/g, ''); // Remove spaces within the username
            const username =  CryptoJS.AES.encrypt(usernameWithoutSpaces,SECRET_KEY,{iv}).toString()
            const password =  CryptoJS.AES.encrypt(values.password.trim(),SECRET_KEY,{iv}).toString()

            authService.login({...values,'username': username,
            'password':password}).then(response => {
            const status = response.status;
            if (status === 200) {
                window.location.href = '/dashboard'
            }
            setSubmitting(false);
        }).catch(function (error) {
            if (error.message) {
                setResponseMsg(error.message)
            } else {
                setResponseMsg("Something went wrong")
            }
            setSubmitting(false);
            setAlert(true)
            setTimeout(() => {
                setAlert(false);
            }, ALERT_DURATION);
        });
    };
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // width: '80%',
                    padding: '1rem 2rem 2rem 2rem',
                }}
            >
                <Grid container>
                    <Grid item>
                        <h1 className='custom-login-heading' >Login</h1>
                    </Grid>
                </Grid>

                <Formik initialValues={initialValues} validateOnChange={true} validateOnBlur={false} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({ errors, touched }) => (
                        <Form>
                            {/* <FormControl fullWidth sx={{ mb: 2 }} error={!!errors.loginType && touched.loginType}>
                                <InputLabel id="loginType">Login Type</InputLabel>
                                <Field as={Select} label="Login Type" id="loginType" name="loginType">
                                    <MenuItem value='customer'>Customer</MenuItem>
                                    <MenuItem value='employee'>Caracal Employee</MenuItem>
                                </Field>
                                {errors.loginType && touched.loginType && (
                                    <FormHelperText error className="error-message">
                                        {errors.loginType}
                                    </FormHelperText>
                                )}
                            </FormControl> */}
                            <Grid container>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                                    <Field as={TextField} size='medium' className='custom-login-textfield' fullWidth label="Username" id='username' name="username" variant="outlined" sx={{ mb: 2 }}
                                        // error={errors.username  && touched.username} helperText={<ErrorMessage name="username" />}
                                        error={(touched.username && !!errors.username) || (submitted && !!errors.username)}
                                        helperText={touched.username && (errors.username || (submitted && errors.username))}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Icon icon="mdi:user" color="#171c26" width="20" height="20" />
                                                </InputAdornment>
                                            )
                                        }} />
                                </Grid>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                                    <Field as={TextField} size='medium' fullWidth className='custom-login-textfield'
                                        label="Password" name="password" variant="outlined" sx={{ mb: 2 }}
                                        // error={errors.password  && touched.password} helperText={<ErrorMessage name="password" />}
                                        error={(touched.password && !!errors.password) || (submitted && !!errors.password)}
                                        helperText={touched.password && (errors.password || (submitted && errors.password))}
                                        type={showPassword ? 'text' : 'password'}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Icon icon="mdi:password" color="#171c26" width="20" height="20" />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            {/* <Field as={TextField} fullWidth label="username" id='username' name="username" variant="outlined" sx={{ mb: 2 }} error={errors.username && touched.username} helperText={<ErrorMessage name="username" />} />
                            <Field as={TextField} fullWidth label="password" name="password" variant="outlined" sx={{ mb: 2 }} type="password" error={errors.password && touched.password} helperText={<ErrorMessage name="password" />} /> */}
                            {/* <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label={
                                        <Typography variant="subtitle2" sx={{ fontSize: 14 }}>
                                            Remember Me
                                        </Typography>
                                    }
                                />
                                <MuiLink href="#" underline="hover" sx={{ fontSize: 14, ml: 'auto' }}>
                                    Forgot password?
                                </MuiLink>
                            </Box> */}
                            <Grid container justifyContent={'center'}>
                                <Grid item lg={4} md={4} sm={4} xs={6} pb={1}>
                                    <Button type="submit" fullWidth className='custom-login-button'>  Login </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
                <Grid container justifyContent={'center'}>
                    <Grid item xs={12} pb={1} textAlign={'center'}>
                        <Typography variant="body1" sx={{ fontSize: 14 }}>
                            If you haven't registered yet. <Link to="/register">Register</Link>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'center'}>
                    <Grid item xs={12} pb={1} textAlign={'center'}>
                        <Typography variant="body1" sx={{ fontSize: 14 }}>
                            <Link to="/forgotPassword">Forgot Password?</Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            {(alert) ? <Alert onClose={alertClose} variant="filled" severity="error">{responseMsg}</Alert> : ''}
        </>
    )
}