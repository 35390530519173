import Grid from '@mui/material/Grid';
import './order_acks_details.css';
import OrderAcksDetailsTable from '../order_acks-details_table/order_acks-details_table.component';
import OrderTaxCalculation from '../order_tax_calculation/order_tax_calculation.component';
import Shipments from '../../shipments/shipments.component';
import OrderAcksInformation from '../order_acks_information/order_acks-information.component';
import OrderAcksDetailsContentComponent from '../order_acks_details_content/order_acks_details_content.component';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSalesOrderDetails } from './order_details_api';

export default function OrderAcksDetails(props: any) {
  const [orderStateProcess, setOrderStateProcess] = React.useState<number>(0);
  const [salesOrderDetails, setSalesOrderDetails] = React.useState();
  const [salesShippingDetails, setShippingOrderDetails] = React.useState();
  const [salesExtrinsicDetails, setExtrinsicDetails] = React.useState();
  const [salesLineItemDetails, setLineItemDetails] = React.useState();
  const [salesTaxDetails, setTaxDetails] = React.useState();
  const [status, SetStatus] = React.useState<any>();
  const location = useLocation();
  const history = useNavigate();
  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    const status = searchParams.get("status");
    SetStatus(status)
    let salesOrder: any;
    const fetchOrderData = async () => {
      try {
        // calling Get Api for sales order details 
        salesOrder = await getSalesOrderDetails(id, props.stage, status);
        if (salesOrder) {
          if (status != salesOrder.orderDetails.purchaseOrder) {
            searchParams.set('status', salesOrder.orderDetails.purchaseOrder);
            const newUrl = `${location.pathname}?${searchParams.toString()}`;
            window.location.href = newUrl
          }

          // let data = localStorage.getItem('activeStep')
          // if (data) {
          //   let steps = parseInt(data)
          //   setOrderStateProcess(steps);
          // }

          setOrderStateProcess(props.stage)
          setSalesOrderDetails(salesOrder.orderDetails);
          setShippingOrderDetails(salesOrder.shipping);
          setExtrinsicDetails(salesOrder.extrinsic);
          setLineItemDetails(salesOrder.lineItems);
          setTaxDetails(salesOrder.taxSummary);
        } else {
          console.log("Sales order not found or missing order details.");
        }
        setSalesOrderDetails(salesOrder);
      } catch (error) {
        console.error('Error occurred:', error);
      }
    };
    fetchOrderData();
  }, [props.stage]);
  return (
    <div>
      {/* {(orderStateProcess === 0 || orderStateProcess === 1 || orderStateProcess === 2 || orderStateProcess === 3 || orderStateProcess === 4) ? */}
      <div>
        <Grid container>
          <Grid item xs={12}>
            <OrderAcksDetailsContentComponent salesOrderDetails={salesOrderDetails} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Shipments salesShippingDetails={salesShippingDetails} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <OrderAcksInformation salesExtrinsicDetails={salesExtrinsicDetails} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} pb={2}>
            <OrderAcksDetailsTable salesLineItemDetails={salesLineItemDetails} />
          </Grid>
        </Grid>
        {(status !== 'New' && props.stage !== 0) ? (
          <Grid container>
            <Grid item xs={12}>
              <OrderTaxCalculation orderStateProcess={orderStateProcess} stage={props.stage} salesTaxDetails={salesTaxDetails} />
            </Grid>
            <Grid item xs={12}>
            
            </Grid>
          </Grid>
        ) : null}
      </div>
      {/* : null} */}
    </div>
  );
}
